import React, { useState, useRef } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Dialog, Typography } from '@mui/material';
import { getFirestore, doc, setDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useStateValue } from '../../StateProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function AddCategory({ categoryData, onClose }) {
    const [{ user, category }, dispatch] = useStateValue();
    const db = getFirestore();
    const storage = getStorage();
    const [Imagen, setImagen] = useState();
    const [loading, setLoading] = React.useState(false);
    const [parentCategory, setParentCategory] = React.useState('frutas');
    const nameCategory = useRef(null);
    const nameDescription = useRef(categoryData ? categoryData.description : null);
    const handleChange = (event) => {
        setParentCategory(event.target.value);
    };
    const [categories, setCategories] = useState(JSON.stringify(categoryData) !== "{}" ? categoryData : {
        description: "",
        id: "",
        images: "",
        name: "",
        url: "",
    })
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [url, setUrl] = useState('')
    console.log(categoryData)

    category.push({ label: 'Sin categoría', value: 'Sin categoría' });
    // Create the file metadata
    /** @type {any} */
    const metadata = {
        contentType: 'image/jpeg'
    };
    if (categoryData) {
        nameCategory.current = (categoryData.name)
    }

    //OBTENIENDO LA IMAGEN
    const changeImagen = e => {
        setImagen(e.target.files[0]);
    }

    //FUNCION PARA GUARDAR LA IMAGEN EN FIREBASE
    const uploadImage = async () => {
        try {
            if (categories.description == "" || categories.name == "" || !Imagen)
                alert("Revise que los datos sean correctos")
            else {
                setLoading(true)
                // Upload file and metadata to the object 'images/mountains.jpg'
                const storageRef = ref(storage, 'categories/' + Imagen.name);
                const uploadTask = uploadBytesResumable(storageRef, Imagen, metadata);

                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                console.log("No tiene acceso");
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                console.log("Subida cancelada");
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                console.log("Error desconocido");
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            setUrl(downloadURL);
                            addCategory(downloadURL);
                            setIsDialogOpen(true);
                        });
                    }
                );

            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    const addCategory = async (link) => {
        try {
            var data = {};
            var uid = uuidv4();
            //await setDoc(doc(db, "product", nameCategory.current.value), {});
            await setDoc(doc(db, "taxonomies", uid), {
                description: categories.description,
                name: categories.name,
                images: link,
                url: "/" + categories.name
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
        setLoading(false);
    }

    const updateCategory = async (data, link) => {
        try {
            const docRef = doc(db, 'taxonomies', categoryData.id)
            await updateDoc(docRef, {
                ...data,
                images: link
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleUpdateCategory = async () => {
        if (categories.name === "" || categories.description === "") {
            alert("Revise que los datos sean correctos")
        } else {
            let data = {}
            if (categories.description !== categoryData.description) {
                data = {
                    description: categories.description
                }
            }
            if (categories.name !== categoryData.name) {
                let link = categories.name.replace(" ", "-")
                data = {
                    ...data,
                    name: categories.name,
                    url: "/" + link,
                }
            }
            if (Imagen) {
                alert("actualizar imagen")
                const storageRef = ref(storage, 'categories/' + Imagen.name);
                const uploadTask = uploadBytesResumable(storageRef, Imagen, metadata);

                uploadTask.on('state_changed',()=>{},
                    (error) => {
                        switch (error.code) {
                            case 'storage/unauthorized':
                                console.log("No tiene acceso");
                                break;
                            case 'storage/canceled':
                                console.log("Subida cancelada");
                                break;
                            case 'storage/unknown':
                                console.log("Error desconocido");
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            setUrl(downloadURL);
                            updateCategory(data, downloadURL);
                            setIsDialogOpen(true);
                        });
                    }
                );
            } else {
                data = {
                    ...data,
                    images: categoryData.images,
                }
                const docRef = doc(db, 'taxonomies', categoryData.id)
                await updateDoc(docRef, { ...data })
            }
        }
    }
    console.log(categories)
    return (
        <Box
            sx={{
                width: 500,
                maxWidth: '100%',
                padding: "2rem"
            }}
        >
            <Dialog onClose={() => isDialogOpen(false)} open={isDialogOpen}  >
                <Box m={1} p={1}>
                    <img src={url} alt="imagen de categoria" width={300} height={300} style={{marginRight:"auto", marginLeft:"auto"}} />
                    <Typography variant='h4' marginTop="1rem" >
                        La categoria  {nameCategory.current?.value} se ha subido correctamente.
                    </Typography>
                    <Button sx={{marginTop:"0.5rem"}} variant='contained' onClick={() => {setIsDialogOpen(false); onClose()}} >
                        OK
                    </Button>
                </Box>
            </Dialog>
            <Stack spacing={2}>
                {
                    JSON.stringify(categoryData) !== "{}" ? <Box
                        sx={{
                            backgroundImage: `url(${categoryData.images})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            // width: "200px",
                            height: "200px",
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                        }}
                    ></Box> : null
                }
                <input type="file" name="imagen" onChange={changeImagen} />
                <TextField fullWidth label="Nombre de la categoría"
                    // inputRef={nameCategory} 
                    id="category" spacing={2}
                    value={categories.name}
                    onChange={(e) => setCategories({
                        ...categories,
                        name: e.target.value
                    })}
                />
  
                <TextField
                    // inputRef={nameDescription}
                    fullWidth
                    id="description-category"
                    label="Descripción"
                    multiline
                    rows={4}
                    value={categories.description}
                    onChange={(e) => setCategories({
                        ...categories,
                        description: e.target.value
                    })}
                />
                {/* <TextField                label="Mostrar en menu"           /> */}

                {
                    JSON.stringify(categoryData) === "{}" ?
                        <LoadingButton
                            size="small"
                            onClick={
                                uploadImage
                            }
                            endIcon={<SaveIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            fullWidth
                            sx={{
                                borderRadius: "10px"
                            }}
                        // disabled={nameDescription.current === null && nameCategory.current === null ? true : false}
                        >
                            Agregar categoría
                        </LoadingButton> :
                        <Button variant='contained' onClick={handleUpdateCategory} > Actualizar categoria</Button>
                }

            </Stack>
        </Box>
    );
}
