import React, { useState, useEffect } from 'react';
import { Dialog, Box, Button, IconButton, Snackbar, Typography, Grid, Stack } from '@mui/material';
import Product from '../product';
// Components
import Slider from './slider';
import CharacterCard from './characterCard';
import CloseIcon from '@mui/icons-material/Close';

const SliderProps = {
  zoomFactor: 5, // How much the image should zoom on hover in percent
  slideMargin: 8, // Margin on each side of slides
  maxVisibleSlides: 4,
  pageTransition: 500 // Transition when flipping pages
};

// Types
export var Character = {
  abilities: "",
  alias: "",
  gender: "",
  hair: "",
  id: 0,
  img_url: "",
  name: "",
  origin: "",
  species: "",
  status: "",
};

const Carousel = ({ product }) => {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([...product])
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeCharacter, setActiveCharacter] = useState(
    {}
  );
  const [snack, setSnack] = useState({
    image: "",
    name: "",
    quantity: "",
    unidad: ""
  })

  const handleDialogOpen = (character) => {
    setIsDialogOpen(true);
    setActiveCharacter(character);
  };
  const [open, setOpen] = React.useState(false);

  const handleClick = (image, quantity, unidad, name) => {
    setOpen(true);
    setSnack({
      image,
      quantity,
      unidad,
      name
    })
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // const action = (
  //   <React.Fragment>
  //     <Button color="secondary" size="small" onClick={handleClose}>
  //       UNDO
  //     </Button>
  //     <IconButton
  //       size="small"
  //       aria-label="close"
  //       color="inherit"
  //       onClick={handleClose}
  //     >
  //       <CloseIcon fontSize="small" />
  //     </IconButton>
  //   </React.Fragment>
  // );

  if (product.length < 1) return <div>Loading ...</div>;

  return (
    <>
      <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
        <CharacterCard character={activeCharacter} />
        {/* <h1>hola</h1> */}
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message="Note archived"
        // action={action}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box sx={{ width: "100%", background: "#fff", borderRadius: "10px" }} display="flex" p={2} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;" >
          <img src={snack.image} width="50px" height="50px" />
          <Box marginLeft={2} >
            <Typography color="primary" >Haz añadido</Typography>
            <Typography color="primary" ><strong>{snack.quantity} {snack.unidad} de {snack.name}</strong></Typography>
          </Box>
        </Box>
      </Snackbar>

      <Box overflow={{ xs: "hidden", md: "visible" }} display={{xs:"none",sm:"block"}} >
        <Slider {...SliderProps}  >
          {product.map(dataProduct => (
            // <div key={character.id} onClick={() => handleDialogOpen(character)}>
            //   <img src={character.img_url} alt='character' />
            // </div>
            <Box key={dataProduct.id} sx={{ width: "100%" }} >
              <Product product={dataProduct} handleClick={handleClick} />
            </Box>
          ))}
        </Slider>
      </Box>

      {
        <Stack sx={{flexDirection:"row"}} overflow="auto"  display={{xs:"flex",sm:"none"}} >
        {/* <Box spacing={2} display={{xs:"flex",sm:"none"}} height="390px" flexDirection="row" overflow="auto" width="auto" > */}
          {product.map(dataProduct => (
            <Box  key={dataProduct.id} minWidth="200px" m={1} >
              <Product product={dataProduct}  handleClick={handleClick} />
            </Box>
          ))}
        {/* </Box> */}
          </Stack>
          
      }

    </>
  );
};

export default Carousel;