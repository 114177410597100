import { Box, Button, Checkbox, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { addDoc, collection, doc, serverTimestamp, setDoc } from 'firebase/firestore'
import React, { useRef } from 'react'
import db from '../../../firebase/fbcnf'
import { useStateValue } from '../../../StateProvider'

const Coupons = () => {
  const [{category}] = useStateValue();
  const title = useRef()
  const type = useRef('')
  const code = useRef()
  const limit = useRef()
  const quantity = useRef()
  const dateStart = useRef()
  const dateEnd = useRef()
  const text = useRef()
  const minQuantity = useRef('');

  const getDepartments = ()=> {
    if(checked.every( v => v===true)){
      return "All"
    }
    return category.filter( (c,index)=> checked[index]).map( c =>c.label)
  }
  const handleSave = async()=>{
    try {
      const valid = validateForm()
      if(!valid){
        return
      }
      const appliable = getDepartments();
      const newCoupon = {
        title: title.current.value,
        type: type.current.value,
        code: code.current.value,
        limit: Number(limit.current.value),
        quantity: Number(quantity.current.value),
        dateStart: dateStart.current.value,
        dateEnd: dateEnd.current.value,
        text: text.current.value,
        applyFor: appliable,
        used:0,
        minQuantity:Number(minQuantity.current.value===''?0:minQuantity.current.value),
        createdAt: serverTimestamp()
      }
      console.log( "Cupon aplicado a ", appliable)
      console.log(newCoupon)
      const result = await setDoc(doc(db,'coupons',newCoupon.code),newCoupon)
      alert("El cupon se ha agregado correctamente")
      clearForm()
    } catch (error) {
      console.log(error)
    }
    
  }

  function  validateForm(){
    let ok = true
    if( title.current.value ==='' || type.current.value===undefined || code.current.value==='' ||  limit.current.value==='' || quantity.current.value==='' ||  dateEnd.current.value==='' || dateStart.current.value==='' || text.current.value==='' ){
      ok=false
      alert("Rellene todos los campos del formulario")
    }
    if(checked.every( c => c===false)){
      ok=false
      alert("Agregue al menos un departamento al cupon")
    }
    return ok;
  }
  function clearForm(){
    title.current.value =''
    // type.current.value=undefined
    code.current.value=''
    limit.current.value='' 
    quantity.current.value=''  
    dateEnd.current.value='' 
    dateStart.current.value='' 
    text.current.value=''
    minQuantity.current.value=''
    setChecked(category.map( c => false))
  }

  const [checked, setChecked] = React.useState(category.map( (index)=>false));

  const handleChange1 = (event) => {
    setChecked(category.map((index) => event.target.checked));
    console.log(" cat => ", checked)
  };

  const checkCat = (e, i) =>{
    let newValue = [...checked]
    newValue[i] = e.target.checked
    setChecked(newValue)
  }
  console.log("cambiado ", checked)
  console.log("checked ", checked.every( (val)=> val===true ))
  console.log("indeterminade ", (!checked.every( val => val===true)))

  const children = (
    <Box sx={{display:'flex', flexDirection:'column', marginLeft:3,}}  >
      {
        category.map((cat,index) => (
          <FormControlLabel
            key={cat.label}
            label={cat.label}
            control={<Checkbox checked={checked[index]} onChange={(e)=>checkCat(e,index)} />}
          />
        ) )
      }
    </Box>
  )

  return (
    <Box>
      <Typography variant='h4' >Cupones gratis</Typography>
      <Box alignItems='center' >
        <TextField placeholder='Titulo' inputRef={title} />
        <TextField placeholder='codigo' inputRef={code} />
        <TextField placeholder='texto' inputRef={text} />
        <TextField placeholder='Numero de usos' type='number' inputRef={limit} />
        <InputLabel id='labelType' >Tipo</InputLabel>
        <Select
          labelId='labelType'
          label='Typo'
          inputRef={type}
          sx={{minWidth:"120px", }}
        >
          <MenuItem value="quantity" >Cantidad</MenuItem>
          <MenuItem value="percentaje" >Procentaje</MenuItem>
        </Select>
        <TextField placeholder='cantidad' inputRef={quantity} type='number' />
        
        <TextField placeholder='Cantidad minima para aplicar cupon' type='number' label='Cantidad minima para Aplicar cupon' inputRef={minQuantity} />
        
        <Typography>Fecha de inicio</Typography>
        <input 
          type='date'
          ref={dateStart}
        />
        <Typography>Fecha de fin</Typography>
        <input 
          type='date'
          ref={dateEnd}
        />
        <br/>
        <Typography>Aplica a:</Typography>
        <FormControlLabel
          label="Todos"
          control={
            <Checkbox
              checked={checked.every( (val)=> val===true )}
              indeterminate={!checked.every( val => val===true)&&!checked.every( val => val===false)}
              onChange={handleChange1}
            />
          }
        />
        {children}

        <Button variant='outlined' onClick={handleSave} >Guardar</Button>
      </Box>
    </Box>
  )
}

export default Coupons