import React from 'react';
// Styles
import { StyledSliderItem } from './sliderItemStyles';

let SliderItemProps = {
  slideClass: "string",
  zoomFactor: 0,
  id: 0,
  callback: (id) => "",
  callbackOut: () => "",
  slideMargin: 1,
  visibleSlides: 0,
};

const SliderItem = ({
  slideMargin,
  visibleSlides,
  zoomFactor,
  slideClass,
  id,
  callback,
  callbackOut,
  children
}) => (
  <StyledSliderItem
    zoomFactor={zoomFactor}
    slideMargin={slideMargin}
    visibleSlides={visibleSlides}
    className={slideClass}
    onMouseOver={() => callback(id)}
    onMouseOut={callbackOut}
  >
      {children}
  </StyledSliderItem>
);

export default SliderItem;