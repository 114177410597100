export const initialState = {
    product: [],
    basket: [],
    returnProduct: [],
    category: [],
    user: null,
    date: "",
    notification: null,
    coupon: null,
    discount: 0,
    openModal:false,
}

export const actionTypes = {
    ADD_CATEGORY: 'ADD_CATEGORY',
    ADD_PRODUCT: 'ADD_PRODUCT',
    QUA_PRODUCT: 'QUA_PRODUCT',
    SEARCH_PRODUCT: 'SEARCH_PRODUCT',
    REMOVE_TO_BASKET: 'REMOVE_TO_BASKET',
    ADD_TO_BASKET: 'ADD_TO_BASKET',
    REMOVE_ITEM: 'REMOVE_ITEM',
    CHANGE_UNIT: 'CHANGE_UNIT',
    SET_USER: 'SET_USER',
    SET_DATE: 'SET_DATE',
    EMPTY_BASKET: 'EMPTY_BASKET',
    EMPTY_PRODUCT: 'EMPTY_PRODUCT',
    SET_NOTIFYCATION: 'SET_NOTIFICATION',
    ADD_COUPON: 'ADD_COUPON',
    OPEN_MODAL: 'OPEN_MODAL'
}

export const getBasketTotal = (basket) => {
    var cantidad = 1000;
    var carrito = [];
    basket.forEach(element => {

        if (element.unit === "grs") {
            element["quantityTotal"] = ((element.quantity) / 1000).toFixed(3);
            element["priceTotal"] = element.price;
        } else {
            element["quantityTotal"] = element.quantity;
            element["priceTotal"] = (element.price / (1000 / parseInt(element.weight)) * element.quantity);
        }
        carrito.push(element);
    });
    return Number(carrito.reduce((amount, item) => (
        (
            item.defaultUnit === "Pza" ?
                ((item.price * item.quantity))
                : item.unit == "Pza" ?
                    (item.priceTotal)
                    : (item.priceTotal * item.quantityTotal)) + amount), 0).toFixed(2));
}
export const getSubtotal = (basket) => {
    var cantidad = 1000;
    var carrito = [];
    console.log(basket);
    basket.forEach(element => {
        if (element.unit === "grs") {
            element["quantityTotal"] = element.quantity / cantidad;
            element["priceTotal"] = element.price;
        } else {
            element["quantityTotal"] = element.quantity;
            element["priceTotal"] = element.price / parseInt(element.piecesPerKg);
        }
        carrito.push(element);
    });
    var total = carrito.reduce((amount, item) => ((item.priceTotal * item.quantityTotal) + amount), 0);
    var subtotal = total / 1.16;
    return (subtotal).toFixed(2);
}
export const getTaxes = (basket) => {
    var cantidad = 1000;
    var carrito = [];
    console.log(basket);
    basket.forEach(element => {
        if (element.unit === "grs") {
            element["quantityTotal"] = element.quantity / cantidad;
            element["priceTotal"] = element.price;
        } else {
            element["quantityTotal"] = element.quantity;
            element["priceTotal"] = element.price / parseInt(element.piecesPerKg);
        }
        carrito.push(element);
    });
    var total = carrito.reduce((amount, item) => ((item.priceTotal * item.quantityTotal) + amount), 0);
    var subtotal = total / 1.16;
    return (total - subtotal).toFixed(2);
}

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_CATEGORY":
            return {
                ...state,
                category: [...state.category, action.item]
            };
        case "ADD_PRODUCT":
            const finded = state.product.find(p => p.id === action.item.id)
            if (finded) {
                // console.log("Producto encontrado => ", finded)
                // finded = {...action.item}
                return {
                    ...state,
                    product: state.product.map(item => item.id === action.item.id ? { ...action.item } : item)
                }
            }
            return {
                ...state,
                product: [...state.product, action.item]
            };
        case "QUA_PRODUCT":
            const indexProduct0 = state.product.findIndex((basketItem => basketItem.id === action.item.id));
            let refreshProduct0 = [...state.product];
            if (indexProduct0 >= 0) {
                refreshProduct0[indexProduct0].quantity = action.item.quantity;
            } else {
                refreshProduct0 = [...state.product, action.item];
            }
            return {
                ...state,
                product: refreshProduct0
            };
        case "SEARCH_PRODUCT":
            const searchProduct = state.product.findIndex((productItem => productItem.id === action.item.id));
            let Products = [...state.product];
            if (searchProduct >= 0) {
                Products = Products[searchProduct];
            } else {
                Products = [];
            }
            return {
                ...state,
                returnProduct: Products
            };
        case "ADD_TO_BASKET":
            const indexBasket = state.basket.findIndex((basketItem => basketItem.id === action.item.id));
            const indexProduct1 = state.product.findIndex((basketItem => basketItem.id === action.item.id));
            let refreshBasket = [...state.basket];
            let refreshProduct1 = [...state.product];
            var cantidad = 1;
            // console.log(state.product[indexProduct1])
            if (action.item.unit === "grs") {
                cantidad = parseInt(state.product[indexProduct1].weight);
            }
            if (indexBasket >= 0) {
                refreshBasket[indexBasket].quantity = refreshBasket[indexBasket].quantity + cantidad;
                refreshProduct1[indexProduct1].quantity = refreshBasket[indexBasket].quantity;
                refreshProduct1[indexProduct1].unit = refreshBasket[indexBasket].unit;
            } else {
                refreshBasket = [...state.basket, action.item];
                refreshProduct1 = [...state.product];
                refreshProduct1[indexProduct1].quantity = cantidad;
                //refreshProduct1[indexProduct1].unit = refreshProduct1[indexProduct1].primaryUnit;
                refreshProduct1[indexProduct1].unit = action.item.unit;
            }
            localStorage.setItem("cart", JSON.stringify(refreshBasket))
            //console.log(refreshProduct1);
            return {
                ...state,
                basket: refreshBasket,
                product: refreshProduct1
            };
        case "REMOVE_TO_BASKET":
            const indexBasket2 = state.basket.findIndex((basketItem => basketItem.id === action.item.id));
            const indexProduct2 = state.product.findIndex((basketItem => basketItem.id === action.item.id));
            let refreshBasket2 = [...state.basket];
            let refreshProduct2 = [...state.product];
            var cantidad = 1;
            if (action.item.unit == "grs") {
                cantidad = parseInt(state.product[indexProduct2].weight);
            }
            if (indexBasket2 >= 0 && refreshBasket2[indexBasket2].quantity >= 1) {
                refreshBasket2[indexBasket2].quantity = refreshBasket2[indexBasket2].quantity - cantidad;
                refreshProduct2[indexProduct2].quantity = refreshBasket2[indexBasket2].quantity;
            }
            localStorage.setItem("cart", JSON.stringify(refreshBasket2))
            return {
                ...state,
                basket: refreshBasket2,
                product: refreshProduct2
            };
        case "REMOVE_ITEM":
            const index = state.basket.findIndex((basketItem => basketItem.id === action.id));
            const indexProduct = state.product.findIndex((basketItem => basketItem.id === action.id));
            let newBasket = [...state.basket];
            let refreshProduct = [...state.product];
            if (index >= 0) {
                newBasket.splice(index, 1);
                refreshProduct[indexProduct].quantity = 0;
            } else {
                console.log('No se puede eliminar');
            }
            return {
                ...state,
                basket: newBasket,
                product: refreshProduct
            };
        case "CHANGE_UNIT":
            console.log(action.unit);
            const iUnit = state.basket.findIndex((basketItem => basketItem.id === action.id));
            console.log("Unidad")
            console.log(iUnit)
            const iUnitProduct = state.product.findIndex((basketItem => basketItem.id === action.id));
            var cantidad = 1;
            if (action.unit === "grs") {
                cantidad = parseInt(state.product[iUnitProduct].weight);
            }
            let newUnit = [...state.basket];
            let refreshUnit = [...state.product];
            if (iUnit >= 0) {
                newUnit[iUnit].quantity = cantidad;
                newUnit[iUnit].unit = action.unit;
                refreshUnit[iUnitProduct].quantity = cantidad;
                refreshUnit[iUnitProduct].unit = action.unit;
            } else {
                console.log('No se puede actualizar la unidad');
            }
            return {
                ...state,
                basket: newUnit,
                product: refreshUnit
            };
        case "SET_USER":
            return {
                ...state,
                user: action.user
            };
        case "SET_DATE":
            return {
                ...state,
                date: action.date
            };
        case "EMPTY_BASKET":
            localStorage.removeItem("cart")
            return {
                ...state,
                basket: action.basket
            };
        case "EMPTY_PRODUCT":
            return {
                ...state,
                product: action.product
            };
        case "SET_NOTIFICATION":
            return {
                ...state,
                notification: action.notification
            };
        case "ADD_COUPON":
            console.log("Cupon => ",action)
            let tempBasket =[]
            if(action.coupon.applyFor==='All'){
                tempBasket =  [...state.basket]
            }
            if(action.coupon.applyFor.length>0){
                action.coupon.applyFor.map(tax => {
                    state.basket.map( p => {
                        if(p.category===tax){
                            tempBasket.push(p)
                        }
                    })

                })
            }
            const nSubtotal = tempBasket.reduce((amount, item) => (
                (
                    item.defaultUnit === "Pza" ?
                        ((item.price * item.quantity))
                        : item.unit == "Pza" ?
                            (item.priceTotal)
                            : (item.priceTotal * item.quantityTotal)) + amount), 0).toFixed(2);
            const discount = action.coupon.type==='quantity'
                            ? action.coupon.quantity
                            : nSubtotal/100 * action.coupon.quantity
            return {
                ...state,
                coupon: action.coupon,
                discount,
            };
            case 'OPEN_MODAL':
                return {
                    ...state,
                    openModal: action.openModal
                }
        default:
            return state;
    }
}

export default reducer