import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10, 
    flexGrow: 1
  }
});

const data = [
  {
    mes:"Enero",
    cantidad: "$15,777.00",
    crecimiento: "$15,777.00",
    porcentaje: "100%"
  },
  {
    mes:"Febrero",
    cantidad: "$19,772.00",
    crecimiento: "$4,595.00",
    porcentaje: "23%"
  },
  {
    mes:"Marzo",
    cantidad: "$21,000.00",
    crecimiento: "$1,128.00",
    porcentaje: "6%"
  },
  {
    mes:"Abril",
    cantidad: "$12,780.00",
    crecimiento: "$780.00",
    porcentaje: "4%"
  },
  {
    mes:"Mayo",
    cantidad: "$22,885.00",
    crecimiento: "$1,095.00",
    porcentaje: "5%"
  },
  {
    mes:"Junio",
    cantidad: "$15,777.00",
    crecimiento: "$15,777.00",
    porcentaje: "100%"
  },
  {
    mes:"Julio",
    cantidad: "$19,772.00",
    crecimiento: "$4,595.00",
    porcentaje: "23%"
  },
  {
    mes:"Agosto",
    cantidad: "$21,000.00",
    crecimiento: "$1,128.00",
    porcentaje: "6%"
  },
  {
    mes:"Septiembre",
    cantidad: "$12,780.00",
    crecimiento: "$780.00",
    porcentaje: "4%"
  },
  {
    mes:"Octubre",
    cantidad: "$22,885.00",
    crecimiento: "$1,095.00",
    porcentaje: "5%"
  },
  {
    mes:"Noviembre",
    cantidad: "$19,772.00",
    crecimiento: "$4,595.00",
    porcentaje: "23%"
  },
  {
    mes:"diciembre",
    cantidad: "$21,000.00",
    crecimiento: "$1,128.00",
    porcentaje: "6%"
  },
  
]

// Create Document Component
export const PdfDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      
      {/* <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View> */}
      <View style={{flexGrow:1, flexDirection:'column'}} >
      <Text  >Reporte de Ventas</Text>
      <Text  >Allfresco Delibery</Text>
      <View style={{flexGrow:1}} >
        <View style={{backgroundColor:"#B6D33C", width:"100%", height:"40px", flexDirection:'row', justifyContent:'space-around'}} >
          <Text style={{color:"#fff"}} >Mes</Text>
          <Text style={{color:"#fff"}} >Venta</Text>
          <Text style={{color:"#fff"}} >Crecimiento</Text>
          <Text style={{color:"#fff"}} >% frente a mes anterior</Text>
        </View >
        {
          data.map( row => (<View style={{flexDirection:'row', justifyContent:'space-around'}} >
            <Text style={{color:"#B6D33C", width:"25%",textAlign:'left'}} >{ row.mes }</Text>
            <Text style={{width:"25%",textAlign:'left'}} >{ row.cantidad }</Text>
            <Text style={{width:"25%",textAlign:'left'}} >{ row.crecimiento }</Text>
            <Text style={{color:"#B6D33C", width:"25%",textAlign:'left'}} >{ row.porcentaje }</Text>
          </View>) )
        }
      </View>

      </View>
    </Page>
  </Document>
);