// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getStorage } from "firebase/storage";
//import { initializeApp as iApp } from 'firebase-admin/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FB_APPID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENTID
};
*/

const firebaseConfig = {
    apiKey            : process.env.REACT_APP_FIREBASE_APIKEY            ,
    authDomain        : process.env.REACT_APP_FIREBASE_AUTHDOMAIN        ,
    projectId         : process.env.REACT_APP_FIREBASE_PROJECTID         ,
    storageBucket     : process.env.REACT_APP_FIREBASE_STORAGEBUCKET     ,
    messagingSenderId : process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID ,
    appId             : process.env.REACT_APP_FIREBASE_APPID             ,
    measurementId     : process.env.REACT_APP_FIREBASE_MEASEREMENTID     ,
};
/*
var admin = require("firebase-admin");
var serviceAccount = require("./serviceAccountKey.json");
admin.initializeApp({
  credential: admin.credential.cert(serviceAccount)
});*/

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);


// export default {db, createUserWithEmailAndPassword};
export default db;