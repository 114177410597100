import { TextField, Typography, Switch, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { addDoc, collection, doc, getFirestore, setDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import React, { useState } from 'react'
import styled from 'styled-components'
import { addDeliberyCost } from './adminFunctions/functions'
import Coupons from './advanced/coupons'
import DeliberyCosts from './advanced/deliberyCosts'
import UploadBanners from './advanced/uploadBanners'
import TopLegend from './advanced/topLegend'
import { ExpandMoreOutlined } from '@mui/icons-material'
import ListCoupons from './advanced/ListCoupons'

const Settings = () => {
  const db = getFirestore()
  const [checked, setChecked] = useState(false)
  const [useIVA, setUseIVA] = useState(false)
  const [useIEPS, setUseIEPS] = useState(false)
  const [couponCode, setCouponCode] = useState({
    name: "",
    code: "",
    discount: null,
    usage: null,
    dateEnd: ""
  })
  const [imagen, setImagen] = useState(undefined)
  const [imagenFooter, setImagenFooter] = useState(undefined)

  const storage = getStorage()
  // const AllfrescoSwitch = styled((props) => (
  //   <Switch 
  //   value={checked}
  //   onChange={handleCheck}
  //   focusVisibleClassName=".Mui-focusVisible" 
  //   disableRipple {...props} 
  //   />
  // ))(({ theme }) => ({
  //   width: 42,
  //   height: 26,
  //   padding: 0,
  //   marginLeft:15,
  //   marginRight:15,
  //   '& .MuiSwitch-switchBase': {
  //     padding: 0,
  //     margin: 2,
  //     transitionDuration: '300ms',
  //     '&.Mui-checked': {
  //       transform: 'translateX(16px)',
  //       color: '#fff',
  //       '& + .MuiSwitch-track': {
  //         backgroundColor: theme.palette.mode === 'dark' ? '#65C466' : '#B6D33C',
  //         opacity: 1,
  //         border: 0,
  //       },
  //       '&.Mui-disabled + .MuiSwitch-track': {
  //         opacity: 0.5,
  //       },
  //     },
  //     '&.Mui-focusVisible .MuiSwitch-thumb': {
  //       color: '#43ABAE',
  //       border: '6px solid #fff',
  //     },
  //     '&.Mui-disabled .MuiSwitch-thumb': {
  //       color:
  //         theme.palette.mode === 'light'
  //           ? theme.palette.grey[100]
  //           : theme.palette.grey[600],
  //     },
  //     '&.Mui-disabled + .MuiSwitch-track': {
  //       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     boxSizing: 'border-box',
  //     width: 22,
  //     height: 22,
  //   },
  //   '& .MuiSwitch-track': {
  //     borderRadius: 26 / 2,
  //     backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
  //     opacity: 1,
  //     transition: theme.transitions.create(['background-color'], {
  //       duration: 500,
  //     }),
  //   },
  // }));
  const Titulo = (props) => <h5 style={{
    color: "#707070",
    fontSize: "24px",
    marginBottom: "20px",
    marginTop: "20px"
  }} >{props.children}</h5>
  const handleCheck = (e) => {
    setChecked(e.target.checked)
  }
  const CuponInput = () => <input style={{
    background: "#f3f3f3", borderRadius: "5px", width: "150px", height: "35px", border: "none", marginRight: "15px", color: "#707070", paddingLeft: "10px",
  }} />
  const CustomInput = () => <input type={"number"} style={{ background: "#f3f3f3", borderRadius: "10px", width: "60px", height: "30px", border: "none", marginLeft: "15px", marginRight: "15px", color: "#707070", paddingLeft: "10px" }} />

  const Field = styled(TextField)({
    border: 0,
    borderRadius: 10,
    background: '#f3f3f3'
  })

  const addCoupon = async () => {
    try {
      const code = {
        ...couponCode,
        creationDate: new Date(),
      }
      const result = await addDoc(collection(db, 'coupons'), code)
      console.log(result)

      setCouponCode({
        code: "",
        discount: null,
        usage: null,
        dateEnd: ""
      })
      alert("cupon creado exitosamente")
    } catch (error) {
      console.log(error)
    }
  }

  const addDelibery = async()=> {
    const res = await addDeliberyCost();
  }

  // Create the file metadata
  /** @type {any} */
  const metadata = {
    contentType: 'image/jpeg'
  };
  const uploadImage = async (action) => {
    try {
      const storageRef = ref(storage, "banners/", imagen.name,)
      const uploadTask = uploadBytesResumable(storageRef, imagen, metadata)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is on " + progress + "% done");
          switch (snapshot.state) {
            case 'paused':
              console.log("upload is paused")
              break
            case 'running':
              console.log("upload is running")
          }
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              console.log("no tiene acceso")
              break
            case 'storage/canceled':
              console.log("subida de imagen cancelada")
              break
            case 'storage/unknown':
              console.log("Error desconocido")
              break
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              console.log("file available at: " + downloadURL)
              if (action == "main")
                changeMainBanner(downloadURL)
              if (action == "footer")
                changeMainBanner(downloadURL)
            })
          alert("Banner " + action + " subido correctamente")
          setImagen(undefined)
        }
      )
    } catch (error) {
      console.log(error)
    }
  }
  const changeMainBanner = async (imageUrl) => {
    await addDoc(collection(db, 'settings', 'banner', 'main'), {
      url: imageUrl,
      created: new Date(),
    })
  }

  const handleCodeChange = (e) => {
    setCouponCode({
      ...couponCode,
      [e.target.name]: e.target.value
    })
    // console.log(e.target.name+ " + "+ e.target.value )
  }
  const Taxes = ()=> (
    <>
    <Typography variant='h5' sx={{
        color: "#707070", fontSize: "24px", marginBottom: "20px",
      }} >
        Activa el calculo de impuestos
      </Typography>
      <div>
        <Typography>Vendo productos con IVA aplicable
          <Switch
            checked={useIVA}
            onChange={(e) => setUseIVA(e.target.checked)}
          />
          {
            useIVA ?
              <>
                <span>porcentaje</span>
                <input type={"number"} style={{ background: "#f3f3f3", borderRadius: "10px", width: "60px", height: "30px", border: "none", marginLeft: "15px", marginRight: "15px", color: "#707070", paddingLeft: "10px" }} />
                <span>%</span>
              </>
              : null
          }
        </Typography>
        <Typography>
          Vendo productos con IEPS aplicable
          <Switch
            checked={useIEPS}
            onChange={(e) => setUseIEPS(e.target.checked)}
          />
          {
            useIEPS ?
              <>
                <span>porcentaje</span>
                <input type={"number"} style={{ background: "#f3f3f3", borderRadius: "10px", width: "60px", height: "30px", border: "none", marginLeft: "15px", marginRight: "15px", color: "#707070", paddingLeft: "10px" }} />
                <span>%</span>
              </>
              : null
          }
        </Typography>
      </div>
      </>
  )

  const data = [
    {
      name:"Impuestos",
      tag:"taxes",
      component:<Taxes />
    }, {
      name:"Leyenda",
      tag:"leyend",
      component: <TopLegend/>
    },
    {
      name:"Cupones",
      tag:"coupons",
      component: <ListCoupons />
    },
    {
      name:"Nuevo cupon",
      tag:"newcoupon",
      component: <Coupons/>
    },{
      name:"Envio",
      tag:"delibery",
      component: <DeliberyCosts/>
    },{
      name:"Banners",
      tag:"banner",
      component: <UploadBanners/>
    }
  ]

  return (
    <main>
      {
        data.map( dat=> (
          <Accordion key={dat.tag} >
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls={`${dat.tag}-content`} id={`${dat.tag}-header`}>
              <Typography variant='h4' >{dat.name}</Typography>
            </AccordionSummary>
            <AccordionDetails> {dat.component} </AccordionDetails>
          </Accordion>
        ))
      }
    </main>
  )
}

export default Settings