import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useStateValue } from '../../StateProvider';
import FirstStep from './firstStep';
import SecondStep from './secondStep';
import ThirdStep from './thirdStep';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from './CheckoutForm';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import AddressForm from './AddressForm';
// import PaymentForm from './PaymentForm';
// import Review from './Review';
// import { Card } from '@mui/material';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const steps = ['Detalles de entrega',
  'Detalles de pago',
  // 'Revisa tu orden'
];

const appearance = {
  theme: 'night'
};

function getStepContent(step, user) {
  var data = { street: '', exteriorNumber: '', interiorNumber: '', suburb: '', city: '', state: '', zipCode: '', country: '' }

  var usuario = { displayName: '' }
  switch (step) {
    case 0:
      if (user != null) {
        if (user.displayName == null) {
          user.displayName = '';
        }
        if (user.address == null) {
          user.address = data;
        }
        return <FirstStep address={user.address} user={user} />;
      } else {
        return <FirstStep address={data} user={usuario} />;
      }
    case 1:
      return <SecondStep address={data} user={usuario} />;
    case 2:
      if (user != null) {
        if (user.displayName == null) {
          user.displayName = '';
        }
        if (user.address == null) {
          user.address = data;
        }
        return <ThirdStep address={user.address} user={user} />;
      } else {
        return <ThirdStep address={data} user={usuario} />;
      }
    default:
      throw new Error('Paso desconocido');
  }
}

const theme = createTheme();

export default function Checkout() {
  var cS = "";
  const options = {
    // passing the client secret obtained from the server
    // clientSecret: cS,
    appearance: appearance
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [{ user, date, basket }, dispatch] = useStateValue();
  // console.log("address")
  // console.log(user?.address)
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      {/* <CssBaseline /> */}
      <Container component="main" className='contenido' sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Finaliza tu pedido
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Gracias por su pedido.
                </Typography>
                {/* <Elements stripe={stripePromise} >
                  <CheckoutForm />
                </Elements> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, user)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button variant='contained' onClick={handleBack} sx={{ mt: 3, ml: 1 }}
                      disabled={basket.length!==0?false:true}
                    >
                      Regresar
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1, background: "#B6D33C", textTransform: "none", fontWeight: "bold", display: activeStep === steps.length - 1 ? "none" : "block" }}
                    disabled={user?.address.city === "" || !date.dateDelivery ||date ==="" ? true : false}
                  >
                    {activeStep === steps.length - 1 ? 'Completar pedido' : 'Siguiente'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </>
  );
}