import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Information from './information';
import Orders from './orders';
import '../../styles.css';
import Wallet from './wallet';
import CreditCard from './Card/CreditCard';
import Password from './password';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { signOut, getAuth } from 'firebase/auth';
// import { display } from '@mui/system';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Profile() {
  const [{ user, basket }, dispatch] = useStateValue();
  const [value, setValue] = React.useState(0);
  const [showPopMenu, setShowPopMenu] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowPopMenu(false);
  };

  const popMenu = () => {

  }
  const handleSignOut = () => {
    if (user) {
      const auth = getAuth();
      signOut(auth).then(() => {
        dispatch({
          type: actionTypes.EMPTY_BASKET,
          basket: [],
        });
        dispatch({
          type: actionTypes.SET_USER,
          user: null,
        });
        // history("/");
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  return (
    <Box sx={{ marginTop: "2rem" }} className="contenido">
      <Button sx={{ display: { md: "none", xs: "block" }, marginLeft: "autos", marginRight:"0" }} onClick={() => setShowPopMenu(true)} > <span className="icon-Atras-mvil-allfresco"></span></Button>
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', display: { xs: "none", md: "block" } }}
          fullWidth
        >
          <Avatar
            alt="Avatar"
            src={user.photoURL?user.photoURL:"/static/images/avatar/1.jpg"}
            sx={{ width: { md: 80, xs: 20 }, height: { md: 80, xs: 20 }, margin: 'auto', }}
            {...a11yProps(0)}
          />
          <Tab label="Información" {...a11yProps(1)} />
          <Tab label="Mis pedidos" {...a11yProps(2)} />
          <Tab label="Wallet" {...a11yProps(3)} />
          <Tab label="Contraseñas" {...a11yProps(4)} />
          <Tab label="Métodos de pago" {...a11yProps(5)} />
          <Tab label="Cupones" {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={value} index={0} className="TabsProfile"  >
          <Information />
        </TabPanel>
        <TabPanel value={value} index={1} className="TabsProfile">
          <Information />
        </TabPanel>
        <TabPanel value={value} index={2} className="TabsProfile"  >
          <Orders />
        </TabPanel>
        <TabPanel value={value} index={3} className="TabsProfile j-ma">
          <Wallet />
        </TabPanel>
        <TabPanel value={value} index={4} className="TabsProfile">
          <h1>Esta pagina no esta disponible por el momento</h1>
          {/* <Password /> */}
        </TabPanel>
        <TabPanel value={value} index={5} className="TabsProfile">
          {/* <CreditCard /> */}
          <h1>Por el momento esta funcion no esta disponible</h1>
        </TabPanel>
        <TabPanel value={value} index={6} className="TabsProfile">
          Cupones
        </TabPanel>
      </Box >




      <Box sx={{ position: "fixed", top: "110px", left: "0px", backgroundColor: "#fff", width: "100%", height: " 85%", zIndex: "9" }} display={{ xs: showPopMenu ? "block" : "none", md: "none" }}>
        <Button  sx={{marginTop:"1.5rem"}} onClick={() => setShowPopMenu(false)} >  <span className="icon-Atras-mvil-allfresco"></span>  </Button>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', display: "flex", alignItems: "center" }}
        >
          <Avatar
            alt="Avatar"
            src={user.photoURL?user.photoURL:"/static/images/avatar/1.jpg"}

            sx={{ width: { md: 80, xs: 60 }, height: { md: 80, xs: 60 }, margin: 'auto', }}
            {...a11yProps(0)}
          />
          <Tab label="Información" {...a11yProps(1)} sx={{ color: "#43ABAE" }} />
          <Tab label="Mis pedidos" {...a11yProps(2)} sx={{ color: "#43ABAE" }} />
          <Tab label="Wallet" {...a11yProps(3)} sx={{ color: "#43ABAE" }} />
          <Tab label="Contraseñas" {...a11yProps(4)} sx={{ color: "#43ABAE" }} />
          <Tab label="Métodos de pago" {...a11yProps(5)} sx={{ color: "#43ABAE" }} />
          <Tab label="Cupones" {...a11yProps(6)} sx={{ color: "#43ABAE" }} />
          <Button variant='contained' sx={{marginTop:"1rem"}} onClick={handleSignOut} >Cerrar sesion</Button>
        </Tabs>
      </Box>


    </Box>

  );
}
