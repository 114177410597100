
import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import logo from '../assets/cereza.png';
import '../styles.css';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Link } from 'react-router-dom';
// import InstaIcon from '@mui/icons-material/Instagram';
// import PhoneIcon from '@mui/icons-material/Phone';


const Img = styled('div')(({ theme }) => ({
  img: {
    width: '100px',
  },
}));

export default function Footer() {
  return (
    <Box component='footer' >
      <CssBaseline />
      <div className='fcolor' style={{ maxHeight: "215px", width: "100%", paddingBottom: "2rem" }} >
        <div className='footer-c'>
          <Grid container className='footer' p={'1.5rem'} color={'#43abae'} spacing={2} >
            <Grid item md={2.4} xs={12} >
              <Box textAlign={'center'}>
                <Img><img src={logo} sx={{ maxWidth: 20 }} alt="Imagen no disponible" /></Img>
                <Typography variant='h2'>allfresco</Typography>
                <Typography color={'primary.main'} variant='p'>©2023 Allfresco</Typography>
              </Box>
            </Grid>
            <Grid item md={2.4} sm={6} xs={12}>
              <Box direction={'column'}>
                <Link to="#" ><Typography variant='h4' gutterBottom>Acerca de Allfresco</Typography></Link>
                <Link to="/preguntas-frecuentes" ><Typography >Preguntas Frecuentes</Typography></Link>
                <Link to="/nosotros" ><Typography >Sobre Nosotros</Typography></Link>
                <Link to="promociones" ><Typography >Promociones</Typography></Link>
                <Link to="/envio" ><Typography >Sobre mi envío</Typography></Link>
                <Link to="/cobertura" ><Typography >Cobertural actual</Typography></Link>
              </Box>
            </Grid>
            <Grid item md={2.4} sm={6} xs={12} >
              <Box direction={'column'}>
                <Typography variant='h4' gutterBottom>Privacidad</Typography>
                <Link to="/politica-de-cookies"><Typography>Política de cookies</Typography></Link>
                {/* <Link to="/nosotros"><Typography>Política de privacidad</Typography></Link> */}
                <Link to="/terms" ><Typography>Términos y condiciones</Typography></Link>
                {/* <Link to="/return-policy" ><Typography>Politicas de devolucion</Typography></Link> */}
                <Link to="/politicas-de-privacidad" ><Typography>Políticas de privacidad</Typography></Link>

              </Box>
            </Grid>
            <Grid item md={2.4} sm={6} xs={12} >
              <Box direction={'column'}>
                <Typography variant='h4' gutterBottom>Ayuda</Typography>
                <Link to="/soporte"><Typography>Soporte de problemas</Typography></Link>
                <a href='tel:2224429809' target='_blank'><Typography>+52 2224429809</Typography></a>
                <a href='mailto:ayuda@allfresco.mx' ><Typography>ayuda@allfresco.mx</Typography></a>
                <a href="https://play.google.com/store/apps/details?id=space.marsbot.allfresco" target='_blank' rel="noopener noreferrer" >
                  <img style={{ width: "160px", height: 'auto' }} src='/PlayStore.png' loading='lazy' alt='Link para descargar la app de allfresco en playstore de Google' />
                </a>
              </Box>
            </Grid>
            <Grid item md={2.4} sm={6} xs={12} >
              <Box direction={'column'} marginBottom={{ xs: "2em", sm: "0px" }}  >
                <Typography variant='h4' gutterBottom>Social</Typography>
                <Grid container spacing={2}>
                  <Grid item><a href="https://www.facebook.com/allfrescomx" target='_blank' rel="noopener noreferrer"><FacebookRoundedIcon /></a></Grid>
                  <Grid item><a href="https://wa.link/mdkmvr" target='_blank' rel="noopener noreferrer">
                    <svg width="38.252" height="21" viewBox="0 0 38.252 38.232">
                      <path id="Trazado_1912" data-name="Trazado 1912" d="M287.917,18.083v2.242c-.031.269-.062.538-.091.807a18.57,18.57,0,0,1-4.641,10.63,18.725,18.725,0,0,1-17.021,6.377,17.879,17.879,0,0,1-7.57-2.775.731.731,0,0,0-.692-.082c-2.187.709-4.378,1.406-6.567,2.106-.121.039-.244.075-.415.127.732-2.18,1.424-4.293,2.16-6.392a1.31,1.31,0,0,0-.173-1.322,18.69,18.69,0,0,1-3.148-12.512,18.181,18.181,0,0,1,3.1-8.654A18.877,18.877,0,0,1,265.491.372c.719-.132,1.45-.2,2.175-.3h2.242a2.068,2.068,0,0,0,.289.061,18.2,18.2,0,0,1,8.346,2.632,18.877,18.877,0,0,1,9.076,13.146c.134.717.2,1.447.3,2.17M275.041,30l.022-.089a5.745,5.745,0,0,0,.633-.04,5.681,5.681,0,0,0,.908-.206c2.724-.939,3.643-2.26,3.711-4.764a.778.778,0,0,0-.485-.8c-1.425-.679-2.841-1.376-4.272-2.043a1.141,1.141,0,0,0-1.645.448c-.371.5-.734,1-1.118,1.494a1.309,1.309,0,0,1-1.879.483,15.176,15.176,0,0,1-7.328-6.489.834.834,0,0,1,.074-1.065c.3-.35.593-.707.918-1.032a1.747,1.747,0,0,0,.4-2.4.256.256,0,0,1-.013-.035c-.568-1.338-1.126-2.681-1.716-4.01a.921.921,0,0,0-.5-.5,3.327,3.327,0,0,0-2.923.418,5.275,5.275,0,0,0-1.723,2.393,7.157,7.157,0,0,0,.3,5.514,18.447,18.447,0,0,0,2.252,3.641,26.426,26.426,0,0,0,4.985,5.174,20.328,20.328,0,0,0,6.867,3.342c.828.245,1.691.377,2.537.561" transform="translate(-249.665 -0.074)" fill="#43abae" />
                    </svg>
                  </a></Grid>
                  <Grid item><a href='https://www.tiktok.com/@allfrescomx' target='_blank' rel="noopener noreferrer">

                    <svg xmlns="http://www.w3.org/2000/svg" width="38.266" height="38" viewBox="0 0 38 38"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" fill="#43abae" /></svg>
                  </a></Grid>
                  <Grid item><a href='https://www.instagram.com/allfrescomx/' target='_blank' rel="noopener noreferrer">
                    <svg width="38.266" height="21" viewBox="0 0 38.266 38.27">
                      <path id="Trazado_1907" data-name="Trazado 1907" d="M465.483,28.545V9.714c.063-.391.1-.787.191-1.172A10.363,10.363,0,0,1,473.483.315C474.04.159,474.625.1,475.2,0h18.831a21.478,21.478,0,0,1,2.4.509,10.426,10.426,0,0,1,7.31,10.027c.023,5.727.008,11.455,0,17.182a9.965,9.965,0,0,1-1.314,5.11,10.292,10.292,0,0,1-9.336,5.423c-5.652.036-11.305.015-16.958,0a9.9,9.9,0,0,1-2.114-.194,10.325,10.325,0,0,1-8.22-7.792c-.156-.559-.213-1.146-.316-1.72m19.141-19.9A10.486,10.486,0,1,0,495.1,19.159,10.5,10.5,0,0,0,484.624,8.643M498.912,8a3.184,3.184,0,0,0-6.368.023A3.184,3.184,0,1,0,498.912,8" transform="translate(-465.483)" fill="#43abae" />
                    </svg>
                  </a></Grid>
                </Grid>

                {/* <Button className='btn-join' sx={{
                  fontFamily: 'Quicksand',
                  fontSize: '20px',
                  color: 'white',
                  lineHeight: '32px',
                  fontWeight: '700',
                  backgroundColor: '#b6d33c',
                  borderRadius: '10px',
                  margin: "0.5rem",
                  marginLeft: 0,
                  textTransform: 'none',
                  // marginBottom: "2.5rem",
                  '&:hover': {
                    backgroundColor: "#88A023"
                  }
                }} >Invitar a un amigo</Button> */}

                <a href="https://apps.apple.com/app/allfresco-delivery/id6457581327" target='_blank' rel="noopener noreferrer" >
                  <img style={{ width: "140px", height: 'auto' }} src='/AppStore.png' loading='lazy' alt='Link para conseguir la app de allfresco en appstore de apple' />
                </a>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );
}