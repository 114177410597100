import { AppBar, Avatar, Box, Button, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { useStateValue } from '../../StateProvider'

const NavRepartidor = ({role}) => {
  const [{user},dispatch] = useStateValue()

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const Title = ({text})=> {
    return ( <span style={{
      fontFamily:"Quicksand",
      fontWeight:'bold',
      color:"#b6d33c",
    }} > 
      {text}
    </span> )
  }

  return (
    <Box sx={{flexGrow:1,  }} >
      <AppBar position='static' color='transparent' sx={{padding:1}}  >
        <Toolbar  >
          <Box flexDirection='row' display='flex' alignItems='center' >
            <Avatar alt={user.displayName} src={user.photoUrl} sx={{marginRight:"5px"}} {...stringAvatar(user.displayName)} />
            <Title text={user.displayName} />
          </Box>
          <Box flexGrow={1} ></Box>
          <Title text={role} />
        </Toolbar>
      </AppBar>
    </Box>  
  )
}

export default NavRepartidor