import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import db from '../../../firebase/fbcnf'
import { Box, IconButton, Typography, styled } from '@mui/material'
import { DeleteOutlineRounded } from '@mui/icons-material'

const ListCoupons = () => {
  const [coupons, setCoupons] = useState([])
  const getCopuon = async()=>{
    try {
      const result = await getDocs(collection(db,'coupons'))
      if(result){
        let arr=[]
        result.forEach(element => {
          arr.push(element.data())
        });
        // for(row of result){
        //   arr.push(row)
        // }
        setCoupons([...arr])
        console.log("arrays ",arr)
      }
      console.log("cupons => ",coupons)
    } catch (error) {
      console.log("error ",error)
    }
  }
  useEffect(() => {
    getCopuon()
  }, [])
  
  const CircleL = ()=>{
    return <div
      style={{background:"white", width:"30px",height:"30px",borderRadius:"50%",position:'relative',top:"55px",
      left:"-40px"
    }}
    >    </div>
}
const CircleR = ()=>{
  return <div
    style={{background:"white", width:"30px",height:"30px",borderRadius:"50%",position:'relative',top:"55px",
    right:"-40px"
  }}
  >    </div>
}
const Text = styled(Typography)(({theme})=>{

})
const handleDelete= async(id)=> {
  try {
    if(window.confirm("esta seguro de eliminar permanentemente el cupon?")  ){
      console.log("Borrar ", id)
      await deleteDoc(collection(db, 'coupons', id))
      await getCopuon()
      return
    }
    console.log(" cancelar")
        
  } catch (error) {
    console.log("error al borrar ",error)
  }
}
  return (
    <div>
        { coupons.map(c=>(
          <Box sx={{background:"#B6D33C"}} margin={2} borderRadius="10px" key={c.code} height={"180px"} padding={3} >
          <div style={{display:'flex', justifyContent:'space-between'}} >
            <CircleL /> <CircleR/>
          </div>
          <div style={{marginTop:"-40px"}} >
            <Typography color='white' variant='h6'>{c.title}</Typography>
            <Typography color='white'><em>{c.code}</em></Typography>
            <Typography color='white'> {c.text} </Typography>
            <Typography color='white'> {c.quantity} tipo: {c.type} </Typography>
            <Typography color='white'>{c.used} / {c.limit}</Typography>
            <Typography color='white'> {c.dateStart.toString()} - {c.dateEnd.toString()} </Typography>
            <div style={{display:'flex', justifyContent:'flex-end'}} >
              <IconButton  color='warning' onClick={()=>handleDelete(c.code)} sx={{marginTop:"-60px", background:"white"
              }} >
                <DeleteOutlineRounded  fontSize='large'/>
              </IconButton>
            </div>
          </div>
          </Box>
        )) }
        
    </div>
  )
}

export default ListCoupons