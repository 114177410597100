import { Box, Typography } from '@mui/material';
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { React, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#c8db7a',
    },
    '& .MuiRating-iconHover': {
        color: '##B6D33C',
    },
});

const UserOrders = ({ id, orderData, userName }) => {
    const [userInfo, setUserInfo] = useState({})
    const db = getFirestore();
    console.log(orderData)
    const getUserInfo = async () => {
        const datos = await getDoc(doc(db, 'address', id))
        if(datos.exists()){
            console.log(datos.data()?.main)
            setUserInfo(datos.data()?.main)
        }
        // setOrderList(datos.data().orders)
    }
    // console.log(orderData)
    useEffect(() => {
        getUserInfo()
        // getuserOrders()
        // Object.keys(orderList).forEach( (order)=>console.log(orderList[order]) )
    }, [])
    return (
        <div >
            {
                JSON.stringify(userInfo) === "{}" ? <Box m={2}>
                    <Typography variant='h1' > No hay informacion disponible </Typography>
                </Box> :
                    <Box m={2} >
                        <Typography variant='h5' >{userName}</Typography>
                        <Typography>{id}  </Typography>
                        <Typography> {userInfo.street}, {userInfo.exteriorNumber} </Typography>
                        <Typography>C.P: {userInfo.zipCode}</Typography>
                        <Typography> {userInfo.suburb}, Puebla, Mexico </Typography>
                        <Box display="flex" flexWrap="wrap" >
                            {
                                JSON.stringify(orderData)==="{}"?<h6>Aun no hay pedidos</h6>:Object.values(orderData).map((value) =>
                                (<Box m={1.5} p={1} borderRadius="20px" width={{ xs: "100%", sm: "45%", md: "28%" }} sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}  >
                                    <Typography variant='h4' color="primary" >Pedido {value.id}</Typography>
                                    <Typography>{value.email}</Typography>
                                    <Typography>Emision: {value.date.toDate().toString()}</Typography>
                                    <Typography>Puebla, Pue, Mexico</Typography>
                                    <Typography>Entrega:{value.time.dateDelivery} hora: {value.time.time}</Typography>
                                    <Box sx={{
                                        '& > legend': { mt: 2 },
                                    }} >
                                        {/* <Typography component="legend">Calificacion</Typography> */}
                                        <StyledRating
                                            name="customized-color"
                                            defaultValue={2}
                                            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                            precision={0.5}
                                            icon={<FavoriteIcon fontSize="inherit" />}
                                            emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                                        />
                                    </Box>
                                </Box>)
                                )
                            }
                        </Box>
                        {/* <Typography>Pedidos</Typography> */}
                    </Box>
            }
        </div>
    )
}

export default UserOrders