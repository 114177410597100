import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import AddressCard from '../user/addressCard';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import Resume from './Resume';
import { FormControl, FormGroup, Radio, RadioGroup } from '@mui/material';
import { CheckoutForm } from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function SecondStep({ address: { street, exteriorNumber, interiorNumber, suburb, city, state, zipCode, country }, user: { displayName } }) {
    const [stripePromise, setStripePromise] = React.useState(() => loadStripe(process.env.REACT_APP_STRIPE_PK));
    var direccion = "";
    if (street != '') {
        direccion = street + ", #" + exteriorNumber + " " + interiorNumber + ", " + suburb;
    }

    const today = new Date();
    const tomorrow = new Date(today);
    let getTomorrow = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
    const dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const [value, setValue] = React.useState('10:00 a.m - 11:00 a.m');
    const [{ date }, dispatch] = useStateValue("10:00 a.m - 11:00 a.m");
    const handleChange = (event) => {
        setValue(event.target.value);
        dispatch({
            type: actionTypes.SET_DATE,
            date: event.target.value,
        });
    };
    const validateDay = () => {
        console.log(today.getHours() + " - " + (date.time.substring(0, 2)))
        if (today.getHours() > (date.time.substring(0, 2)))
            return console.log("true")
        else
            return console.log("false")

    }

    validateDay()
    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Resume />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='h2' marginBottom="15px" >Metodo de Pago</Typography>
                        <Typography variant='h6' >Nombre</Typography>
                        <TextField
                            fullWidth
                            size='small'
                        />
                        <Typography variant='h6' >Tarjeta</Typography>
                        <Elements stripe={stripePromise} >
                            <CheckoutForm />
                        </Elements>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}