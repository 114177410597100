import * as React from 'react';
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { actionTypes } from '../reducer';
import { useStateValue } from '../StateProvider';
import { ButtonGroup } from '@mui/material';
import { getFirestore, doc, setDoc, collection, addDoc } from "firebase/firestore";
import { useState } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
// import IconButton from '@mui/material/IconButton';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import CardHeader from '@mui/material/CardHeader';
// import { styled } from '@mui/material/styles';

export default function Product({ product: { id, name, productType, price, image, description, quantity, docId, category, primaryUnit, piecesPerKg, unit, weight, defaultUnit, stock, organico }, handleClick }) {
  const [{ basket, user, }, dispatch] = useStateValue();
  const [unidad, setUnit] = React.useState(defaultUnit);
  const [showGroupButton, setShowGroupButton] = useState(quantity > 0 ? true : false)
  const db = getFirestore();
  const [outOfStock, setOutOfStock] = useState(false)
  const [state, setState] = React.useState(false);
  const handleClose = (e, reason) => {
    if (reason === "clickaway") return;
    setState(false);
  };
  React.useEffect(() => {
    if(quantity==0) removeItem()
  }, [quantity])

  const { vertical, horizontal, open } = state;
  var url = "/" + category + "/" + id;
  const uPza = async () => {
    removeItem();
    setUnit("Pza");
    setSelected(true)
  }
  const uKg = async () => {
    removeItem();
    setUnit("grs");
    setSelected(false)
  }
  const [selected, setSelected] = useState(() => {
    if (defaultUnit === "Pza") {
      setUnit("Pza")
      return true
    } else {
      setUnit("grs")
      return false
    }
  })
  const addToBasket = async () => {
    if(unidad=="Pza"){
      if(stock <= quantity) return ;
    } else{
      let convertUnits = quantity/weight;
      if(stock<=convertUnits) return
    }
    // setState(true)
    var cantidad = 1;
    if (unidad.toLowerCase() === "grs") {
      // cantidad = 250;
      cantidad = parseInt(weight);
    }
    dispatch({
      type: actionTypes.ADD_TO_BASKET,
      item: {
        id,
        name,
        productType,
        image,
        price,
        description,
        piecesPerKg,
        quantity: cantidad,
        docId,
        category,
        unit: unidad,
        weight,
        defaultUnit
      }
    });
    

    try {
      var data = {};
      var existe = false;

      if (basket.length == 0) {
        data[docId] = { quantity: quantity + cantidad, unit: unidad, weight }
      }
      Object.keys(data).forEach(key => {
        if (key == docId) {
          existe = true;
        }
      })
      if (!existe) {
        data[docId] = { quantity: quantity + cantidad, unit: unidad }
      }
      basket.forEach(function (element) {
        if (element.docId != docId) {
          data[element.docId] = { quantity: element.quantity, unit: element.unit, weight: element.weight, price:element.price }
        }
      })
      await setDoc(doc(db, "cart", user.uid), {
        data
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    setState({ open: true });
    handleClick(image, quantity=quantity+cantidad, unidad, name)
    alertStock() ;
  }
  const removeToBasket = async () => {
    var cantidad = 1;

    if (unidad == "grs") {
      cantidad = parseInt(weight);
    }
    dispatch({
      type: actionTypes.REMOVE_TO_BASKET,
      item: {
        id,
        name,
        productType,
        image,
        price,
        description,
        quantity: cantidad,
        docId,
        unit: unidad,
        weight
      }
    });
    if(stock>quantity) setOutOfStock(false)
      console.log("stock: "+outOfStock)
    if ((quantity - cantidad) > 0) {
      try {
        var data = {};
        var existe = false;

        if (basket.length == 0) {
          data[docId] = { quantity: quantity + cantidad, unit: unidad }
        }
        Object.keys(data).forEach(key => {
          if (key == docId) {
            existe = true;
          }
        })
        if (!existe) {
          data[docId] = { quantity: quantity - cantidad, unit: unidad, }
        }
        basket.forEach(function (element) {
          if (element.docId != docId) {
            data[element.docId] = { quantity: element.quantity, unit: element.unit }
          }
        })
        await setDoc(doc(db, "cart", user.uid), {
          data
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      
    }
  }
  const removeItem = async () => {
    dispatch({
      type: actionTypes.REMOVE_ITEM,
      id: id,
    });
    try {
      var data = {};
      basket.forEach(function (element) {
        if (element.docId !== docId) {
          data[element.docId] = { quantity: element.quantity, unit: element.unit }
        }
      })
      if(user!==null){
        await setDoc(doc(db, "cart", user.uid), {
          data
        });
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  const handleEmail = async () => {
    const email = "luis@marsbot.space"
    const body = 'Hello, this is a alert. Your time has expired'
    const subject = 'notify, check your password'
    const collectionRef = collection(db, 'mail');
    const emailContent = {
      to: email,
      message: {
        subject: subject,
        text: body,
        html: `<p>${body}</p>`,
      },

      // to: ['luis@marsbot.space'],
      // message: {
      //   subject: 'Hello from Firebase!',
      //   text: 'This is the plaintext section of the email body.',
      //   html: 'This is the <code>HTML</code> section of the email body.',
      // }
    };
    console.log('listo para ser enviado');
    const res = await addDoc(collectionRef, emailContent);
    console.log(res)
    return res
  }

  const alertStock = ()=>{
    if(unidad=="Pza"){
      if(stock<=quantity) {
        setOutOfStock(true)
         return
      }
    } else {
      const units = quantity/weight
      if(stock<=units){
        setOutOfStock(true);
        return ;
      } 
    } 
    setOutOfStock(false)
    
  }
  const OrganicTag = ()=> {
    return <>
    <div style={{
      width:0,
      height:0,
      borderRight:"0px solid transparent",
      borderLeft:"10px solid transparent",
      borderBottom:"10px solid #e6c501",
      position:"relative",
      left:"-10px",
      top:"16px"
    }} ></div>
    <span style={{
      color: 'white',
      background: '#fcd700',
      padding:'0.4em',
      borderRadius: '0 10px 10px 0',
      position: 'relative',
      left: '-10px',
      top:"20px",
      fontFamily:'Quicksand',
      fontWeight: 'bold',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
      margin:"0px"
    }} >Organico</span></>
  }

  return (
    <>
          {organico &&<OrganicTag />}
          
      <Card sx={{ maxWidth: 345,marginTop:organico?"-33px":"0px" }}>
        <div className='product-int'>

          {/* <Box>
            <Button onClick={handleEmail} >Enviar email</Button>
          </Box> */}
          {/* <OrganicTag /> */}

          <Link to={url}>
            <CardMedia
              component="img"
              height="194"
              image={image}
              alt={`Imagen de ${name}`}
              loading='lazy'
            />
          </Link>
          <CardContent sx={{ display: 'flex', justifyContent: "center", paddingBottom: 0 }}>
            <Typography variant="h2" >
              {name}
            </Typography>
          </CardContent>
          <CardContent sx={{ display: 'flex', justifyContent: "center" }}>
            <Typography fontWeight="400!important" variant='subtitle2' color="text.secondary" fontFamily="Raleway" >
              ${
                primaryUnit==='Pza'
                ? (`${price} Pza`)
                :unidad == "Pza" ? (
                  (price / 1000 * weight).toFixed(2) + " por pza"
                ) : (
                  (price) + " por " + " Kg"
                )
              }
            </Typography>
          </CardContent>
          <Button color='primary' variant='contained' size='large' onClick={() => setShowGroupButton(true)}
            sx={{ display: showGroupButton ? "none" : "block", margin: "auto", marginY: "23.5px", paddingX: { sx: "auto", md: "3rem" }, background: "#B6D33C", fontWeight: "bold", textTransform: "none" }}
              disabled={stock<=quantity?true:false}
          >Agregar </Button>
          <Box display={showGroupButton ? "block" : "none"} >
            <CardActions sx={{ justifyContent: "center", padding: "0px" }}>
              <ButtonGroup color="secondary" variant="contained" aria-label="outlined primary button group" fullWidth sx={{ boxShadow: 0, borderRadius: '10px', border: "none" }}>
                <Button onClick={uPza} sx={{borderRadius:"10px", '&.MuiButtonGroup-grouped:not(:last-of-type)':{borderRadius:"10px", border:"none"}}} color={selected ? "secondary" : "inactive"} >Pieza</Button>
                <Button onClick={uKg} sx={{ display: defaultUnit === "Pza" ? "none" : "block", borderRadius: "0 10px 10px 0" }} color={selected ? "inactive" : "secondary"} >Kilo</Button>
                {
                  // defaultUnit === 'Pza' ? <Box width="50%" ></Box> : null
                }
              </ButtonGroup>
            </CardActions>
            <CardActions sx={{ justifyContent: "center", paddingX: "0" }}>
              <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth sx={{ boxShadow: 0, borderRadius: '10px' }}>
                <Button onClick={removeToBasket} sx={{ width: "50px", borderRadius: "10px 0 0 10px" }} color="secondary" className='min-btn'>-</Button>
                <Button sx={{ color:"#707070" ,fontFamily:"Raleway !important" ,fontSize:{xs:"0.7rem",sm:"0.9rem"} , textTransform: "none",  }} className="card-btn-unit" color='inactive'>{quantity} {unidad == "grs" ? "g" : "Pza"}</Button>
                <Button onClick={  addToBasket  } sx={{ width: "50px", borderRadius: "0 10px 10px 0" }} color="secondary" className='plus-btn'>+</Button>
              </ButtonGroup>
            </CardActions>
          </Box>
        </div>
        {
          outOfStock?<Typography variant='subtitle1' color="tomato" textAlign="center" >
          No hay suficiente stock
        </Typography>: <Box height="28px" ></Box>
        }
        
      </Card>
    </>
  );
}