// import CardMedia from '@mui/material/CardMedia';
// import { collection, query, orderBy, onSnapshot } from 'firebase/firestore'
// import db from '../firebase/fbcnf'
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import { useState } from 'react';
import { useStateValue } from '../StateProvider';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

const Categories = () => {

    const [{ category }, dispatch] = useStateValue()
    const [categories, setCategories] = useState([...category])
    // console.log("category")
    // console.log(category)

    // useEffect(() => {
    //     setCategories([...category])
    //     // const { label } = category[0]
    //     console.log(category)
    // }, [category])


    return (
        <div className="contenido" >
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 8, sm: 8, md: 12 }} sx={{ padding: 2 }}>

                {
                    category.map((c) => {
                        return <Grid item xs={4} sm={4} md={4}>
                            <Card >
                                <Link to={"/" + c.label} >
                                    <CardActionArea>
                                        {/* <CardMedia
                                    component="img"
                                    height="280"
                                    image="https://images.unsplash.com/photo-1551963831-b3b1ca40c98e"
                                    alt="green iguana"
                                /> */}
                                        <Box sx={{
                                            backgroundImage: `url(${c.image})`,
                                            // backgroundImage:"url('https://images.unsplash.com/photo-1551963831-b3b1ca40c98e')",
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            width: "100%",
                                            height: "20vh",
                                        }} >
                                            <Typography gutterBottom variant="h5" component="div" display="flex" justifyContent="center" alignItems="center" height="100%" color="white" fontWeight="bold"
                                                sx={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                                            >
                                                <Link to={"/" + c.label} >
                                                    {c.label}
                                                </Link>
                                            </Typography>

                                        </Box>
                                        {/* <CardContent>
                                        </CardContent> */}
                                    </CardActionArea>
                                    {/* <Link to={"/"+c.label} >
                                        {c.label} */}
                                </Link>
                            </Card>
                        </Grid>
                    })
                }
                {/* <Box component="span" sx={{ m:2, p: 2, border: '1px dashed grey',justifyContent:"center"}}> */}


                {/* </Box> */}
            </Grid>
        </div>
    );
}
export default Categories