import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const CookiesModal = () => {
  const [showModal, setShowModal] = useState(false)
  function acceptCookies(){
    localStorage.setItem('acceptCookies', "true")
    setShowModal(false)
  }
  function getCookiesConcent(){
    const result = localStorage.getItem('acceptCookies')
    if(!result){
      setShowModal(true)
    }
  }

  useEffect(() => {
    getCookiesConcent()
  
  }, [])
  if(!showModal)
    return <></>
  
    const stylesModal = {
      
    }
  return (
    <Box height={{xs:190,md:160}} zIndex={9} sx={{position:'fixed', bottom:0, right:0, left:0, background:"white",boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px" }} >
      <section style={{maxWidth:"1080px",margin:"auto", padding:"1em",}} >
        <Typography marginY={1} variant='h4' >Aceptar las cookies</Typography>
        <Typography fontSize={{xs:"12px !important"}} >Usamos cookies para mejorar tu experiencia de navegación  en nuestra web, 
          para mostrarle contenidos personalizados y para analizar el tráfico de nuestra web. <Link to={'/politica-de-cookies'} ><strong>Ver polìtica de cookies. </strong></Link>
        Al continuar navegando aceptas nuestra política de cookies.</Typography>
        <Button variant='contained' onClick={acceptCookies} >Aceptar</Button>
      </section>
    </Box>
  )
}

export default CookiesModal