import React from 'react'
import { MenuItem, Button, Box, Menu, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const NavbarClient = () => {

  const [anchorEli, setAnchorEli] = React.useState(null);
  const open = Boolean(anchorEli);
  const displayCategories = (event) => {
    setAnchorEli(event.currentTarget);
  };
  const hideCategories = () => {
    setAnchorEli(null)
  };

  const navItems = [
    {
      label: "Lácteos",
      href:"/Lacteos"
    },
    {
      label: "Frutas",
      href:"/Frutas"
    },
    {
      label: "Verduras",
      href:"/Verduras"
    },
    {
      label: "Panadería",
      href:"/Panaderia"
    },
    {
      label: "Granos",
      href:"/Granos"
    },
    {
      label: "Salchichonería",
      href:"/salchichoneria"
    },
  ]

  const linkStyles = { 
    color: "#43ABAE", 
    marginX: "10px", 
    transition:"color 0.5s ease-in-out",
    fontSize:"0.875rem",
    '&:hover':{
      color:"#b6d33c", 
      fontWeight:"bold", 
    },
  }
  const subMenuItems = [
    {
      label:"Carnes y pescados",
      href:"/Carnes"
    },
    {
      label:"Despensa",
      href:"/Despensa"
    },
    {
      label:"Limpieza",
      href:"/Limpieza"
    },
    {
      label:"Higiene",
      href:"/Higiene"
    },
    {
      label:"Farmacia",
      href:"/Farmacia"
    },
    {
      label:"Bebidas",
      href:"/Bedibas"
    },
    {
      label:"Congelados",
      href:"/Congelados"
    },
    {
      label:"Dulces",
      href:"/Dulces"
    },
    {
      label:"Mascotas",
      href:"/Mascotas"
    },
    {
      label:"Ver todo",
      href:"/catergories"
    },
  ]
  const subMenuItemStyle = { color: "#43ABAE" }

  return (
    <div>
      <Box className='at-bottom' sx={{
        backgroundColor: "#fff", width: "100%", display: { xs: "none", md: "flex" }, 
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);"
      }}
        justifyContent="flex-start" alignItems="center" >
        <Box sx={{ width: "1270px", padding: "8px 24px" }} m="auto" display="flex" justifyContent="flex-start" >
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={displayCategories}
            sx={{
              color: "#43ABAE",
              transition:"color 0.5s ease-in-out",
              '&:hover': {
                color: "#b6d33c",
              }
            }}
          >
            Todas las categorías  <span style={{ width: "15px",}} ></span>
            <svg id="Grupo_21" data-name="Grupo 21" width="13" height="8.378" viewBox="0 0 13 8.378">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectángulo_11" data-name="Rectángulo 11" width="13" height="8.378" fill="#b6d33c" />
                </clipPath>
              </defs>
              <g id="Grupo_20" data-name="Grupo 20" clipPath="url(#clip-path)">
                <path id="Trazado_48" data-name="Trazado 48" d="M6.519,6.031,8.592,3.61Q9.959,2.016,11.325.42A.925.925,0,0,1,12.18.053a.879.879,0,0,1,.749.578.88.88,0,0,1-.166.95c-.476.562-.957,1.119-1.435,1.678Q9.313,5.611,7.3,7.964a.911.911,0,0,1-.986.379,1.128,1.128,0,0,1-.52-.321Q3,4.784.228,1.527a.912.912,0,0,1,.1-1.3A.917.917,0,0,1,1.653.358Q4.007,3.1,6.358,5.851c.047.054.1.106.161.18" transform="translate(0 0)" fill="#b6d33c" />
              </g>
            </svg>

          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEli}
            open={open}
            onClose={hideCategories}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Link to="/Lacteos"  ><MenuItem onClick={hideCategories} sx={{ color: "#43ABAE", width: "200px", margin: "auto" }} >Lácteos</MenuItem></Link>
            
             {
              subMenuItems.map( (item) => (<Link to={item.href} key={item.label} >
                <MenuItem 
                  onClick={hideCategories}  
                  sx={subMenuItemStyle}
                > {item.label} </MenuItem>
              </Link>) )
             }
          </Menu>

          
          {
            navItems.map( (item => (<Link to={item.href} key={item.label} >
              <Typography variant='overline' sx={linkStyles} > {item.label} </Typography>
            </Link>)) )
          }

        </Box>
      </Box>
      
    </div>
  )
}

export default NavbarClient