import * as React from 'react';
import { Alert, Collapse, IconButton, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { useState } from 'react';

const NativePicker = () => {
    const [{ date }, dispatch] = useStateValue("10:00 a.m - 11:00 a.m");
    const [open, setOpen] = useState(true)
    const [value, setValue] = useState(date.time?date.time:"");
    const d = new Date()
    const tomorrow = new Date()
    tomorrow.setTime(d.getTime() + ((24 * 60 * 60 * 1000)))

    let dateLimit = new Date()
    dateLimit.setTime(d.getTime() + ((24 * 60 * 60 * 1000) * 7))
    const handleChange = (event) => {
        setValue(event.target.value);
        dispatch({
            type: actionTypes.SET_DATE,
            date: {
                time: event.target.value,
                dateDelivery: fecha
            }
        });
    };
    const [fecha, setFecha] = useState(date.dateDelivery?date.dateDelivery:"")
    console.log(fecha)
    let maximo = `${d.getHours() >= 12 ? tomorrow.getFullYear() : d.getFullYear()}-${d.getHours() >= 12 ? ''+tomorrow.getMonth() + 1 : ''+d.getMonth() + 1}-${d.getHours() >= 12 ? (tomorrow.getDate()<10?"0"+tomorrow.getDate():tomorrow.getDate()) : (d.getDate()<10?"0"+d.getDate():d.getDate())}`
    const mmin = `${d.getHours() >= 12 ? tomorrow.getFullYear() : d.getFullYear()}-${d.getHours() >= 12 ? ''+(tomorrow.getMonth() + 1) : ''+(d.getMonth() + 1)}-${d.getHours() >= 12 ? (tomorrow.getDate()<10?"0"+tomorrow.getDate():tomorrow.getDate()) : (d.getDate()<10?"0"+d.getDate():d.getDate())}`
    const mmax = `${dateLimit.getFullYear()}-${(dateLimit.getMonth()<10?"0":"")+(dateLimit.getMonth() + 1)}-${dateLimit.getDate()<10?("0"+dateLimit.getDate()):dateLimit.getDate()}`
    console.log(mmin + " && " +mmax)
    console.log("datelimit: "+dateLimit.getMonth())
    const handleChanePicker = (e) => {
        setFecha(e.target.value);
        console.log();
        dispatch({
            type: actionTypes.SET_DATE,
            date: {
                time: value,
                dateDelivery: e.target.value
            }
        });
    }

    return (
        <Stack noValidate spacing={3} component="form" >
            <Typography variant='h4' >Opciones de entrega</Typography>
            <TextField
                id="date"
                label="Dia de entrega"
                type="date"
                value={fecha}
                onChange={(e) => handleChanePicker(e)}
                // defaultValue="2022-10-22"
                sx={{ width: 300 }}
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{
                    min: `${d.getHours() >= 12 ? tomorrow.getFullYear() : d.getFullYear()}-${d.getHours() >= 12 ? (tomorrow.getMonth()<10?"0":"")+(tomorrow.getMonth() + 1) : (dateLimit.getMonth()<10?"0":"")+(d.getMonth() + 1)}-${d.getHours() >= 12 ? (tomorrow.getDate()<10?"0"+tomorrow.getDate():tomorrow.getDate()) : (d.getDate()<10?"0"+d.getDate():d.getDate())}`,
                    max: `${dateLimit.getFullYear()}-${(dateLimit.getMonth()<10?"0":"")+(dateLimit.getMonth() + 1)}-${dateLimit.getDate()<10?("0"+dateLimit.getDate()):dateLimit.getDate()}`
                }}
            />
            <Collapse in={open} >
                <Alert  
                    action={
                        <IconButton
                            onClick={()=>setOpen(false)}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{mb:2}}
                >
                    Nota: A partir de las 12:00 P.M. no se puede programar pedidos el dia actual, sino hasta el dia de mañana
                </Alert>
            </Collapse>
            <Typography variant='h6' >Recibelo: {fecha} </Typography>

            <FormControl>
                <RadioGroup value={value} name="radio-buttons-group" onChange={handleChange}>
                    <FormControlLabel value="09:00 a.m - 10:00 a.m" disabled={parseInt(fecha.substring(8, 10)) === d.getDate() ? (d.getHours() >= 9 ? true : false) : false} control={<Radio />} label="09:00 a.m - 10:00 a.m" />
                    <FormControlLabel value="10:00 a.m - 11:00 a.m" control={<Radio />} label="10:00 a.m - 11:00 a.m" />
                    <FormControlLabel value="11:00 a.m - 12:00 p.m" control={<Radio />} label="11:00 a.m - 12:00 p.m" />
                    <FormControlLabel value="12:00 p.m - 01:00 p.m" control={<Radio />} label="12:00 p.m - 01:00 p.m" />
                    <FormControlLabel value="01:00 p.m - 2:00 p.m" control={<Radio />} label=" 1:00 p.m -  2:00 p.m" />
                    <FormControlLabel value="02:00 p.m - 3:00 p.m" control={<Radio />} label=" 2:00 p.m -  3:00 p.m" />
                    <FormControlLabel value="03:00 p.m - 4:00 p.m" control={<Radio />} label=" 3:00 p.m -  4:00 p.m" />
                    <FormControlLabel value="05:00 p.m - 6:00 p.m" control={<Radio />} label=" 5:00 p.m -  6:00 p.m" />
                    <FormControlLabel value="07:00 p.m - 8:00 p.m" control={<Radio />} label=" 7:00 p.m -  8:00 p.m" />
                </RadioGroup>
            </FormControl>
        </Stack>
    )
}

export default NativePicker