import * as React from 'react';
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useState, useEffect } from 'react'
import { useStateValue } from '../../StateProvider';

const OrderItem = ({ itemData }) => {
    const [{user}, dispatch] = useStateValue();
    const [item, setItem] = useState(itemData)
    const [prod, setprod] = useState(itemData)
    useEffect(() => {

        console.log(itemData)
        console.log("iterar")

        if (itemData?.products) {
            Object.entries(itemData?.products).map((product) => (
                console.log(product)
            ))
        }

    }, [itemData])

    return (
        <Stack>
            <Box>
                <Typography color="primary" variant='h4' >{ user.displayName }</Typography>
                {/* <Typography variant='h4' >Pedido #{itemData.id}  </Typography> */}
                <Typography variant='body' >Emision: {(itemData.date.toDate().toString())} </Typography>
                <Typography>Entrega: {itemData?.time.dateDelivery},   hora: {itemData?.time.time}</Typography>
                {/* <Typography display="" >{itemData?.time.time}</Typography> */}
                <Typography> {itemData?.address?.street}, {itemData?.address?.exteriorNumber} </Typography>
                <Typography> CP {itemData?.address?.zipCode}</Typography>
                <Typography>{itemData?.address?.city}, {itemData?.address?.country}</Typography>
            </Box>
            <hr></hr>
            <Box  borderTop="1px dashed black" borderBottom="1px dashed black" paddingTop={1} paddingBottom={1} >
                <Typography variant="h4" color="primary" >Resumen del pedido</Typography>
                <Box display="flex" justifyContent="space-between"  >
                    <Typography>Metodo de pago:</Typography>
                    <Typography>Tarjeta</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between"  >
                    <Typography>Subtotal de productos:</Typography>
                    <Typography>${itemData?.basketTotal}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between"  >
                    <Typography>Costo de envio:</Typography>
                    <Typography>$ {itemData?.shippingPrice}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between"  >
                    <Typography>Descuentos:</Typography>
                    <Typography>$0.00</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between"  >
                    <Typography>Wallet:</Typography>
                    <Typography>$0.00</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between"  >
                    <Typography color="primary" >Total:</Typography>
                    <Typography color="primary" >$ {parseInt(itemData?.basketTotal) + parseInt(itemData?.shippingPrice)}</Typography>
                </Box>
            </Box>
            <Box>
                {/* <hr></hr> */}
                <Typography variant="h4" color="primary" > Ticket</Typography>
                <Box justifyContent="space-between" display="flex" borderBottom="1px dashed black" >
                    <Typography>ARTICULO</Typography>
                    <Typography>CANTIDAD</Typography>
                    <Typography>PRECIO</Typography>
                </Box>

                {
                    itemData?.products ? Object.entries(itemData?.products).map((product) => (
                        <Box display="flex" justifyContent="space-between" 
                        // boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px" 
                        // m={1} p={1}
                        >
                            {/* <img src={product[1].url} alt="imagen" loading='lazy' width="20%" height="auto" /> */}
                            <Box alignItems="flex-start" display="flex" flexDirection="column" >
                                {/* <Typography> {product[1].id}</Typography> */}
                                <Typography width="100px" variant='caption' > {product[1].nombre}</Typography>
                                {/* <Typography>$ {product[1].unit == "grs" ? (product[1].price * product[1].weigth) : (product[1].price * product[1].quantity)} </Typography> */}
                            </Box>
                            <Typography variant='caption' > {product[1].quantity} {product[1].unit}</Typography>
                            {/* <Box flex={1} borderTop="1px solid black"marginTop={2.5}  ></Box> */}
                            <Typography variant='caption' >$ {product[1].subtotal} </Typography>
                            {/* <Typography>$</Typography> */}
                        </Box>
                    )) : (<p></p>)
                }
                <Box justifyContent="space-between" display="flex" borderTop="1px dashed black" >
                    <Typography width="100px"></Typography>
                    <Typography variant='caption' >SUBTOTAL</Typography>
                    <Typography variant='caption' >${itemData?.basketTotal}</Typography>
                </Box>
                <Box justifyContent="space-between" display="flex" borderBottom="1px dashed black" >
                    <Typography width="100px" ></Typography>
                    <Typography variant='caption' >ENVIO</Typography>
                    <Typography variant='caption' >$ {itemData?.shippingPrice}</Typography>
                </Box>

                <Box justifyContent="space-between" display="flex" borderBottom="1px dashed black" >
                    <Typography width="100px"></Typography>
                    <Typography variant='caption' fontWeight="bold" >TOTAL</Typography>
                    <Typography variant='caption' fontWeight="bold" >$ {parseInt(itemData?.basketTotal) + parseInt(itemData?.shippingPrice)}</Typography>
                </Box>

            </Box>

        </Stack>
    )
}

export default OrderItem
