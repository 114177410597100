import React, { useEffect } from 'react';
import { onSnapshot, collection, getDoc, getDocs, doc, getFirestore } from "firebase/firestore";
import './App.css';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Products from './components/products';
import CheckoutPage from './components/checkoutPage';
import DetailsProduct from './components/detailsProduct';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from 'react-router-dom';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { actionTypes } from './reducer';
import { useStateValue } from './StateProvider';
import Checkout from './components/checkoutForm/Checkout';
import Profile from './components/user/profile';
import Admin from './components/admin/panel';
import Dashboard from './components/admin/dashboard';
import Category from './components/category';
import BottomNav from './components/bottomNav';
import Categories from './components/categories';
import Box from '@mui/material/Box';
import NavbarClient from './components/navbarClient';
import Empacador from './components/empacador/empacador';
import Repartidor from './components/repartidor/repartidor';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Mail from './Mail';
import CookiesPolicy from './components/info/cookiesPolicy';
import TermsAndConditions from './components/info/termsAndConditions';
import ReturnPolicy from './components/info/returnPolicy';
import Promotions from './components/info/Promotions';
import PrivacyPolicy from './components/info/privacyPolicy';
import CookiesModal from './components/cookiesModal';
import ModalFirstCoupon from './components/user/ModalFirstCoupon';
import { getUserName } from './firebase/functions';

const theme = createTheme({
  palette: {
    primary: {
      main: '#B6D33C',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ceb19b',
      contrastText: '#fff'
    },
    inactive: {
      main: '#F9F9F9',
      contrastText: '#ceb19b',
      '&:hover': {
        background: "#B6D33C",
      }
    },
    focus: {
      main: "#43ABAE",
      contrastText: "#fff",
    },
    warning:{
      main:'#FF6700',
    },
  },
  // shape:{
  //   borderRadius:10
  // }
});

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

function App() {
  const [{ user, product, basket, openModal }, dispatch] = useStateValue();
  const [canView, setCanView] = React.useState(false)
  const [isEmpacador, setIsEmpacador] = React.useState(false)
  const [isRepartidor, setIsRepartidor] = React.useState(false)
  const router = useNavigate();
  const db = getFirestore();
  const pathname = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const auth = getAuth();
    var logeado = false;
    var uid = "";
    onAuthStateChanged(auth, (user) => {
      if (user) {
        uid = user.uid;
        user.address = null;
        logeado = true;
        if(user.providerData[0].providerId==="apple.com"){
          // console.log("provider data, ",user.providerData[0])
          user.photoURL="/usr-icon.png"
          let name = ''
          getUserName(user.uid).then((res)=>{
            name=res;
            console.log("name ",res);
            user.displayName=name;})
          .catch((e)=>console.log(e));
          
          console.log("user", user)
        }
        const obtenerDireccion = async () => {
          const docRefAddress = doc(db, "address", uid);
          const docSnapAddress = await getDoc(docRefAddress);
          if (docSnapAddress.data() !== undefined) {
            direccion = docSnapAddress.data().main;
            user.address = direccion;
          }
          dispatch({
            type: actionTypes.SET_USER,
            user: user,
          });
        }
        obtenerDireccion();
        protectedPage(user)
      } else {
        uid = "";
        logeado = false;
        dispatch({
          type: actionTypes.SET_USER,
          user: null,
        });
      }
      obtenerDatos();
    });
    var direccion = {};
    const obtenerDatos = async () => {
      dispatch({
        type: actionTypes.EMPTY_BASKET,
        basket: [],
      });
      dispatch({
        type: actionTypes.EMPTY_PRODUCT,
        product: [],
      });
      const db = getFirestore();
      if (logeado) {
        const docRef = doc(db, "cart", uid);
        const docSnap = await getDoc(docRef);
        var carrito = docSnap.data();
      }
      let collectionCategories = collection(db, "taxonomies");
      onSnapshot(collectionCategories, (querySnapshot) => {
        querySnapshot.forEach((document) => {
          var dataCategories = document.data();
          dispatch({
            type: actionTypes.ADD_CATEGORY,
            item: {
              label: dataCategories.name,
              value: dataCategories.name,
              image: dataCategories.images
            }
          });
        });
      });
      let collectionRef = collection(db, "product");
      onSnapshot(collectionRef, (querySnapshot) => {
        querySnapshot.forEach((document) => {
          onSnapshot(collection(db, 'product', document.id, 'inventario'), (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const info = doc.data();
              dispatch({
                type: actionTypes.ADD_PRODUCT,
                item: {
                  id: info.id,
                  name: info.name,
                  productType: info.productType,
                  image: info.image,
                  price: info.price,
                  description: info.description,
                  alarm: info.alarm,
                  stock: info.stock,
                  stockKg: info.stockKg,
                  link: info.link,
                  piecesPerKg: info.piecesPerKg,
                  primaryUnit: info.primaryUnit,
                  secondaryUnit: info.secondaryUnit,
                  category: document.id,
                  subcategory: info.subcategory,
                  quantity: 0,
                  unit: "Pza",
                  docId: doc.id,
                  weight: info.weight,
                  tags: info.tags,
                  defaultUnit: info.primaryUnit,
                  organico: info.organico,
                  createdAt: info.createdAt,
                }
              });
              if (logeado && carrito != undefined) {
                Object.keys(carrito['data']).forEach(key => {
                  if (key == doc.id) {
                    const getQuantity = ()=>{
                      if(carrito['data'][key].unit=="grs"){
                        console.log("carrito y nuevos precios")
                        console.log(carrito['data'][key])
                        // console.log(carrito['data'][key].weight)
                        // console.log(info.quantity )
                        console.log(info)
                      }
                    }
                    getQuantity()
                    // console.log("hellow my frends")
                    // console.log(carrito['data'][key].unit)
                    dispatch({
                      type: actionTypes.ADD_TO_BASKET,
                      item: {
                        id: info.id,
                        name: info.name,
                        productType: info.productType,
                        image: info.image,
                        price: info.price,
                        description: info.description,
                        alarm: info.alarm,
                        stock: info.stock,
                        stockKg: info.stockKg,
                        link: info.link,
                        piecesPerKg: info.piecesPerKg,
                        primaryUnit: info.primaryUnit,
                        secondaryUnit: info.secondaryUnit,
                        category: document.id,
                        subcategory: info.subcategory,
                        quantity: carrito['data'][key].quantity,
                        unit: carrito['data'][key].unit,
                        docId: key,
                        weight: info.weight,
                        defaultUnit: info.primaryUnit
                      }
                    });
                    dispatch({
                      type: actionTypes.QUA_PRODUCT,
                      item: {
                        id: info.id,
                        quantity: carrito['data'][key].quantity,
                        unit: carrito['data'][key].unit
                      }
                    });
                  }
                });
              }
              if (localStorage.getItem("cart") !== null) {
                // console.log("hay items en local storage")
              }
            });
          });
        });
      }, (error)=> {console.log("snapchot error ", error)});
    }
    
  }, []);

useEffect(() => {
  if( canView){
    router('/admin')
  }
  if(isEmpacador){
    router('/empacador')
  }
  if(isRepartidor){
    router('/repartidor')
  }
}, [canView, isEmpacador, isRepartidor])


  
  useEffect(() => {
    if(product.length>0) {
      if (localStorage.getItem("cart") !== null) {
        // console.log(product)
        if (basket.length == 0) {
          const localCart = JSON.parse(localStorage.getItem("cart"))
          console.log(localCart)
          localCart.map(product => {
            console.log(product)
            dispatch({
              type: actionTypes.ADD_TO_BASKET,
              item: {
                ...product
              },
            })
            dispatch({
              type: actionTypes.QUA_PRODUCT,
              item: {
                id: product.id,
                quantity: product. quantity,
                unit: product.unit
              } 
            })
          })
          
        }
      }

    }

  }, [product.length])


  const protectedPage = async (usuario) => {
    const docRef = doc(db, 'users', usuario.uid)
    try {
      const permisos = await getDoc(docRef)
      if (permisos.exists()) {

        if (permisos.data().role === "admin") {
          setCanView(true)
          // router('/admin')
        }
        if (permisos.data().role === "empacador") {
          setIsEmpacador(true)
          // router('/empacador')
        }
        if (permisos.data().role === "repartidor") {
          setIsRepartidor(true)
          // router('/repartidor')
        }
      }
    } catch (error) {
      console.log("error usuario: " + error)
    }
  }
  return (
    <Wrapper>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Navbar userType={
            canView? "admin"
            : isEmpacador? "empacador"
            : isRepartidor? "repartidor" 
            : "cliente"
          } />
          {
            isEmpacador || isRepartidor 
            ? <Box ></Box> 
            : (<div>
              <Box sx={{ height: { xs: "90px", sm: "120px", md: "160px" }, background: "#fff" }} ></Box>
            </div>)
          }
          <Routes>
            <Route path='/' element={ <Products />} />
            
            {
              !user && <>
                <Route path='/signin' element={<SignIn />} />
                <Route path='/signup' element={<SignUp />} />
              </> 
            }
            <Route path='/checkout' element={<CheckoutPage />} />
            {
              <Route path='/carrito' element={user ? <Checkout /> : <SignIn />} />
            }
            <Route path='/perfil' element={user ? <Profile /> : <SignIn />} />
            <Route path='/categories' element={<Categories />} />
            {/* <Route path='/mail' element={<Mail />} /> */}
            <Route path="/:category" element={<Category />} />
            <Route path="/:category/:product" element={<DetailsProduct />} />
            {
              canView &&  <>
                <Route path='/home' element={<Products />} />
                <Route path='/admin' element={<Dashboard />} />
              </> 
            }
            {
              isEmpacador && <Route path='/empacador' element={<Empacador />} /> 
            }
            {
              isRepartidor && <Route path='/repartidor' element={<Repartidor />} /> 
            }
            <Route path='/politica-de-cookies' element={<CookiesPolicy />} />
            <Route path='/terms' element={<TermsAndConditions />} />
            <Route path='/return-policy' element={<ReturnPolicy />} />
            <Route path='/promociones' element={<Promotions />} />
            <Route path='/politicas-de-privacidad' element={<PrivacyPolicy />} />
          </Routes>
          { openModal && <ModalFirstCoupon /> }
          <CookiesModal />
          <Footer />
          <BottomNav />
        </div>
      </ThemeProvider>
    </Wrapper>
  );
}
export default App;
