// import Avatar from '@mui/material/Avatar';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Grid from '@mui/material/Grid';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import cereza from "../assets/cereza.png";
// import { height, maxWidth } from '@mui/system';
import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RoutLink, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";
import brackgroundImg from "../assets/allfresco-login.jpg";
import { getDoc, doc, getFirestore, setDoc, addDoc, collection } from 'firebase/firestore';
import ModalFirstCoupon from './user/ModalFirstCoupon';
import { useStateValue } from '../StateProvider';
import { actionTypes } from '../reducer';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        AllFresco
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [coupon, setCoupon] = useState(null)
  const history = useNavigate();
  const provider = new GoogleAuthProvider();
  const db = getFirestore();
  const [{ }, dispatch] = useStateValue();
  const signin = (e) => {
    e.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        history("/");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }
  const SignInWithGoogle = (e) => {
    e.preventDefault();
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        setUser(user);
        console.log("credential")
        console.log(credential)
        console.log("result")
        console.log(result)
        // ...

      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }
  const signInWithFacebook = () => {
    const auth = getAuth();
    // const provider = new FacebookAuthProviver();
    signInWithPopup(auth, provider)
      .then((re) => {
        console.log(re);
      })
      .catch((err) => {
        console.log(err.message)
      })
  }
  // async function  signInWithApple() {
  //   const provider = new FirebaseFirestore.auth.OAuthProvider("apple.com")
  //   const result = await this.auth.signInWithPopup(provider)
  //   console.log(result.user)
  // }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const setUser = async (userRef) => {
    const docRef = doc(db, 'users', userRef.uid)
    try {
      const userResult = await getDoc(docRef)
      if (userResult.exists()) {
        console.log("user registered")
        const result = await getDoc(doc(db, 'coupons', '1COMPRA'))
        if (result.exists()) {
          // set the coumpon
          setCoupon(result.data())
          dispatch({
            type: actionTypes.ADD_COUPON,
            coupon: result.data()
          })
          dispatch({
            type: actionTypes.OPEN_MODAL,
            openModal: true
          })
          // open dialog
        }
        history("/");
      }
      else {
        console.log("User not registered")
        const collectionRef = collection(db, 'mail');
        const newUser = {
          role: "cliente",
          email: userRef.email,
          userName: userRef.displayName,
          createdAt: userRef.metadata.createdAt
        }
        const emailContent = {
          to: userRef.email,
          message: {
            subject: "Inicio de sesion en allfresco",
            text: "¡Hola! " + userRef.displayName + " , el inicio de sesion fue exitoso",
            html: `
            <body>
              <table style="width: 600px;height: 368px;font-family: 'Quicksand';background-image: url(https://firebasestorage.googleapis.com/v0/b/allfresco-52e3c.appspot.com/o/categories%2Fbg-mail.webp?alt=media&token=700902bf-b48a-4357-b067-a0dfb30c3a3e);background-position: center;background-size: cover;" >
              <tbody>
                <tr>
                  <th>
                    <img src="https://develop.allfresco.mx/static/media/cereza.136f873677aece390bd6.png" alt="allfresco logo" width="35px" height="35px"  />
                    <h1 style="color: #43ABAE;margin: 0px;" >allfresco</h1>
                  </th>
                </tr>
                <tr>
                  <th>
                    <h2 style="font-size:16px;color: #B6D33C; margin: 0px;" >¡Hola!</h2>
              <h2 style="font-size:16px;color: #B6D33C; margin: 0px;">@${userRef.displayName}</h2>
                  </th>
                </tr>
                <tr>
                  <th>
                    <p style="color: #707070;font-family: 'Open sans';margin-bottom: 0px;font-weight: 400;" >¡Felicidades!</p>
              <p style="color: #707070;font-family: 'Open sans'; margin: 0px;font-weight: 400;" >El inicio de sesion fue exitoso</p>
                  </th>
                </tr>
                <tr>
                  <th>
                    <button style="background-color: #B6D33C;border: none; border-radius: 5px;padding: 5px;margin-top: 15px;margin-bottom: 15px;" > <a style="text-decoration: none; color: white;" href="https://www.allfresco.mx" target="_blank">Comenzar con tu compra</a> </button>
                  </th>
                </tr>
                <tr>
                  <th>
                    <div >
                      <span style="text-align: center;font-size: 12px;color: #43ABAE;" >Siguenos</span>
                      <div  >
                        <svg id="Grupo_1805" data-name="Grupo 1805" xmlns="http://www.w3.org/2000/svg" width="13.246" height="13.246" viewBox="0 0 13.246 13.246">
                          <path id="Trazado_1970" data-name="Trazado 1970" d="M6.623,0a6.623,6.623,0,1,0,6.623,6.623A6.623,6.623,0,0,0,6.623,0M9.23,3.344H8.454a.889.889,0,0,0-1,.961V5.459H9.158L8.885,7.237H7.452v4.3H5.529v-4.3H3.967V5.459H5.529V4.1A2.171,2.171,0,0,1,7.853,1.71a9.456,9.456,0,0,1,1.377.12Z" fill="#3eb1b4"/>
                        </svg>
                        <svg id="Grupo_1804" data-name="Grupo 1804" xmlns="http://www.w3.org/2000/svg" width="13.246" height="13.246" viewBox="0 0 13.246 13.246">
                          <path id="Trazado_1967" data-name="Trazado 1967" d="M74.787,13.415a1.266,1.266,0,1,0,1.266,1.266,1.268,1.268,0,0,0-1.266-1.266" transform="translate(-68.132 -8.059)" fill="#3eb1b4"/>
                          <path id="Trazado_1968" data-name="Trazado 1968" d="M73.309,8.425H70.1a1.658,1.658,0,0,0-1.656,1.656v3.206A1.658,1.658,0,0,0,70.1,14.943h3.205a1.658,1.658,0,0,0,1.657-1.656V10.081a1.658,1.658,0,0,0-1.657-1.656m-1.57,5.236a1.977,1.977,0,1,1,1.977-1.977,1.979,1.979,0,0,1-1.977,1.977m2.093-3.617a.471.471,0,1,1,.471-.471.471.471,0,0,1-.471.471" transform="translate(-65.084 -5.061)" fill="#43abae"/>
                          <path id="Trazado_1969" data-name="Trazado 1969" d="M66.646,0a6.623,6.623,0,1,0,6.623,6.623A6.623,6.623,0,0,0,66.646,0M70.66,8.226a2.414,2.414,0,0,1-2.412,2.412H65.043a2.414,2.414,0,0,1-2.412-2.412V5.02a2.415,2.415,0,0,1,2.412-2.412h3.205A2.415,2.415,0,0,1,70.66,5.02Z" transform="translate(-60.023)" fill="#3eb1b4"/>
                        </svg>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <ul style="list-style-type: none; display: flex; color: #43ABAE;font-weight: 500;font-size: 16px;" >
                      <li >Acerca de allfresco</li>
                      <li style="margin-left: 10px;">Ayuda</li>
                      <li style="margin-left: 10px;margin-right: 10px;" >Privacidad</li>
                      <li>Terminos y condiciones</li>
                    </ul>
                  </th>
                </tr>
              </tbody>
            </table>
            </body>
            `
          }
        }
        // console.log({ userRef })
        await setDoc(docRef, newUser)
        await addDoc(collectionRef, emailContent);
        const result = await getDoc(doc(db, 'coupons', '1COMPRA'))
        if (result.exists()) {
          // set the coumpon
          setCoupon(result.data())
          dispatch({
            type: actionTypes.ADD_COUPON,
            couponModal: result.data()
          })
          dispatch({
            type: actionTypes.OPEN_MODAL,
            openModal: true
          })
          // open dialog
        }
      }
    } catch (error) {
      alert(error)
    }
  }
  const handleAppleSignIn = () => {
    const auth = getAuth();
    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email')
    appleProvider.addScope('name')
    appleProvider.setCustomParameters({
      locale: 'mx'
    })

    signInWithPopup(auth, appleProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        // IdP data available using getAdditionalUserInfo(result)
        // ...
        console.log('user')
        console.log(user)
        console.log('result')
        console.log(result)
        setUser(user)
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
        console.log('error => ',error)
        alert('No fue posible iniciar sesión')
      });

  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md"  >
        <CssBaseline />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundImage: `url(${brackgroundImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            maxWidth: "1367px",
            top: "120px",
            position: "relative",
            maxWidth: "1768px",
            height: "60vh"
          }}

        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar> */}

          <Typography className='logo' variant="h2">
            allfresco
          </Typography>
          <Typography sx={{ color: "#b6d33c" }} component="h1" variant="h4">
            Iniciar sesión
          </Typography>
          {/* <img  
            src={cereza}
            width="120px"
            sx={{margin:"auto"}}
            alt="logo allfesco"
            loading='lazy'
          /> */}

          <svg id="Grupo_1925" data-name="Grupo 1925" width="79.396" height="129.857" viewBox="0 0 79.396 129.857">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectángulo_1121" data-name="Rectángulo 1121" width="79.396" height="129.857" fill="#43abae" />
              </clipPath>
            </defs>
            <g id="Grupo_1462" data-name="Grupo 1462" transform="translate(0 0)" clipPath="url(#clip-path)">
              <path id="Trazado_1891" data-name="Trazado 1891" d="M29.09,50.978c-.656-3.007-.229-.717-.77-3.665q-.2-1.1-.371-2.2c-.059-.379-.115-.76-.169-1.14l-.036-.257c-.007-.049-.011-.1-.02-.147,0,.021.038.287.012.085s-.052-.393-.077-.59q-.284-2.243-.46-4.5c-.232-2.946-.338-5.9-.341-8.855q-.007-4.173.245-8.343.217-3.679.609-7.345.169-1.593.371-3.18c.031-.249.064-.5.1-.746.025-.19-.044.332-.014.105.008-.055.015-.109.023-.164l.05-.367q.092-.679.192-1.357.3-2.072.667-4.138c.114-.639.241-1.276.361-1.914.006-.027.009-.056.018-.082.615-2.13-1.536-2.895-2.21-1.146l-.354,1.843q-.339,1.838-.62,3.686Q25.84,9.5,25.5,12.45q-.414,3.548-.668,7.112-.29,4.09-.351,8.191c-.04,2.947.015,5.9.192,8.839.186,3.112.51,6.215.993,9.294.468,2.987.526,2.567,1.089,5.524Z" transform="translate(4.524 0.013)" fill="#43abae" />
              <path id="Trazado_1892" data-name="Trazado 1892" d="M27.338,1.835c.686,1.14,1.437,2.245,2.205,3.332a50.411,50.411,0,0,0,5.4,6.568,20.867,20.867,0,0,0,3.69,3.014,1.2,1.2,0,0,0,1.617-.489,1.226,1.226,0,0,0-.491-1.619c-.123-.077-.246-.156-.369-.238-.066-.044-.132-.089-.2-.134l-.073-.052c-.191-.133.239.175.052.037-.258-.192-.514-.386-.763-.59-.531-.434-1.039-.9-1.531-1.374s-.985-1-1.455-1.514q-.366-.4-.722-.818L34.37,7.57c-.184-.218.13.158,0,0l-.192-.235a62.665,62.665,0,0,1-4.087-5.618c-.251-.385-.5-.773-.737-1.167A1.206,1.206,0,0,0,27.705.186a1.219,1.219,0,0,0-.367,1.649" transform="translate(5.022 0)" fill="#43abae" />
              <path id="Trazado_1893" data-name="Trazado 1893" d="M36.587,10.08S30.553,21.354,35,30.508C40.242,41.32,50.24,45.031,53.486,48.949c0,0,9.637-34.265-16.9-38.869" transform="translate(6.169 1.864)" fill="#43abae" />
              <path id="Trazado_1894" data-name="Trazado 1894" d="M28.5,43.009q-.983.156-1.948.36Z" transform="translate(4.909 7.953)" fill="#43abae" />
              <path id="Trazado_1895" data-name="Trazado 1895" d="M39.7,42.586a39.931,39.931,0,0,0-6.1.467c.871,2.875.485,2.2,1.428,5.048q.347,1.045.736,2.074.19.5.391,1c.037.091.075.181.111.274l.017.038c.069.165.139.328.209.492a42.834,42.834,0,0,0,1.9,3.86,36.676,36.676,0,0,0,2.287,3.6q.162.224.327.444l.024.031.126.165q.327.421.666.832a18.644,18.644,0,0,0,1.348,1.318c3.849-1.121,14.438-3.8,20.835,8.966,8.65,17.256-7.939,36.54-28.3,33.49C11.19,101.014,8.723,77.913,18.111,65.8c7.519-9.7,16.687-5.65,21.313-3.878a40.159,40.159,0,0,1-4.037-6.665,98.462,98.462,0,0,1-4.12-11.764,39.694,39.694,0,1,0,8.431-.906" transform="translate(0 7.875)" fill="#43abae" />
            </g>
          </svg>

          <Button
            onClick={SignInWithGoogle}
            type="submit"
            variant="contained"
            size='large'
            sx={{
              m: 2, p: 2, background: 'red', color: 'white', fontWeight: "bold", minWidth: "400px", '&:hover': {
                backgroundColor: 'white',
                color: "red",
                opacity: [0.9, 0.8, 0.7],
              },
              borderRadius: "10px",
              textTransform: "none"
            }}
          >
            Continuar con Google
          </Button>

          <Button
            // onClick
            // type="submit"
            variant="contained"
            size='large'
            p={1}
            sx={{
              m: 2, p: 2, background: '#4267b2', color: 'white', fontWeight: "bold", minWidth: "400px", '&:hover': {
                backgroundColor: 'white',
                color: "#4267b2",
                opacity: [0.9, 0.8, 0.7],
              },
              borderRadius: "10px",
              textTransform: "none"
            }}
          >
            Continuar con Facebook
          </Button>

          <Button
            onClick={handleAppleSignIn}
            // type="submit"
            variant="contained"
            size='large'
            sx={{
              m: 2, p: 2, background: 'black', color: 'white', fontWeight: "bold", minWidth: "400px", '&:hover': {
                backgroundColor: 'white',
                color: "black",
                opacity: [0.9, 0.8, 0.7],
              },
              borderRadius: "10px",
              textTransform: "none"
            }}
          >
            Continuar con Apple
          </Button>


        </Box>
        <Box
          sx={{
            paddingTop: "200px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            {/* <TextField
                value={email}
                onChange={
                    e=>setEmail(e.target.value)
                }
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
                value={password}
                onChange={
                    e=>setPassword(e.target.value)
                }
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
                onClick={signin}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button> */}


            {/* <Button
              onClick={SignInWithGoogle}
              type="submit"
              fullWidth
              variant="contained"
              size='large'
              sx={{ mt: 1, mb: 1, background: 'red', color: 'white' }}
            >
              Continuar con Google
            </Button>

            <Button
              onClick
              type="submit"
              fullWidth
              variant="contained"
              size='large'
              p={1}
              sx={{ mt: 1, mb: 1, background: '#4267b2', color: 'white', fontWeight:"bold" }}
            >
              Continuar con Facebook
            </Button>

            <Button
              onClick
              type="submit"
              fullWidth
              variant="contained"
              size='large'
              sx={{ mt: 1, mb: 1, background: 'black', color: 'white' }}
            >
              Continuar con Apple
            </Button> */}

            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <RoutLink to="/signup">
                  {"Don't have an account? Sign Up"}
                </RoutLink>
              </Grid>
            </Grid> */}

          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}


