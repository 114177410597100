import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { doc, collection, getDocs, getFirestore, getDoc } from "firebase/firestore";
import OrderItem from '../user/orderItem';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ExportCSV from './csv/ExportCSV';


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('#445', "10/06/2022", "$550.23", "Pagado", "Entregado"),
  createData('#446', "10/06/2022", "$550.23", "Pagado", "Entregado"),
];

export default function OrdersList() {
  const db = getFirestore();
  const [orders, setOrders] = React.useState([]);
  const [ordersCopy, setOrdersCopy] = React.useState(orders)
  const [orderData, setOrderData] = React.useState({});
  const dateStartRef = React.useRef();
  const dateEndRef = React.useRef();
  const [filteredOrders, setFilteredOrders] = React.useState([])
  
  
  const getUserData = async (id) => {
    const userData = await getDoc(doc(db, 'users', id))
    return userData.data()
  }
  const getOrders = async () => {
    var datos = []
    const querySnapshot = await getDocs(collection(db, "orders"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.data().orders);
      // console.log(doc.id, " => ", doc.data());
      // datos = [...datos, doc.data().orders]
      Object.keys(doc.data().orders).forEach(async (key) => {
        // console.log(key)
        // console.log(item)
        var item = doc.data().orders[key]
        item.id = key
        datos = [...datos, item]
      })

      // console.log(doc.data().orders[llave])
      // console.log(new Date(doc.data().fecha.seconds * 1000))
    });
    datos.sort( (a,b) => new Date(b.time.dateDelivery) - new Date(a.time.dateDelivery))
    setOrders([...datos])
    // console.log(orders)
  }
  React.useEffect(() => {
    getOrders()
    console.log("orders")
    console.log(orders)
  }, [])

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paper':{
      borderRadius:"10px"
    }
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const [open, setOpen] = React.useState(false);
  const [orderSearch, setOrderSearch] = React.useState('')
  
  const handleClickOpen = (item) => {
    setOrderData(item)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSearchOrder = (searched) => {
    setOrderSearch(searched)
    let orderResult = orders.filter((order) => {
      // return orders.id.include(searched)
      return order.id.toLowerCase().includes(searched.toLowerCase());
    })
    console.log(orderResult)
    if (searched === "")
      setOrders(orderResult)
    else
      setOrdersCopy(orderResult)
  }

  const sortByTab = (value, direction) => {
    // console.log(direction)
    let newArray = [...orders]
    switch (value){
      case "username":
        newArray = newArray.sort( (a,b) => direction?(a.username<b.username?1:a.username>b.username?-1:0):((a.username>b.username?1:a.username<b.username?-1:0)) )
        setOrders(newArray)
        return;
      case "date":
        newArray = newArray.sort( (a,b) => direction?(new Date(a.date.seconds*1000)-new Date(b.date.seconds*1000)):(new Date(b.date.seconds*1000)-new Date(a.date.seconds*1000)) )
        setOrders(newArray)
        return;
      case "time.date":
        return;
      case "total":
        newArray = newArray.sort( (a,b) => direction?(a.basketTotal-b.basketTotal):(b.basketTotal-a.basketTotal) )
        setOrders(newArray)
        return;
      case "subtotal":
        return;
      case "statusOrder":
        newArray = newArray.sort( (a,b) => direction?(a.statusOrder<b.statusOrder?1:a.statusOrder>b.statusOrder?-1:0):(a.statusOrder>b.statusOrder?1:a.statusOrder<b.statusOrder?-1:0)  )
        setOrders(newArray)
        return;
      default:
        alert("No se ha podido ordenar")
        return;
    }
    return setOrders(newArray)
  }
  const filterOrders = () => {
    let newArray = orders.filter( o => {
      const currentDate = new Date(o.date.seconds*1000)
      if( currentDate>=new Date(dateStartRef.current.value) && currentDate<=new Date(dateEndRef.current.value))
        return o;
    })
    setFilteredOrders(newArray)
  }

  return (
    <>
    <Grid container spacing={1} >
      <Grid item xs={12} sm={4} >
      <TextField
        value={orderSearch}
        fullWidth
        onChange={(e) => handleSearchOrder(e.target.value)}
        variant="filled"
        sx={{ borderRadius: "10px", border: "none", backgroundColor:"#f9f9f9", '& .MuiInputBase-input':{paddingTop:"8px"} }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <span className='icon-Busqueda-allfresco' ></span>
            </InputAdornment>
          ),
        }}
      />
      </Grid>
      <Grid item xs={12} sm={4} justifyContent={'space-between'} alignItems={'center'} display={'flex'} >
        <span>del</span>
        <input ref={dateStartRef} type='date' style={{borderRadius:"10px", border:"1px solid #707070", height:"36px",paddingLeft:"15px", paddingRight:"15px", fontFamily:"Raleway"}} />
        <span>al</span>
        <input ref={dateEndRef} type='date' style={{borderRadius:"10px", border:"1px solid #707070", height:"36px", paddingLeft:"15px", paddingRight:"15px", fontFamily:"Raleway"}} />
      </Grid>
      <Grid item xs={12} sm={4} justifyContent={'space-around'} display={'flex'} >
        <Button variant='contained' onClick={filterOrders} >Filtrar</Button>
        <Button variant='outlined' onClick={()=>setFilteredOrders([])} >Reset</Button>
        <ExportCSV dataR={{sells:{data:filteredOrders.length>0?filteredOrders:orders}}} />
      </Grid>
    </Grid>
      
      <Typography gutterBottom > Se muestran {orderSearch === "" ? orders.length : ordersCopy.length} resultados </Typography>
      <TableContainer sx={{marginTop:"20px"}} >
        <Table fullWidth sx={{ maxWidth: 'xl', }} aria-label="simple table" >
          <TableHead>
            <TableRow>
              <TableCell ><StyledButton dontSort sortByTab={sortByTab} property="id" >No. de pedido</StyledButton></TableCell>
              <TableCell ><StyledButton sortByTab={sortByTab} property="username" >Usuario</StyledButton> </TableCell>
              <TableCell size='small' ><StyledButton dontSort sortByTab={sortByTab} property="time.date" >CP</StyledButton></TableCell>
              <TableCell size='medium' ><StyledButton dontSort sortByTab={sortByTab} property="time.date" >Entrega</StyledButton></TableCell>
              <TableCell ><StyledButton sortByTab={sortByTab} property="date" >Fecha</StyledButton> </TableCell>
              <TableCell ><StyledButton sortByTab={sortByTab} property="total" >Total</StyledButton> </TableCell>
              <TableCell size='small' ><StyledButton dontSort sortByTab={sortByTab} property="pay" >Pago</StyledButton> </TableCell>
              <TableCell ><StyledButton sortByTab={sortByTab} property="statusOrder" >Estado del pedido</StyledButton> </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(orderSearch !== "" 
            ? ordersCopy 
            : filteredOrders.length>0
            ? filteredOrders
            :orders ).map((item) => (
              <TableRow
                key={item.id}
                sx={{'&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Button onClick={() => handleClickOpen(item)} >{item.id}</Button>
                </TableCell>
                <TableCell sx={{color:"#707070",fontFamily:"Quicksand" ,fontWeight:"500"}} > {item.username} </TableCell>
                <TableCell size='small' sx={{color:"#707070",fontFamily:"Quicksand" ,fontWeight:"500"}} >{item.address.zipCode}</TableCell>
                <TableCell size='medium' sx={{color:"#707070",fontFamily:"Quicksand" ,fontWeight:"500"}} >{item.time.dateDelivery}:{item.time.time}</TableCell>
                <TableCell sx={{color:"#707070",fontFamily:"Quicksand" ,fontWeight:"500"}} >{(new Date(item.date.seconds * 1000)).toLocaleString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
                <TableCell sx={{color:"#707070",fontFamily:"Quicksand" ,fontWeight:"500"}} >${item.basketTotal}</TableCell>
                <TableCell size='small' sx={{color:"#707070",fontFamily:"Quicksand" ,fontWeight:"500"}} >{item.status}</TableCell>
                <TableCell sx={{color:"#707070",fontFamily:"Quicksand" ,fontWeight:"500"}} >{item.statusOrder}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Pedido # {orderData.id}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <OrderItem itemData={orderData} />
            <Typography gutterBottom>
              Gracias por comprar en allfresco.mx
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </BootstrapDialog>

      </TableContainer>
    </>
  );
}


const ArrowIcon  = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.096" height="14.106" viewBox="0 0 12.096 14.106">
    <path id="Trazado_2015" data-name="Trazado 2015" d="M6.685,32.32a1.009,1.009,0,0,0-1.426,0L.22,37.358a1.009,1.009,0,0,0,1.426,1.426l3.319-3.322v9.661a1.008,1.008,0,0,0,2.015,0V35.463L10.3,38.782a1.009,1.009,0,0,0,1.426-1.426L6.688,32.317Z" transform="translate(0.075 -32.025)" fill="#9f9f9f"/>
  </svg>
)
const StyledButton = (props) => {
  const [isAsc, setIsAsc] = React.useState(false)
  return (
    <Button sx={{
      borderRadius: "10px",
      fontFamily:"Quicksand",
      color:"#707070",
      fontWeight:"900",
      textTransform:'none'
    }}
      onClick={()=>{
        if(props.dontSort) return;
        setIsAsc(!isAsc);props.sortByTab(props.property,isAsc);}}
    >
      {!props.dontSort && <div style={{marginRight:"5px", transform:`rotate(${isAsc?"0":"180"}deg)`}} ><ArrowIcon/></div>}  
      {props.children}
    </Button>
  )
}