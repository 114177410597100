import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useRef } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, IconButton, Typography } from '@mui/material';
import logo from '../../assets/AllFresco.png';
import { styled } from '@mui/material/styles';
import './styles.css';
import { getFirestore, doc, setDoc, getDoc, getDocs, collection } from "firebase/firestore";
import { useStateValue } from '../../StateProvider';
// import { uuid } from 'uuidv4';

const Img = styled('div')(({ theme }) => ({
  img: {
    width: '100px',
  },
}));

export default function DirectionForm() {
  const db = getFirestore();
  const [{ user }, dispatch] = useStateValue();
  const [open, setOpen] = React.useState(false);

  const street = useRef('');
  const exteriorNumber = useRef('');
  const interiorNumber = useRef('');
  const suburb = useRef('');
  const city = useRef('');
  const state = useRef('');
  const zipCode = useRef('');
  const country = useRef('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addAddress = async () => {
    try {
      // console.log( await isCpValid())
      if (await isCpValid()) {
        const idv4 = uuidv4()
        var address= {
          [idv4] : {
              street: street.current.value,
              exteriorNumber: exteriorNumber.current.value,
              interiorNumber: interiorNumber.current.value,
              suburb: suburb.current.value,
              city: city.current.value,
              state: state.current.value,
              zipCode: zipCode.current.value,
              country: country.current.value
            }
        }
        var addressMain= {
          main : {
              street: street.current.value,
              exteriorNumber: exteriorNumber.current.value,
              interiorNumber: interiorNumber.current.value,
              suburb: suburb.current.value,
              city: city.current.value,
              state: state.current.value,
              zipCode: zipCode.current.value,
              country: country.current.value
            }
        }
        var dirs = await getDoc(doc(db, 'address', user.uid))
        console.log(dirs.data())
        if (JSON.stringify(dirs.data()) !== '{}') {
          // dirs= dirs.data()
          // dirs.push(data)
          console.log(dirs)
          await setDoc(doc(db, "address", user.uid), address, {merge: true}
          );
        } else {
          await setDoc(doc(db, "address", user.uid), addressMain, {merge: true})
          // console.log()
          // data.main = true
          // await setDoc(doc(db, "address", user.uid), {
          //   main: data
          // }
          // )
          // alert("El codigo postal no es valido")
        }
        handleClose();
      } else {
        alert("Actualmente no contamos con cobertura en su zona, por favor intente mas tarde")
      }

      // window.location.reload()
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  const isCpValid = async () => {
    const datos = await getDocs(collection(db, 'cp'))
    let valid = false
    datos.forEach((doc) => doc.data().cp === zipCode.current.value ? (valid = true) : null)
    // datos.forEach((doc) => console.log(doc.data().cp+" => "+zipCode.current.value))
    // console.log(valid)
    return valid
  }
  return (
    <div>
      <IconButton sx={{ color: '#B6D33C' }} onClick={handleClickOpen}>
        <AddCircleIcon /> Añadir dirección
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className="form-direction">
          <Img>
            <img src={logo} sx={{ width: '100px' }} />
          </Img>
          <Typography variant="h5" color="primary">
            Añadir nueva dirección
          </Typography>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="street"
                inputRef={street}
                label="Nombre de la calle"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="exteriorNumber"
                inputRef={exteriorNumber}
                label="Número exterior"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="interiorNumber"
                inputRef={interiorNumber}
                label="Número interior"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="suburb"
                inputRef={suburb}
                label="Colonia"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="cp"
                label="Código Postal"
                inputRef={zipCode}
                type="number"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="municipio"
                label="Alcaldía o Municipio"
                inputRef={city}
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="state"
                label="Estado"
                inputRef={state}
                type="text"
                value="Puebla"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="country"
                label="País"
                inputRef={country}
                value="Mexico"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="telephone"
                label="Número de teléfono"
                type="number"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
          <DialogContentText>
            <Button variant="contained" sx={{ color: 'white', background: '#B6D33C' }} onClick={addAddress}>Agregar dirección</Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
