import * as React from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { PieChart } from './AdminChart/pieChart';
import { LineChart } from './AdminChart/lineChart';
import { HorizontalBarChart } from './AdminChart/horizontalBarChart';
import { VerticalBarChart } from './AdminChart/verticalBarChart';


const Graphics = ({data}) => {
 const productosEnBodega = ["Manzana", "Tomate", "Cilantro", "Perejil", "Aguacate", "Ajo", "Cebolla", "Limon", "Hongos", "Abas" ]
const EditIcon = () => {
  return <svg  width="20.503" height="20.626" viewBox="0 0 20.503 20.626">
  <g id="Group_25" data-name="Group 25" transform="translate(-1445 -926)">
    <path id="Vector" d="M2.68,17.929A2.68,2.68,0,0,1,0,15.248V2.678A2.68,2.68,0,0,1,2.68,0h9.333L9.989,2.028H2.68a.653.653,0,0,0-.653.65v12.57a.653.653,0,0,0,.653.653h12.57a.653.653,0,0,0,.653-.653V7.67l2.028-2.028v9.606a2.68,2.68,0,0,1-2.68,2.68Z" transform="translate(1445 928.697)" fill="#43abae"/>
    <path id="Vector-2" data-name="Vector" d="M12.405.061l2.614,2.612a.275.275,0,0,1-.025.375L3.336,14.684a.3.3,0,0,1-.218.091l-2.9.295A.218.218,0,0,1,0,14.852l.295-2.9a.3.3,0,0,1,.091-.218L12.03.088a.275.275,0,0,1,.375-.028Z" transform="translate(1450.422 926)" fill="#43abae"/>
  </g>
</svg>

}
const DeleteIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="18.58" height="25" viewBox="0 0 18.58 25">
  <g id="Group_32" data-name="Group 32" transform="translate(-1487 -924)">
    <path id="Vector" d="M17.044,3.242H0A1.619,1.619,0,0,1,.474,2.093a1.639,1.639,0,0,1,1.155-.474H6.217L7.028,0h3l.808,1.619h4.581a1.626,1.626,0,0,1,1.623,1.623Z" transform="translate(1487.768 924)" fill="#ff6700"/>
    <path id="Vector-2" data-name="Vector" d="M14.974,19.679H3.6A2.994,2.994,0,0,1,.748,16.59L0,2.464A2.534,2.534,0,0,1,2.584,0H15.992A2.537,2.537,0,0,1,18.58,2.474v.047L17.829,16.59A2.994,2.994,0,0,1,14.974,19.679ZM1.729,2.448l.751,14.116a1.285,1.285,0,0,0,1.132,1.4H14.974a1.287,1.287,0,0,0,1.135-1.4V16.52l.748-14.073a.818.818,0,0,0-.865-.735H2.584a.818.818,0,0,0-.855.735Z" transform="translate(1487 929.321)" fill="#ff6700"/>
    <path id="Vector-3" data-name="Vector" d="M.768,12.2A.768.768,0,0,1,0,11.431V.717a.768.768,0,0,1,1.532,0V11.424a.768.768,0,0,1-.765.775Z" transform="translate(1495.524 933.589)" fill="#ff6700"/>
    <path id="Vector-4" data-name="Vector" d="M.768,12.23H.741A.768.768,0,0,1,0,11.455L.354.741A.765.765,0,0,1,1.118,0h.027a.765.765,0,0,1,.738.771L1.549,11.488a.768.768,0,0,1-.781.741Z" transform="translate(1498.866 933.559)" fill="#ff6700"/>
    <path id="Vector-5" data-name="Vector" d="M1.118,12.207a.768.768,0,0,1-.765-.741L0,.749A.768.768,0,0,1,1.532.7l.354,10.734a.768.768,0,0,1-.741.791Z" transform="translate(1491.828 933.581)" fill="#ff6700"/>
  </g>
</svg>

}
console.log(data)
const [dashboard,setDashboard] = React.useState({
  totalSales : 0
})


  function getTop(){
    
    let f = []
    f.sort()
    let initialValue= 0

    data.reduce(
      (acumulador, valorActual) => acumulador+valorActual,initialValue
    )
    setDashboard({
      totalSales: data.length,
    })

  }
  return (
    <main  >
      <Box sx={{flexGrow:1}} >
        <Grid container  >
          <Grid container  xs={12} md={9} spacing={1} >
            <Grid xs={12} md={8} p={1} >
              <img 
                src='https://firebasestorage.googleapis.com/v0/b/allfresco-52e3c.appspot.com/o/assets%2FAnyConv.com__banner-dashboard.webp?alt=media&token=68dabd7b-1b14-4aae-9665-aff4efe8783d'
                width={"100%"}
              />
            </Grid>
            <Grid item xs={12} md={2} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" >
              <h6  >Los mas vendidos</h6>
              <PieChart arrayData={data.mostSeller} />
            </Grid>
            <Grid item xs={12} md={2} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" p={1} >
              <h6>Los menos vendidos</h6>
              <PieChart arrayData={data.lessSeller} />
            </Grid>
            
            <Grid item xs={12} md={8} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" p={1} >
              <h6>Reporte de ventas</h6>
              <Box display={"flex"} justifyContent="space-between" >
                <Button variant='contained' >Año</Button>
                <Button sx={{background:"#f9f9f9",}} >Mes</Button>
                <Button sx={{background:"#f9f9f9",}} >Dia</Button>
                <Box sx={{backgroundColor:"#f9f9f9", display:"inline-block", width:"100px", paddingLeft:"10px"}} >
                  <span style={{color:"#B6D33C", fontSize:"12px", fontFamily:'Roboto', fontWeight:'bold'}} >Ventas totales</span>
                  <span style={{color:"#43ABAE", display:"block", fontSize:"12px"}} >{data.sells.totalSells}</span>
                </Box>
                <Box sx={{backgroundColor:"#f9f9f9",display:"inline-block", width:"100px",paddingLeft:"10px"}} >
                  <span style={{color:"#B6D33C",fontSize:"12px", fontFamily:'Roboto',fontWeight:'bold'}} >Ingresos</span>
                  <span style={{color:"#43ABAE", display:"block",fontSize:"12px"}} >${data.sells.amount.toFixed(2)}</span>
                </Box>
              </Box>
              <VerticalBarChart arrayData={data.sells.data} />
            </Grid>
            <Grid xs={12} md={4} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"  >
              <h6>Experiencia de cliente</h6>
              <HorizontalBarChart arrayData={data.clientSatisfaction} />
            </Grid>

            <Grid xs={12} md={2} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" >
              <h6>Con mas stock</h6>
              <section  >
                {
                  data.topStock.map( product =>(<div key={product.id} style={{display:"flex", alignItems:'center', marginBottom:"10px"}} >
                  <img src={product.image}
                    width="40px" 
                    height="40px" 
                    style={{boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", marginRight:"10px", marginLeft:"10px"}}
                  />
                    <div style={{display:"flex", flexDirection:"column"}} >
                      <span style={{fontWeight:'bold', color:'#43ABAE', fontSize:"15px"}} >{product.name}</span>
                      <Typography variant='caption' color="#c4c4c4" > {product.stock} - {product.unit} </Typography>
                    </div>
                  </div>))
                }
                
              </section>
            </Grid>
            <Grid xs={12} md={2} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" >
              <h6>Con menos stock</h6>
              <section>
              {
                  data.bottomStock.map( product =>(<div key={product.id} style={{display:"flex", alignItems:'center', marginBottom:"10px"}} >
                  <img src={product.image}
                    width="40px" 
                    height="40px" 
                    style={{boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", marginRight:"10px", marginLeft:"10px"}}
                  />
                  <div style={{display:"flex", flexDirection:"column"}} >
                    <span style={{fontWeight:'bold', color:'#43ABAE', fontSize:"15px"}} >{product.name}</span>
                    <Typography variant='caption' color="#c4c4c4" > {product.stock} - {product.unit} </Typography>
                  </div>
                  </div>))
                }
              </section>
            </Grid>
            <Grid xs={12} md={8} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" >
              <h6>Reclamos</h6>
              <section style={{display:"flex",borderLeft:"1px solid #43ABAE", marginLeft:"15px", paddingLeft:"10px", justifyContent:"space-between", alignItems:"center", paddingRight:"10px" }} > 
                <Box maxHeight="50px" minWidth="50px" height="50px" sx={{backgroundColor:"#c4c4c4", borderRadius:"10px",marginRight:"10px"}} ></Box>
                <div>
                  <Typography variant='caption' color="#43ABAE" >Emily vega</Typography>
                  <Typography variant='caption' display="block" color="#B6D33C" >Email: emilyverga@gmail.com</Typography>
                </div>
                <Typography variant='caption' width="40%" color="#43ABAE" >“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</Typography>
                <IconButton>
                  <EditIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
                
              </section>
            </Grid>

          </Grid>
          <Grid xs={12} md={3} >
            <Grid container xs={12} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" >
              <Grid xs={12} p={1}  >
                <h6>Productos con mas tiempo en bodega</h6>
                <div style={{display:'flex',flexDirection:'column'}} >
                {
                  productosEnBodega.map( product => <span style={{color:"#B6D33C",fontWeight:'bold'}} >{product}</span> )
                }
                </div>
              </Grid>
              <Grid xs={12} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" p={1} >
                <h6>Tiempo promedio de entregas</h6>
                <LineChart />
                <p>Tiempo promedio calculado: 55 min</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </main>
  )
}

export default Graphics