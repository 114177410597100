import { Button, TextField, Typography } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import React, { useRef } from 'react'
import db from '../../../firebase/fbcnf'

const TopLegend = () => {
  const leyend = useRef('')

  const saveLegend =async() => {
    if(leyend.current.value===''){
      alert("Ingrese un texto")
      return;
    }
    console.log(leyend.current.value)
    // await updateDoc(doc(db,'settings', 'leyend'),{
    //   text: leyend.current.value
    // })
  }
  async function getData(){
    try {
      const result = await getDoc(doc(db,'settings', 'legend'))
      if(result){
        leyend.current.value= result.data().text
      }
    } catch (error) {
      console.log("error ", error)
    }
  }
  return (
    <section>
      <Typography variant='h4' >Leyenda</Typography>
      <TextField placeholder='añadir encabezado' inputRef={leyend} rows={3} />
      <Button variant='outlined' onClick={saveLegend} >Guardar</Button>
    </section>
  )
}

export default TopLegend