// import HomeIcon from '@mui/icons-material/Home';
// import AccountIcon from '@mui/icons-material/PersonRounded';
// import CategoriesIcon from '@mui/icons-material/Category';
// import Link from '@mui/material/Link';
import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../StateProvider';

export default function BottomNav() {
  const [{user}] = useStateValue()
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const navigate = useNavigate()


  return (
    <Box sx={{ pb: 7 }} ref={ref} className="bottomNav" >
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            console.log(newValue)
            navigate(newValue)
          }}
        >
          {/* <Link href="/categories"> */}
          <BottomNavigationAction value="/categories" icon={<span className='icon-Men-movil-allfresco' ></span>} />
          {/* </Link> */}
          {/* <Link href="/">  */}
          <BottomNavigationAction value="/" icon={<span className='icon-Inicio-movil-allfresco' ></span>} />
          {/* </Link> */}
          {/* <Link href="/perfil"> */}
          <BottomNavigationAction value="/perfil" icon={ <span className='icon-Perfil-de-usuario-allfresco'  ></span>} />
          {/* </Link> */}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
