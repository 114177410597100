// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import AddressCard from '../user/addressCard';
// import { FormControl, FormGroup, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import Resume from './Resume';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const fecha = new Date();

export default function ThirdStep({ address: { street, exteriorNumber, interiorNumber, suburb, city, state, zipCode, country }, user: { displayName } }) {
    var direccion = "";
    if (street != '') {
        direccion = street + ", #" + exteriorNumber + " " + interiorNumber + ", " + suburb;
    }
    console.log(direccion);
    const today = new Date();
    var now = today.toLocaleTimeString('es-MX');
    const tomorrow = new Date(today);
    let getTomorrow = new Date(tomorrow.setDate(tomorrow.getDate()));
    const dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const [{ date, basket }, dispatch] = useStateValue();
    const [value, setValue] = React.useState(date);
    const handleChange = (event) => {
        setValue(event.target.value);
        dispatch({
            type: actionTypes.SET_DATE,
            date: event.target.value,
        });
    };
    console.log("basket pedidos")
    console.log({ basket })
    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <Item sx={{ textAlign: 'left' }} >
                            <Typography variant='h4' >Detalles de entrega</Typography>
                            {/* <Typography>{today.getHours()} { today.getMinutes()}</Typography> */}
                            <Typography>
                                {
                                    // date == "1:00 p.m - 2:00 p.m" || date == "10:00 a.m - 11:00 a.m" ? fecha.toLocaleString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : (
                                    //     dias[getTomorrow.getDay()] + " " + getTomorrow.getDate() + " de " + meses[getTomorrow.getMonth()]
                                    // )
                                    date.dateDelivery
                                }
                            </Typography>
                            <Typography>
                                {date.time}
                            </Typography>
                            <Typography>
                                {direccion}
                            </Typography>
                            <Typography>
                                {zipCode}
                            </Typography>
                            <Typography>
                                {city}, {state}, {country}
                            </Typography>
                            <Typography>
                                {basket.length} productos
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Resume />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}