import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddressCard from '../user/addressCard';
import Resume from './Resume';
import { Button } from '@mui/material';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import PaymentForm from './PaymentForm';
import NativePicker from './nativePicker';
// import Typography from '@mui/material/Typography';
// import { useStateValue } from '../../StateProvider';
// import { getBasketTotal, getTaxes, getSubtotal } from '../../reducer';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FirstStep({ address: { street, exteriorNumber, interiorNumber, suburb, city, state, zipCode, country }, user: { displayName, uid } }) {
    const db = getFirestore();
    var direccion = "";
    if (street !== '') {
        direccion = street + ", #" + exteriorNumber + " " + interiorNumber + ", " + suburb;
    }
    const [showForm, setShowForm] = React.useState(false)
    const [newAddress, setNewAddress] = React.useState({
        street: "",
        city: "",
        cp: "",
        country: "Mexico",
        exteriorNumber: "",
        interiorNumber: "",
        state: "Puebla",
        suburb: ""
    })

    async function addAddress() {
        try {
            if(street){
                const userAddres = await setDoc(doc(db, 'address', uid), {
                    [Math.floor((Math.random() * 1000000) + 1)]: newAddress
                }, {merge: true})
            } else {
                const userAddres = await setDoc(doc(db, 'address', uid), {
                    main: newAddress
                })
            }
            // console.log(userAddres)
            window.location.reload()
        } catch (error) {
            console.log("Error adding address: " + error)
        }
    }

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} spacing={1} >
                        {/* <Grid item xs={12} sm={3} > */}
                            {
                                street !== "" ? <AddressCard /> : null
                            }
                        {
                            <Button sx={{ marginY: "15px", fontWeight: "bold", color: "#43ABAE", border: "3px solid #43ABAE", '&:hover': { border: "3px solid", background: "#43ABAE", color: "white" } }} variant="outlined" onClick={() => setShowForm(!showForm)} >{street !== "" ? "Agregar una nueva direccion":"Agregar direccion"}</Button>
                        }
                        <Item sx={{ display: showForm ? "block" : "none" }} >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="firstName"
                                        name="firstName"
                                        label="Nombre completo"
                                        fullWidth
                                        autoComplete="given-name"
                                        variant="filled"
                                        value={displayName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="address1"
                                        name="address1"
                                        label="Calle"
                                        fullWidth
                                        autoComplete="shipping address-line1"
                                        variant="standard"
                                        value={newAddress.street}
                                        onChange={(e) => setNewAddress({
                                            ...newAddress,
                                            street: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="extNumber"
                                        name="exterior"
                                        label="Numero exterior"
                                        fullWidth
                                        autoComplete="shipping address-level2"
                                        variant="standard"
                                        value={newAddress.exteriorNumber}
                                        onChange={(e) => setNewAddress({
                                            ...newAddress,
                                            exteriorNumber: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="intNumber"
                                        name="interior"
                                        label="Numero interior"
                                        fullWidth
                                        variant="standard"
                                        value={newAddress.interiorNumber}
                                        onChange={(e) => setNewAddress({
                                            ...newAddress,
                                            interiorNumber: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="city"
                                        name="city"
                                        label="Ciudad"
                                        fullWidth
                                        autoComplete="shipping address-level2"
                                        variant="standard"
                                        value={newAddress.city}
                                        onChange={(e) => setNewAddress({
                                            ...newAddress,
                                            city: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="suburb"
                                        name="col"
                                        label="Colonia"
                                        fullWidth
                                        autoComplete="shipping address-level2"
                                        variant="standard"
                                        value={newAddress.suburb}
                                        onChange={(e) => setNewAddress({
                                            ...newAddress,
                                            suburb: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="state"
                                        name="state"
                                        label="Estado"
                                        fullWidth
                                        autoComplete="shipping state"
                                        variant="standard"
                                        value={newAddress.state}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="zip"
                                        name="zip"
                                        label="Código Postal"
                                        fullWidth
                                        type="number"
                                        autoComplete="shipping postal-code"
                                        variant="standard"
                                        value={newAddress.zipCode}
                                        onChange={(e) => setNewAddress({
                                            ...newAddress,
                                            zipCode: e.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="country"
                                        name="country"
                                        label="País"
                                        fullWidth
                                        autoComplete="shipping country"
                                        variant="standard"
                                        value={newAddress.country}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        onClick={addAddress}
                                        disabled={newAddress.street === "" ? true : false}
                                        variant='contained'
                                        sx={{ background: "#43ABAE" }}
                                    >Guardar</Button>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NativePicker />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}
