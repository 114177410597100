import React from 'react'
import styled from '@emotion/styled';
import { Stack, Button, Grid, Typography, Box } from '@mui/material'
import { actionTypes, getBasketTotal } from '../reducer';
import { useStateValue } from '../StateProvider';
import { Link, useNavigate } from 'react-router-dom';
import { doc, updateDoc, getFirestore, getDoc, collection } from 'firebase/firestore'
import DetailsCoupon from './checkoutForm/DetailsCoupon';

const useStyles = styled((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
export const Total = () => {
  const navigate = useNavigate()
  const classes = useStyles();
  const [{ basket, user, discount }, dispatch] = useStateValue();
  console.log("basket")
  console.log({ basket })
  const cleanrCart = async () => {
    if (user) {
      await updateDoc(doc(db, 'cart', user.uid), {
        data: {}
      })
    }
    dispatch({
      type: actionTypes.EMPTY_BASKET,
      basket: []
    })
    console.log(basket)
    // basket: []
  }
  const db = getFirestore();
  const [validBasket, setValidBasket] = React.useState(false)

  const validateStock = async () => {
    basket.forEach(function (element, key) {
      // products[element.docId] = { quantity: element.quantity, unit: element.unit, price: element.price, url: element.image, nombre: element.name };
      updateStock(element)
    })
  }
  const updateStock = async (product) => {
    const taskDocRef = doc(db, 'product', product.category, 'inventario', product.id)
    try {

      const stockProduct = await getDoc(taskDocRef)
      console.log(stockProduct.data().stock)
      console.log(product.quantityTotal)
      if (stockProduct.data().stock >= product.quantityTotal) {
        // const newStock = (stockProduct.data().stock - product.quantityTotal)
        // await updateDoc(taskDocRef, {
        //   stock: newStock
        // })
        navigate('/carrito')
      } else {
        alert(" el stock de "+product.name +" es menor al pedido")
        setValidBasket(true)
      }
      // onClose()
    } catch (err) {
      alert(err)
    }
  }

  return (
    <Grid item container spacing={2} xs={12} sm={12} md={12} marginTop="20px"  >

      <Grid item xs={2} >  </Grid>
      <Grid item xs={4}  >
        <Typography variant='h6' color="black" >Subtotal</Typography>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={4}>
        <Typography variant='h5' color="black" >$ {getBasketTotal(basket)}</Typography>
      </Grid>

      <Grid item xs={2}> </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" color="black" >Envío</Typography>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={4}>
        <Typography variant='h5' color="black" > $ {getBasketTotal(basket) < 500 ? "46.40" : "00.00"}</Typography>
      </Grid>
      <Grid item xs={2} ></Grid>
      <Grid item xs={4} >
        <Typography variant="h6" color="black">Descuentos {discount>0 && <DetailsCoupon/>} </Typography>
      </Grid>
      <Grid item xs={2} ></Grid>
      <Grid item xs={4}  >
        <Typography variant='h5' color="black"  >$ {discount.toFixed(2)} </Typography>
      </Grid>

      <Grid item xs={2}> </Grid>
      <Grid item xs={4}>
        <Typography variant='h6' color="primary">Total</Typography>
      </Grid>
      <Grid item xs={2}> </Grid>
      <Grid item xs={4}>
        <Typography variant='h5' color="primary" >$ {(getBasketTotal(basket) < 500 ? 46.40 + parseFloat(getBasketTotal(basket)) : parseFloat(getBasketTotal(basket))).toFixed(2)}</Typography>
      </Grid>

      

      {
        basket.length > 0 ? (
          <Stack spacing={2} direction='row' justifyContent="flex-end" width="100%" xs={12} sx={{ textAlign: 'right' }} marginTop="50px" >
            <Button variant='contained' color='primary' onClick={() => cleanrCart()} sx={{ textTransform: 'none' }} >Limpiar Carrito</Button>
            <Button variant='contained' color='primary' onClick={validateStock} disabled={validBasket} sx={{ textTransform: 'none' }} >
              {/* <Link to={user ? '/carrito' : '/signin'} >Comprar</Link>  */}
              Comprar
            </Button>
          </Stack>
        ) : ('')
      }

    </Grid>
  )
}
