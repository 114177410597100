import React from 'react'
import { Link } from 'react-router-dom'

const CookiesPolicy = () => {

  return (
    <main style={{maxWidth:"1080px", margin:"auto", padding:"1rem"}} >
      <h1>Política de Cookies</h1>
      <p>El presente sitio: <Link to={'/'}> <strong>https://allfresco.mx/</strong></Link> Declara que:</p>
      <p>Esta web utiliza <strong>cookies</strong> propias y de terceros para la elaboración de perfiles basados en los hábitos de navegación del usuario y ofrecer productos adaptados a sus intereses.</p>
      <h3>¿Qué es una cookie?</h3>
      <p>Las <strong>cookies</strong> constituyen una herramienta empleada por los servidores web para almacenar y recuperar información acerca de sus visitantes. No es más que un identificador único en forma de fichero de texto que algunos servidores remiten al dispositivo del usuario, para registrar información acerca de lo que hemos estado haciendo por sus páginas, permitiendo así mejorar la calidad y seguridad de la página web. Poseen una fecha de caducidad, que puede oscilar desde el tiempo que dure la sesión, hasta una fecha futura especificada a partir de la cual dejan de ser operativas.</p>
      <h3>Utilidad de las cookies en este Portal</h3>
      <p>En este sitio se utilizan cookies para facilitar la navegación dentro de esta página web, por motivos de seguridad y para obtener una mayor eficacia y personalización de los servicios ofrecidos a los Usuarios mediante el análisis de navegación.</p>
      <h3>Tipos de cookies empleadas en este Portal</h3>
      <h4><strong>Cookies Propias</strong></h4>
      <p>Con el fin de reconocerte y prestarte un mejor servicio, nuestro sitio utiliza cookies (pequeños archivos de texto que tu navegador almacena) propias. Las ventajas que conlleva la aceptación de nuestras cookies se traduce en un ahorro de tiempo. Asimismo pueden ser utilizadas también para reconocerte entre visitas sucesivas y así adaptar el contenido que se te muestra, para obtener información acerca de la fecha y hora de tu última visita, medir algunos parámetros de tráfico dentro del propio sitio, y estimar el número de visitas realizadas, de manera que podamos enfocar y ajustar los servicios y promociones de forma más efectiva.<br />
        Ninguna cookie permite que pueda contactarse con tu número de teléfono, tu dirección de correo electrónico o con cualquier otro medio de contacto. Ninguna cookie puede extraer información de tu disco duro o robar información personal.</p>
      <p>Cookies estrictamente necesarias usadas en este sitio:</p>
      <p>Nombre: SL_C_23361dd035530_KEY<br />
        Propósito: Gestiona la sesión actual.</p>
      <p>Nombre: SL_C_23361dd035530_SID<br />
        Propósito: Gestiona la sesión actual.</p>
      <p>Nombre: SL_C_23361dd035530_VID<br />
        Propósito: Gestiona la sesión actual.</p>
      <h4><strong>Cookies de Terceros: Cookies de Google Analytics</strong></h4>
      <p>El sitio utiliza el servicio Google Analytics, que es prestado por Google, Inc., entidad cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos («Google»).<br />
        Google Analytics utiliza cookies para ayudarnos a analizar el uso que hacen los usuarios del sitio. La información que genera la cookie acerca de tu uso del sitio (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en sus servidores de Estados Unidos. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de tu uso del sitio, recopilando informes de la actividad del sitio y prestando otros servicios relacionados con la actividad del sitio y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará tu dirección IP con ningún otro dato del que disponga Google.</p>
      <p>Si lo deseas puedes rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de tu navegador. Sin embargo, si lo haces, puede ser que no puedas usar la plena funcionabilidad de este sitio. Al utilizar este sitio consientes el tratamiento tu información por Google en la forma y para los fines arriba indicados.<br />
        Más información en <a href='https://www.google.es/intl/es/policies/technologies/cookies/'>https://www.google.es/intl/es/policies/technologies/cookies/</a>.</p>
      <p>Google ha desarrollado un complemento que puede instalar en su navegador si desea no ser rastreado por sus cookies: <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a></p>
      <p>Nombre: _ga<br />
        Duración: 2 años<br />
        Propósito: Se usa para diferenciar entre usuarios y sesiones. La cookie se crea al cargar la librería javascript y no existe una versión previa de la cookie _ga. La cookie se actualiza cada vez que envia los datos a Google Analytics.</p>
      <p>Nombre: _gat<br />
        Duración: 1 Minuto<br />
        Propósito: Se usa para diferenciar entre los diferentes objetos de seguimiento creados en la sesión. La cookie se crea al cargar la librería javascript y no existe una versión previa de la cookie _gat. La cookie se actualiza cada vez que envia los datos a Google Analytics.</p>
      <p>Nombre:_gid<br />
        Duración: 24 horas<br />
        Propósito: Se usa para distinguir a los usuarios.</p>
      <h4><strong>Cookies de Terceros: Cookies de Cloudflare</strong></h4>
      <p>Nombre:_cfduid</p>
      <p>Duración: 30 Días</p>
      <p>Propósito:_cfduid cookie sirve para identificar visitantes individuales de forma privada.</p>
      <p>La cookie _cfduid ayuda a Cloudflare a detectar visitantes malintencionados a los sitios web de nuestros Clientes y minimiza el bloqueo de usuarios legítimos. Puede colocarse en los dispositivos de los usuarios finales de nuestros clientes para identificar a los clientes individuales detrás de una dirección IP compartida y aplicar la configuración de seguridad por cliente. Es necesario para admitir las funciones de seguridad de Cloudflare.</p>
      <p>La cookie _cfduid:</p>
      <ul>
        <li>No permite el seguimiento entre sitios.</li>
        <li>No permite seguir a los usuarios de un sitio a otro combinando varios identificadores _cfduid en un perfil.</li>
      </ul>
      <p>Más información: En Cloudflare <a href='https://support.cloudflare.com/hc/en-us/articles/200170156-Understanding-the-Cloudflare-Cookies'>https://support.cloudflare.com/hc/en-us/articles/200170156-Understanding-the-Cloudflare-Cookies</a></p>
      <h4><strong>Cookies de Terceros: Cookies de Facebook</strong></h4>
      <p>Nombre:_fbp</p>
      <p>Duración:1 día</p>
      <p>Propósito: Permite usar funciones publicitarias (como los públicos, las conversiones, la segmentación y la optimización) y los datos de Facebook Analytics.</p>
      <h3><strong>Configuración del usuario para evitar Cookies.</strong></h3>
      <p>Desde www.isesinstituto.com y en cumplimiento de la normativa legal vigente, ponemos a tu disposición la información que te permita configurar tu navegador/navegadores de Internet para mantener tu privacidad y seguridad en relación a las cookies. Por ello, te facilitamos la información y enlaces a los sitos de soporte oficiales de los principales navegadores para que puedas decidir si deseas o no aceptar el uso de cookies.</p>
      <p>Así, puedes bloquear las cookies a través de las herramientas de configuración del navegador o bien puedes configurar tu navegador para que te avise cuando un servidor quiera guardar una cookie:</p>
      <p>Si utilizas Microsoft Internet Explorer, en la opción de menú Herramientas &gt; Opciones de Internet &gt; Privacidad &gt; Configuración. Para saber más visita https://support.microsoft.com/es-es/help/17442/windows-internet-explorer…</p>
      <p>Si utilizas Firefox, en la opción de menú Herramientas &gt; Opciones &gt; Privacidad &gt; Cookies. Para saber más visita<br />
        <a href='https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros' >
          https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros</a></p>
      <p>Si utilizas Chrome, en la sección de Opciones &gt; Opciones avanzadas &gt; Privacidad. Para saber más <a href='http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647'>http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a></p>
      <p>Si utilizas Opera, en la opción de Seguridad y Privacidad, podrás configurar el navegador. Para saber más visita<br />
        <a href='http://www.opera.com/help/tutorials/security/cookies/'>
          http://www.opera.com/help/tutorials/security/cookies/</a></p>
      <p>Si utilizas Safari, en la opción Preferencias &gt; Seguridad o Privacidad, podrás configurar Navegador. Para saber mas visita:<br />
        <a href='https://support.apple.com/kb/PH21411?viewlocale=es_ES&amp;locale=es_ES' >
          https://support.apple.com/kb/PH21411?viewlocale=es_ES&amp;locale=es_ES</a></p>
      <p>Si desea deshabilitar Google Universal Analytics, puede instalar la siguiente extensión proporcionada por Google: <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a></p>

    </main>

  )
}

export default CookiesPolicy