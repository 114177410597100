import React, { useEffect, useRef } from 'react';
import { collection, getDoc, getDocs, getFirestore } from "firebase/firestore";
import { useStateValue } from './StateProvider';
import db from './firebase/fbcnf';

const products = [
    {
        id: 1,
        name: 'P1',
        productType: 'T1',
        price: 100,
        image: 'http://assets.stickpng.com/images/580b57fcd9996e24bc43c200.png',
        description: '',
    },
    {
        id: 2,
        name: 'P2',
        productType: 'T1',
        price: 50,
        image: 'https://www.seekpng.com/png/detail/344-3446995_zanahoria-merqueo-3-und-zanahoria-png.png',
        description: '',
    },
];

const obtenerDatos = async() => {
    const db = getFirestore();
    const data = await getDocs(collection(db, 'products'));
    data.forEach((doc) => {
        console.log(doc.data());
        const info = doc.data();
        products.push({
            id:4,
            name: info.name,
            productType: 'T1',
            price: 0,
            image: 'http://assets.stickpng.com/images/580b57fcd9996e24bc43c131.png',
            description: '',
        });
    });
}
//obtenerDatos();

export default products;