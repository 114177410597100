import { Box, Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import { useLayoutEffect } from 'react';
import { getInfo, saveInfo } from '../../../firebase/functions';

const EditField = () => {
  const [value, setValue] = useState('');

  useLayoutEffect(() => {
    loadInfo();
  }, [])
  const loadInfo = async() =>{
    const {result, error} = await getInfo();
    if(error){
      console.log(error);
      alert("ha ocurrido un error");
    } else {
      console.log("datos: ", result.data());
      setValue(result.data().data);
    }
  }
  const saveDoc = async() => {
    const {result,error} = await saveInfo(value)
    if(error){
      console.log(error)
      alert("Error al guardar")
    } else{
      alert("El documento se ha actualizado")
    }
  }

  return (
    <Box>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={6} >
          <EditorToolbar />
          <ReactQuill 
            theme='snow'
            value={value}
            onChange={setValue}
            placeholder={"Escribe aqui tu informacion..."}
            modules={modules}
            formats={formats}
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <div style={{height:"69px",width:"100%"}} ></div>
          <ReactQuill 
            value={value}
            readOnly={true}
            theme='bubble'
          />
        </Grid>
      </Grid>
      <Button onClick={saveDoc} variant='contained' >Guardar</Button>
    </Box>
  )
}

export default EditField