import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { getCupon } from '../../firebase/functions'
import { useStateValue } from '../../StateProvider'
import {  actionTypes } from '../../reducer'
const ApplyCupon = () => {
  const [showMessage, setshowMessage] = useState(false)
  const [message, setMessage] = useState({
    title:'',
    text:''
  })
  const cupon = useRef('')
  const [{},dispatch] = useStateValue()

  const handleApply = async() => {
    try {
      if(cupon.current.value===''){
        alert('Por favor ingrese un cupon')
        return;
      }
      const {result, error} = await getCupon(cupon.current.value)
      if(error){
        alert("el cupon no existe")
        return;
      }
      console.log("cupon: ",result)
      // if(result.minQuantity>500) alert("Necesita almenos")
      setMessage({
        title: result.title,
        text: result.text
      })
      setshowMessage(true)
      
      dispatch({
        type: actionTypes.ADD_COUPON,
        coupon: {
          ...result
        }
      })

    } catch (error) {
      console.log(error)
    }
  }

  const SuccessMessage = ()=> (<Box sx={{backgroundColor:"#b6d33c", padding:2, marginTop:1, borderRadius:5}} >
    <Typography  color='white'>Cupon valido - {message.title}</Typography>
    <Typography color="white" >{message.text} 
    </Typography>
  </Box>)
  return (
    <Paper sx={{padding:2, width:"100%"}} >
      <Typography color='#b6d33c' marginBottom={1} >Aplicar cupón</Typography>
      <Box display='flex' flexDirection={{xs:'column', md:'row' }} justifyContent='space-between'  >
        <TextField sx={{marginLeft:2}} placeholder='1COMPRA' label='Código de cupón' inputRef={cupon} />
        <Button variant='outlined' onClick={handleApply} sx={{marginLeft:2, marginY:1}} >Aplicar</Button>
      </Box>
      {showMessage && <SuccessMessage />}
    </Paper>
  )
}

export default ApplyCupon