import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//   labels: ['Manzana', 'Platano', 'Brocoli', ],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3,],
//       backgroundColor: [
//         '#B6D33C',
//         '#43ABAE',
//         '#FF6700',
//       ],
//       borderColor: [
//         '#fff',
//         '#fff',
//         '#fff',
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

export function PieChart({arrayData}) {
  let d=[]
  let l =[]
  arrayData.map( data =>{
    l.push(data.nombre+" "+data.unit)
    d.push(data.quantity)
  } )
  const data = {
    labels: l,
    datasets: [
      {
        label: '# of Votes',
        data: d,
        backgroundColor: [
          '#B6D33C',
          '#43ABAE',
          '#FF6700',
        ],
        borderColor: [
          '#fff',
          '#fff',
          '#fff',
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut data={data} />;
}
