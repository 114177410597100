// import Grid from '@mui/material/Grid';
import Product from './product';
import products from '../product-data';
// import { getDocs, collection, getFirestore } from 'firebase/firestore';
// import Dialog from '@mui/material/Dialog';
// import TextField from '@mui/material/TextField';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useStateValue } from '../StateProvider';
import banner1 from '../assets/Promo-Allfresco-Principal.webp';
import banner2 from '../assets/Promo-Allfresco-Fresas-Berries.webp';
import banner3 from '../assets/Allfresco-Semillas-a-granel.jpg';
import banner4 from '../assets/Allfresfo-Jueves de frescura.jpg';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Category from './category';
import Button from '@mui/material/Button';
import Carousel from './slider/carousel';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';


const Banner = styled('div')(({ theme }) => ({
  img: {
    width: '100%',
    margin: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}));

const BannerDividido = styled('div')(({ theme }) => ({
  img: {
    width: '50%',
    margin: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
}));

const Linked = ({href})=>{
  return (<Link to={href} style={{
    '&:hover':{background:"#fff"},
    color:'#43abae', 
    fontFamily:'Raleway',
    marginY:2
  }} >VER MAS</Link>)
}

let categoryName = "";

export default function Products() {
  const [{ product, productos }, dispatch] = useStateValue();
  const [mostSeller, setMostSeller] = React.useState([])
  // var mostSeller = product.slice(0, 4)
  const [count, setCount] = React.useState(0);
  const [limit, setLimit] = React.useState(4);
  const [open, setOpen] = React.useState(false)
  const [contador, setContador] = React.useState(0)
  const banner1Mobile = 'https://firebasestorage.googleapis.com/v0/b/allfresco-52e3c.appspot.com/o/assets%2Fmain-banner-mobile.webp?alt=media&token=0574f18e-454e-4dd9-9163-e068d10d9227'
  const aumentar = () =>{
    setContador(contador+1)
  }
  // console.log(product)
  React.useEffect(() => {
    if (product.length > 0) {
      setMostSeller([...product])
    }


  }, [product.length])
  const handleClose = () => {
    setOpen(false)
  };


  return (
    <Box id='inicio' className='contenido' sx={{ flexGrow: 2, padding: 2, marginTop: "1rem" }}>
      
      <Banner>
        <Link to='/frutas'>
        <picture>
            <source srcSet={banner1Mobile}
                    media="(max-width:600px)"/>
            <img src={banner1} alt="Banner de inicio allfresco.mx"  />
        </picture>
        </Link>
      </Banner  >
      <Typography variant="h2" marginY="2rem" >
        Todos
      </Typography>
      <Carousel product={product} />
      {/* <Button sx={{'&:hover':{background:"#fff"}}} > <Link to='/frutas/' >Ver mas...</Link> </Button>
      <Linked href="/frutas/" /> */}
      <BannerDividido>
        <Link to='/granos'>
          <img src={banner3} sx={{ width: 100, paddingRight: '10px' }} />
        </Link>
        <Link to='verduras'>
          <img src={banner4} sx={{ width: 100, paddingLeft: '10px' }} />
        </Link>
      </BannerDividido>

      <Category categoryName="Frutas" limit={4}    ></Category>
      {/* <Button sx={{'&:hover':{background:"#fff"}, color:'#43abae', fontFamily:'Raleway'}} > <Link to='/frutas/' >Ver mas...</Link> </Button> */}
      <Linked href='/frutas/' />

      {/* categorias */}
      <Stack sx={{ flexDirection: "row", marginTop:"20px", scrollbarWidth:'0px' }} gap={"16px"} overflow="auto" >
        <Link to='lacteos' >
          <img src="img/lacteos.png" sx={{ width: { xs: "70%", md: "25%" } }} alt='Departamento de lacteos allfresco.mx' />
        </Link>

        <Link to='carnes'>
          <img src="img/carnes.png" sx={{ width: { xs: "70%", md: "25%" } }} alt='Departamento de carnes allfreso.mx'/>
        </Link>

        <Link to='panaderia'>
          <img src="img/panaderia.png" sx={{ width: { xs: "70%", md: "25%" } }} alt='Departamento de panaderia allfreso.mx'/>
        </Link>
        <Link to='allyouneed'>
          <img src="img/allyouneed.png" sx={{ width: { xs: "70%", md: "25%" } }} alt='Todo lo que necesitas:allfresco.mx' />
        </Link>
      </Stack>

      <Category categoryName="Verduras" limit={4} ></Category>
      {/* <Button sx={{'&:hover':{background:"#fff"}}} > <Link to='/verduras/' >Ver mas...</Link> </Button> */}
      <Linked href='/verduras/' />

      <div style={{height:"20px", flex:1}} ></div>

      <Banner>
        <img src={banner2} sx={{ width: 100, margin: 'auto' }} alt='Banner footer para allfresco' />
      </Banner>

      
    </Box>
  );
}