// import CardHeader from '@mui/material/CardHeader';
// import CardContent from '@mui/material/CardContent';
// import IconButton from '@mui/material/IconButton';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Paper from '@mui/material/Paper';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { actionTypes } from '../reducer';
import { useStateValue } from '../StateProvider';
import { ButtonGroup, Grid } from '@mui/material';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

export default function CheckoutCard({ product: { id, name, productType, price, image, description, quantity, docId, category, primaryUnit, piecesPerKg, unit, priceTotal, quantityTotal, weight, defaultUnit }, handleClick }) {
  const [{ basket, user }, dispatch] = useStateValue();
  const [unidad, setUnit] = React.useState(unit);
  const [selected, setSelected] = React.useState(unit == "Pza" ? true : false);
  const [subtotal, setSubtotal] = React.useState((price / parseInt(piecesPerKg) * quantity))

  React.useEffect(() => {
    if (quantity == 0) removeItem()
  }, [quantity])


  const db = getFirestore();
  var url = "/" + category + "/" + id;
  const uPza = async () => {
    setUnit("Pza");
    changeUnit("Pza");
    setSelected(true);
  }
  const uKg = async () => {
    setUnit("grs");
    changeUnit("grs");
    setSelected(false)
  }
  const removeItem = async () => {
    dispatch({
      type: actionTypes.REMOVE_ITEM,
      id: id,
    });
    try {
      var data = {};

      basket.forEach(function (element) {
        if (element.docId !== docId) {
          data[element.docId] = { quantity: element.quantity, unit: element.unit }
        }
      })

      await setDoc(doc(db, "cart", user.uid), {
        data
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  const changeUnit = async (u) => {
    dispatch({
      type: actionTypes.CHANGE_UNIT,
      id: id,
      unit: u
    });
    try {
      var cantidad = 1;
      var data = {};
      if (u.toLowerCase() === "grs") {
        cantidad = parseInt(weight);
      }
      basket.forEach(function (element) {
        if (element.docId !== docId) {
          data[element.docId] = { quantity: element.quantity, unit: element.unit }
        } else {
          data[element.docId] = { quantity: cantidad, unit: u }
        }
      })

      await setDoc(doc(db, "cart", user.uid), {
        data
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  const addToBasket = async () => {
    var cantidad = 1;
    if (unidad === "grs") {
      cantidad = parseInt(weight);
    }
    dispatch({
      type: actionTypes.ADD_TO_BASKET,
      item: {
        id,
        name,
        productType,
        image,
        price,
        description,
        quantity: cantidad,
        docId,
        unit: unidad,
        defaultUnit,
      }
    });

    try {
      var data = {};
      var existe = false;

      if (basket.length == 0) {
        data[docId] = { quantity: quantity + cantidad, unit: unidad }
      }
      Object.keys(data).forEach(key => {
        if (key == docId) {
          existe = true;
        }
      })
      if (!existe) {
        data[docId] = { quantity: quantity + cantidad, unit: unidad }
      }
      console.log(basket);
      basket.forEach(function (element) {
        if (element.docId != docId) {
          data[element.docId] = { quantity: element.quantity, unit: element.unit }
          // console.log(data[element.docId]);
        }
      })
      await setDoc(doc(db, "cart", user.uid), {
        data
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    handleClick(image, cantidad = cantidad + quantity, unidad, name)
  }
  const removeToBasket = async () => {
    var cantidad = 1;
    if (unidad == "grs") {
      cantidad = parseInt(weight);
    }
    dispatch({
      type: actionTypes.REMOVE_TO_BASKET,
      item: {
        id,
        name,
        productType,
        image,
        price,
        description,
        quantity: cantidad,
        unit: unidad,
        docId
      }
    });
    if ((quantity - cantidad) > 0) {
      try {
        var data = {};
        var existe = false;

        if (basket.length == 0) {
          data[docId] = { quantity: quantity + cantidad, unit: unidad }
        }
        Object.keys(data).forEach(key => {
          if (key == docId) {
            existe = true;
          }
        })
        if (!existe) {
          data[docId] = { quantity: quantity - cantidad, unit: unidad }
        }
        basket.forEach(function (element) {
          if (element.docId != docId) {
            data[element.docId] = { quantity: element.quantity, unit: element.unit }
          }
        })
        await setDoc(doc(db, "cart", user.uid), {
          data
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }

  }

  return (
    <Grid className='checkout-table' container spacing={1} m="auto" marginBottom={2}>
      <Grid item md={2.4} xs={4} >
        <Item sx={{ justifyContent: "center" }}>
          <Card sx={{ maxWidth: 345 }}>
            <Link to={url}>
              <CardMedia
                id={id}
                component="img"
                // height="50px"
                // width="50px"
                image={image}
                alt={`Imagen de ${name}`}
                sx={{ width: { xs: "100px", sm: "150px" }, height: { xs: "100px", sm: "150px" } }}
              />
            </Link>
          </Card>
        </Item>
      </Grid>
      <Grid item md={9.6} xs={8}>
        <Grid container >
          <Grid item md={3} xs={12}>
            <Item sx={{ justifyContent: "center", alignItems: "center", paddingY: "0px" }} >
              <Typography variant="h6" sx={{ color: "#43ABAE", display: "flex", alignItems: 'center' }}  >
                {name}
              </Typography>
            </Item>
          </Grid>
          <Grid item md={3} xs={12}>
            <Item sx={{ justifyContent: "center", paddingY: "0px" }} >
              <Typography variant="h4" color="text.secondary" >
                $ {
                  primaryUnit == "Pza" ? (
                    `${price} por Pza`
                  ) : unidad==="grs"
                  ?( `${price} por K` )
                  : (
                    // unidad==="grs"?(price):(price/1000*weight).toFixed(2) por unidad == "grs" ? ("Kg") : "pieza"
                    `${unidad === "grs" ? (price) : (price / 1000 * weight).toFixed(2)} por ${unidad == "grs" ? "Kg" : "pieza"}`
                  )
                }

              </Typography>
            </Item>
          </Grid>
          <Grid item md={3} xs={12}>
            <Item sx={{ justifyContent: "center", paddingY: "0px" }}>
              <Box flexDirection="column">
                <CardActions sx={{ justifyContent: "center", padding: "0" }}>
                  {/* <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth sx={{ boxShadow: 0, borderRadius: '10px' }}>
                    <Button onClick={uPza}>Pieza</Button>
                    <Button onClick={uKg}>Kilo</Button>
                  </ButtonGroup> */}

                  {/* botones con estilo */}
                  <ButtonGroup color="secondary" variant="contained" aria-label="outlined primary button group" fullWidth sx={{ boxShadow: 0, borderRadius: '10px' }}>
                    <Button onClick={() => uPza()} sx={{ borderRadius: "10px", paddingTop: "1", paddingBottom: "1" }} color={selected ? "secondary" : "inactive"} >Pieza</Button>
                    {
                      primaryUnit == "Pza" ?
                        null
                        :
                        <Button onClick={() => uKg()} sx={{ borderRadius: "0 10px  10px 0", paddingTop: "0", paddingBottom: "0" }} color={selected ? "inactive" : "secondary"} >Kilo</Button>
                    }
                  </ButtonGroup>
                </CardActions>



                {/* <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ height: '35px' }}>
                  <Button onClick={removeToBasket}>-</Button>
                  <Button>{quantity} {unidad}</Button>
                  <Button onClick={addToBasket}>+</Button>
                </ButtonGroup> */}

                <ButtonGroup color='secondary' variant="contained" aria-label="outlined primary button group" fullWidth sx={{ boxShadow: 0, borderRadius: '10px', marginTop: "7px" }}>
                  <Button onClick={() => removeToBasket()} sx={{ width: "50px", borderRadius: "10px 0 0 10px", paddingTop: "1", paddingBottom: "1" }} color="secondary" className='min-btn'>-</Button>
                  <Button sx={{ backgroundColor: "#F9F9F9", paddingTop: "0", paddingBottom: "0", textTransform: "none", fontWeight: "400", color: "#ceb19b" }} className="card-btn-unit">{quantity} {unidad === "grs" ? "g" : "Pza"}</Button>
                  <Button onClick={() => addToBasket()} sx={{ width: "50px", borderRadius: "0 10px 10px 0", paddingTop: "1", paddingBottom: "1" }} color="secondary" className='plus-btn'>+</Button>
                </ButtonGroup>

                {/* <DeleteIcon fontSize='large' onClick={removeItem} sx={{ display: "block" }} /> */}
                <Button variant='text' onClick={() => removeItem()} size="small" sx={{ color: "#43ABAE" }} fullWidth >ELIMINAR</Button>
                {/* <Typography variant='caption' > ( aprox. {(1000 / piecesPerKg).toFixed(2)} grs. por pieza )</Typography> */}
              </Box>
            </Item>
          </Grid>
          <Grid item md={3} xs={12} >
            <Item sx={{ justifyContent: "center", paddingY: "0px", }} >
              <Typography variant='h4' sx={{ color: "#707070" }}  > $ {
                primaryUnit === "Pza" ? (
                  `${(price * quantity).toFixed(2)}`
                ) :
                  unidad === "grs" ?
                    ((price / 1000 * quantity).toFixed(2))
                    : (
                      (price / (1000 / weight) * quantity).toFixed(2)
                    )
                // unidad === "grs" ?  : ()
              } MXN </Typography>
            </Item>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}
