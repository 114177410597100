import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import OrderItem from './orderItem';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { Rating, Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('#445', "10/06/2022", "$550.23", "Pagado", "Entregado"),
  createData('#446', "10/06/2022", "$550.23", "Pagado", "Entregado"),
];
const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#c8db7a',
  },
  '& .MuiRating-iconHover': {
    color: '##B6D33C',
  },
});

export default function Orders() {
  const [{ user, notification }, dispatch] = useStateValue();
  const db = getFirestore();
  const [orders, setOrders] = React.useState([]);
  const [orderData, setOrderData] = React.useState({});
  const [rate, setRate] = React.useState(3)
  const [showRate, setShowRate] = React.useState(false)
  const [rateOrder, setRateOrder] = React.useState(0)
  const getOrders = async () => {
    var datos = []
    const querySnapshot = await getDoc(doc(db, "orders", user.uid));
    const userOrders = querySnapshot.data().orders
    console.log(userOrders)
    Object.keys(userOrders).forEach((doc) => {
      console.log(userOrders[doc])
      userOrders[doc]['id'] = doc
      datos = [...datos, userOrders[doc]]
    });
    datos.sort( (a,b) => b.date.seconds - a.date.seconds)
    setOrders([...datos])
    // console.log(orders)
  }
  React.useEffect(() => {
    getOrders()
    console.log("orders")
    console.log(orders)
  }, [])

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (item) => {
    setOrderData(item)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const confirmar = async (idOrder) => {
    try {
      setRateOrder(idOrder)
      const orders = {}
      orders[idOrder] = {
        statusOrder: "confirmado"
      }
      await setDoc(doc(db, 'orders', user.uid), {
        orders
      }, { merge: true })
      dispatch({
        type: actionTypes.SET_NOTIFYCATION,
        notification: null
      })
      setShowRate(true)
      // Refresh orders
      getOrders()
    } catch (error) {
      alert("Error al confirmar pedido")
      console.log(error)
    }
  }
  function onClose(){
    try {
      console.log()
    } catch (error) {
      console.log(error)
    }
    console.log(rate)
    return (false)
   }
   const handleRate = async()=>{
    try {
      const orders = {}
      orders[rateOrder] = {
        rate: rate
      }
      await setDoc(doc(db, 'orders', user.uid), {
        orders
      }, { merge: true })
      setShowRate(false)
    } catch (error) {
      console.log(error)
    }
   }

  return (
    <TableContainer component={Paper}>
      <Table fullWidth sx={{ maxWidth: 'xl' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>No. de pedido</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Pago</TableCell>
            <TableCell>Estado del pedido</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {orders.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {/* <Link to="/" >{1664404087541}</Link> */}
                <Button onClick={() => handleClickOpen(item)} >{item.id}</Button>

              </TableCell>
              <TableCell>{(new Date(item.date.seconds * 1000)).toLocaleString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
              {/* <TableCell>{item.date.seconds.toDateString() }</TableCell> */}
              <TableCell>${item.basketTotal}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.statusOrder == "entregado" ?
                <Button variant='contained' onClick={() => confirmar(item.id)} >Confirmar</Button>
                :item.statusOrder
              }</TableCell>
            </TableRow>
          ))}

        </TableBody>
      </Table>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Pedido # {orderData.id}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <OrderItem itemData={orderData} />
          <Typography gutterBottom>
            Gracias por comprar en allfresco.mx
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog open={showRate} onClose={()=>setShowRate(false)} >
        <Box p={2} >
          <Typography marginBottom={2} variant='h3'>Cuentanos!, ¡Como fue tu experiencia?</Typography>
          <StyledRating
            name="customized-color"
            // defaultValue={2}
            value={rate}
            onChange={(e, newValue)=>setRate(newValue)}
            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
            precision={1}
            icon={<FavoriteIcon fontSize="inherit" />}
            emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
          />
          <Button variant='outlined' onClick={handleRate} >calificar</Button>
          {
            rate === 3?
            <textarea></textarea>
            : null
          }
        </Box>
      </Dialog>

    </TableContainer>
  );
}
