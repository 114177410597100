import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore"
import db from "./fbcnf"

export const getCupon = async (code) => {
  try {
    const result = await getDoc(doc(db, 'coupons', code))
    if(result){
      const now = new Date()
      if(now<new Date(result.data().dateStart.seconds *1000) || now> new Date(result.data().dateEnd.seconds *1000)){
        return { error:'cupon no valido'}
      }
      return {
        result: result.data(),
        error:false
      }
    }
  } catch (error) {
    return {error:error}
  }
}

/**
 * 
 * @param {string} uid 
 * @param {product[]} basket 
 * @param {string} docId 
 * @param {product} product 
 */
export const saveBasketOnCloud = async(uid, basket, docId, quantity, cantidad, unidad, weight) => {
  try {
    let data = {};
    let existe = false;

    if (basket.length == 0) {
      data[docId] = { quantity: quantity + cantidad, unit: unidad, weight }
    }
    Object.keys(data).forEach(key => {
      if (key == docId) {
        existe = true;
      }
    })
    if (!existe) {
      data[docId] = { quantity: quantity + cantidad, unit: unidad }
    }
    basket.forEach(function (element) {
      if (element.docId != docId) {
        data[element.docId] = { quantity: element.quantity, unit: element.unit, weight: element.weight, price:element.price }
      }
    })
    await setDoc(doc(db, "cart", uid), {
      data
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}


export const SendMailToPickers = async()=>{
  try {
    const usersRef = collection(db, 'users')    
    const q = query(usersRef, where("role","==","empacador"))
    const result = await getDocs(q)
    console.log("resultado")
    const users=[]
    result.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      users.push(doc.data().email)
    });

    // console.log("destinatarios => ", users)

    const emailContent = {
      to: users,
      message: {
        subject: "Nuevo pedido",
        text: "Has recibido un nuevo pedido en allfresco.mx",
        html: `<section>
          <h1>Hola, Nuevo pedido</h1>
          <p>Se ha realizado un nuevo pedido, consulta los detalles en tu cuenta de allfresco.mx </p>
        </section>`,
      },
    }
    const collectionRef = collection(db, 'mail');
    await addDoc(collectionRef,emailContent)
    alert("emails enviados")
  } catch (error) {
    console.log(error)
    return {
      error: error
    }
  }
}

export const saveInfo = async (data) => {
  let result=null;
  let error=null;
  try {
    result = await setDoc(doc(db,'settings', 'info'), {
      data,
      editedAt: serverTimestamp()
    })
    return {
      result,
      error
    }
  } catch (error) {
    return {error:error}
  }
}
export const getInfo = async() => {
  let error=null;
  let result= null;
  try {
    result = await getDoc(doc(db,'settings','info'))
    return { result, error}
  } catch (error) {
    return {error:null}
  }
}

export const getUserName = async (uid) => {
  try {
    const docRef = doc(db, 'users', uid)
    const res = await getDoc(docRef)
    // console.log("name, ",res.data().userName)
    return res.data().userName
  } catch (error) {
    console.log("error to get name")
    console.log(error)
  }
}