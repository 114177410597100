import { Button, Input, MenuItem, Select, Typography } from '@mui/material'
import { updateDoc, doc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import db from '../../../firebase/fbcnf';
import { uploadImageToFirebase } from '../adminFunctions/functions';

const UploadBanners = () => {
  const [bannerType, setBannerType] = useState('')
  // const bannerType = useRef();
  const image = useRef();

async function saveImage(){
  if(bannerType===''){
    alert("Seleccione el tipo de banner")
    return;
  }
  if(image.current.files.length===0){
    alert("Seleccione una imagen")
    return;
  }
  let plattform=''
  let position = ''
  switch(bannerType){
    case 'main-web':
      plattform= 'web'
      position= 'main'
      break;
    case 'footer-web':
      plattform= 'web'
      position= 'footer'
      break;
    case 'main-mobile':
      plattform= 'mobile'
      position= 'main'
      break;
    case 'footer-mobile':
      plattform= 'mobile'
      position= 'footer'
      break;
  }
  // await uploadImageToFirebase(file);
  await updateDoc(doc(db,'settings','banner'),{
    [`${plattform}.${position}`]:"imgURL"
  }, {merge:true})

  console.log(bannerType)
  console.log(image.current.files)
  // if(bannerType.current.files){
  //   return 
  // }
}

useEffect(()=>{

},[])

  return (
    <section style={{marginTop:"20px"}} >
      <Typography variant='h4'>Banners</Typography>
      
      <Typography>Imagen para banner principal, preferentemente webp o jpge</Typography>
      <Typography>(1200x400) para web y 300X200 para moviles</Typography>
      <input type='file' accept='image/webp image/jpeg image/png' style={{display:"block"}} ref={image} />
      <Select
        label='banner'
        sx={{marginRight:"30px", minWidth:"130px"}}
        value={bannerType}
        onChange={(e)=>setBannerType(e.target.value)}
      >
        <MenuItem  value='main-web' >Main(web)</MenuItem>
        <MenuItem  value='footer-web' >footer(web)</MenuItem>
        <MenuItem  value='main-mobile' >Main(mobile)</MenuItem>
        <MenuItem  value='footer-mobile' >footer(web)</MenuItem>
      </Select>
      <Button variant='outlined' onClick={saveImage}>Guardar</Button>

    </section>
  )
}

export default UploadBanners