import React, { useState, useRef, useEffect } from 'react'
import { addDoc, collection, getFirestore, doc, setDoc, updateDoc, onSnapshot, deleteDoc, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Button, Dialog, FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField, Typography, Box, Chip, AlertTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
// import {useStorage } from "reactfire";
// import { actionTypes } from '../../../reducer';
// import { useStateValue } from '../../../StateProvider';
// import Category from '../category';

const categorys = [
    {
        value: 'frutas',
        label: 'Frutas',
    },
    {
        value: 'verduras',
        label: 'Verduras',
    },
];

export default function ModalProducto({ update,dispatch, ProductId: { id, pName, pDescription, pStock, pStockKg, pPiecesPerKg, pUnit, pSecondaryUnit, pCategory, pSubcategory, pPrice, pWeight, pTags, pImage, pOrganic }, Categories: { category } }) {
    const db = getFirestore();
    const storage = getStorage();
    const [Imagen, setImagen] = useState(false);
    const [pname, setName] = useState(pName);
    const [pdescription, setDescription] = useState(pDescription);
    const [pstock, setStock] = useState(pStock);
    const [pstockKg, setStockKg] = useState(pStockKg);
    const [ppiecesPerKg, setPiecesPerKg] = useState(pPiecesPerKg);
    const [punit, setUnit] = useState(pUnit);
    const [psecondaryUnit, setSecondaryUnit] = useState(pSecondaryUnit);
    const [pcategory, setCategory] = useState(pCategory);
    const [psubcategory, setSubcategory] = useState(pSubcategory);
    const [pprice, setPrice] = useState(pPrice);
    const [tags, setTags] = useState(() => {
        if (pTags?.length > 0)
            return [...pTags]
        else
            return []
    });
    const [unidad, setUnidad] = useState(pUnit ? pUnit : '')
    const [tagItem, setTagItem] = useState("");
    const [weight, setWeight] = useState(pWeight ? pWeight : 0);
    const [loading, setLoading] = React.useState(false);
    const name = useRef('');
    const su1 = useRef('');
    const su2 = useRef('');
    const description = useRef('');
    const scategory = useRef('');
    const ssubcategory = useRef('');
    const stock = useRef('');
    const stockKg = useRef('');
    const price = useRef('');
    const tag = useRef('');
    const aproxWeight = useRef('');
    const piecesPerKg = useRef('');
    const [url, setUrl] = useState('https://images.unsplash.com/photo-1551963831-b3b1ca40c98e')
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState('');
    // Create the file metadata
    /** @type {any} */
    const metadata = {
        contentType: 'image/jpeg'
    };
    const [checked, setChecked] = useState(pOrganic? pOrganic:false)
    const AllfrescoSwitch = styled((props) => (
        <Switch 
        inputProps={{ 'aria-label': 'controlled' }}
        checked={checked}
        onChange={handleCheck}
        focusVisibleClassName=".Mui-focusVisible" 
        disableRipple {...props} 
        />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#65C466' : '#B6D33C',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#43ABAE',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));
      const handleCheck = (e) =>{
        // console.log("switch ", e.target.checked)
        setChecked(e.target.checked)
        // setChecked(!checked)
      }

    //OBTENIENDO LA IMAGEN
    const changeImagen = e => {
        setImagen(e.target.files[0]);
    }
    const [categoryValue, setCategoryValue] = React.useState(pCategory.length > 0 ? pCategory : "");

    const handleChange = (event) => {
        setCategoryValue(event.target.value);
    };
    const handleUnidad = (e) => {
        setUnidad(e.target.value)
    }
    const renderImage = () => {
        if (pImage?.length > 0) {
            return <Box sx={{
                backgroundImage: `url(${Imagen? URL.createObjectURL(Imagen) :pImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: "300px",
                height: "20vh",
            }}
            marginBottom={2}
            ></Box>
        }
        return ''
    }

    //FUNCION PARA GUARDAR LA IMAGEN EN FIREBASE
    const uploadImage = async () => {
        try {
            setLoading(true)
            // Upload file and metadata to the object 'images/mountains.jpg'
            const storageRef = ref(storage, 'images/' + Imagen.name);
            const uploadTask = uploadBytesResumable(storageRef, Imagen, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            console.log("No tiene acceso");
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            console.log("Subida cancelada");
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            console.log("Error desconocido");
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                    setOpen(true)
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        setUrl(downloadURL);
                        addProduct(downloadURL);
                        setIsDialogOpen(true);
                    });
                }
            );
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    const addProduct = async (imgUrl) => {
        try {
            var data = {};
            var uid = uuidv4();
            if (id !== "") {
                uid = id;
                console.log("Edición de producto");
            }
            await setDoc(doc(db, "product", scategory.current.value, "inventario", uid), {
                id: uid,
                primaryUnit: su1.current.value,
                // secondaryUnit: su2.current.value,
                name: name.current.value,
                image: imgUrl,
                price: Number(price.current.value),
                description: description.current.value,
                alarm: stock.current.value,
                stock: Number(stock.current.value),
                stockKg: weight * stock,
                link: '/' + scategory.current.value + "/" + uid,
                piecesPerKg: 3,
                subcategory: "Sin Categoria",
                tags: [...tags],
                weight: Number(weight),
                organico: checked,
                createdAt: serverTimestamp()
            });

        } catch (e) {
            console.error("Error adding document: ", e);
        }
        setLoading(false);
    }
    const handleUpdate = async () => {
        if (Imagen) {
            console.log({ Imagen })
            try {
                setLoading(true)
                // Upload file and metadata to the object 'images/mountains.jpg'
                const storageRef = ref(storage, 'images/' + Imagen.name);
                const uploadTask = uploadBytesResumable(storageRef, Imagen, metadata);

                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                console.log("No tiene acceso");
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                console.log("Subida cancelada");
                                // User canceled the upload
                                break;

                            // ...

                            case 'storage/unknown':
                                console.log("Error desconocido");
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                        }
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log('File available at', downloadURL);
                            setUrl(downloadURL);
                            editProduct(downloadURL);
                            setIsDialogOpen(true);
                        });
                    }
                );
                // await refetchProducts();
                document.getElementById("TableProducts").style.display = "block";
                document.getElementById("newProduct").style.display = "none";
            } catch (error) {
                setLoading(false);
                setOpen(true)
                console.log(error);
            }
        }
        else {
            console.log("No imagen, id")
            console.log(id)
            setMessage("edito")
            setUrl(pImage);
            editProduct(pImage);
            setIsDialogOpen(true);
        }
        // e.preventDefault()

    }
    const editProduct = async (idImage) => {
        const taskDocRef = doc(db, "product", categoryValue, "inventario", id)
        try {
            const productEdited = {
                id: id,
                primaryUnit: su1.current.value,
                // secondaryUnit: su2.current.value,
                name: name.current.value,
                image: idImage,
                price: Number(price.current.value),
                description: description.current.value,
                alarm: stock.current.value,
                stock: Number(stock.current.value),
                stockKg: weight * stock,
                link: '/' + scategory.current.value + "/" + id,
                piecesPerKg: 3,
                subcategory: "Sin Categoria",
                tags: [...tags],
                weight: Number(weight),
                // lastUpdated:{
                //     date:new Date(),
                //     price:12,
                //     weight:2
                // }
            }
            if(price.current.value !== pPrice) {
                productEdited.lastUpdated={
                    date: new Date(),
                    price: pPrice,
                }
                if(pUnit==='grs'){
                    productEdited.lastUpdated.weight= weight
                }
            }

            if(pCategory===categoryValue){
                await updateDoc(taskDocRef, productEdited)
            } else {
                const newRef = doc(db, "product", pCategory,'inventario', id)
                await setDoc(taskDocRef, productEdited)
                await deleteDoc(newRef)
            }
            // window.location.reload()
        } catch (err) {
            alert(err)
        }
    }
    const handleBack = () => {
        document.getElementById("TableProducts").style.display = "block";
        document.getElementById("newProduct").style.display = "none";
    }
    const [open, setOpen] = useState(false);
    const validate = () => {
        try {
            if (pname == "") {
                createError('El producto debe tener un nombre')
            }
            if (categoryValue == "") {
                createError("Seleccione una categoria")
            }
            if (unidad == "") {
                createError('Seleccione la unidad')
            }
            if (tags.length <= 0) {
                createError('El producto debe tener al menos una etiqueta')
            }
            if (pstock <= 0) {
                createError('La cantidad del stock no puede ser 0')
            }
            if (pprice <= 0) {
                createError('El precio debe ser mayor a cero')
            }
            if (pdescription == "") {
                createError('Añada una descripcion al producto')
            }
            if (unidad==='grs') {
                if(weight <= 0){
                    createError("Añada un peso aproximado al producto")
                }
            }
            if (!Imagen) {
                createError("Seleccione la imagen del producto")
            }
            uploadImage()
        } catch (error) {
            // createError("Error al guardar ", error.message)
            console.log(error)
        }
    }
    const deleteChip = (chip)=> {
        const index = tags.indexOf(chip)
        let newArray = [...tags]
        newArray.splice(index, 1)
        console.log(index)
        console.log(chip)
        console.log(newArray)
        setTags([...newArray])
    }

    const createError = (erms)=> {
        setOpen(true)
        setErrorMessage(erms)
        throw new Error(erms)
    }

    const AddIcon = ()=>(
        <svg  width="57.84" height="63.108" viewBox="0 0 57.84 63.108">
            <path id="Trazado_1950" data-name="Trazado 1950" d="M90.421,70.7h-23.5V96.49H56.078V70.7H32.58V59.317h23.5V33.382H66.923V59.317h23.5Z" transform="translate(-32.58 -33.382)" fill="#cbcbcb"/>
        </svg>
      )

    const EditIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="71.018" height="66.284" viewBox="0 0 71.018 66.284">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectángulo_1038" data-name="Rectángulo 1038" width="71.018" height="66.284" transform="translate(0 0)" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectángulo_1320" data-name="Rectángulo 1320" width="8.269" height="36.29" fill="#fff"/>
    </clipPath>
  </defs>
  <g id="Grupo_1886" data-name="Grupo 1886" transform="translate(-377 -503)">
    <g id="Grupo_1883" data-name="Grupo 1883" transform="translate(-6 -8)">
      <g id="Grupo_1257" data-name="Grupo 1257" transform="translate(383 511)" clipPath="url(#clip-path)">
        <path id="Trazado_1760" data-name="Trazado 1760" d="M59.422,29.711A29.711,29.711,0,1,1,29.711,0,29.711,29.711,0,0,1,59.422,29.711" transform="translate(5.842 3.46)" fill="#b6d33c"/>
      </g>
    </g>
    <g id="Grupo_1885" data-name="Grupo 1885" transform="translate(422.661 521) rotate(45)">
      <g id="Grupo_1884" data-name="Grupo 1884" clipPath="url(#clip-path-2)">
        <path id="Trazado_2006" data-name="Trazado 2006" d="M.453,44.572H7.926a.343.343,0,0,1,.343.343V68.24a1.268,1.268,0,0,1-.371.9l-3.8,3.8L.371,69.211a1.268,1.268,0,0,1-.371-.9L.111,44.913a.343.343,0,0,1,.343-.341" transform="translate(0 -36.647)" fill="#fff"/>
        <path id="Trazado_2007" data-name="Trazado 2007" d="M8.372,5.334H.753a.28.28,0,0,1-.28-.28V1.43A1.431,1.431,0,0,1,1.9,0H7.221a1.43,1.43,0,0,1,1.43,1.43V5.054a.28.28,0,0,1-.28.28" transform="translate(-0.389)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>

    )

    return (
        <Grid container spacing={2} id="newProduct" sx={{ display: 'flex' }}>
            <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen} >
                <Box m={1} p={1} alignItems="center" display="flex" flexDirection="column" >
                    <Box sx={{
                        backgroundImage: `url(${url})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: "200px",
                        height: "200px",
                        boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }} >
                    </Box>
                    {/* <Typography>{url}</Typography> */}
                    <Typography marginY={2} variant='h6'>{pname} se {message===""?"añadio":message} correctamente!</Typography>
                    <Button onClick={() => {
                        // window.location.reload()
                        update()
                        setIsDialogOpen(false);
                        handleBack()
                        }} > <span  className="icon-Check-allfresco"></span> </Button>
                </Box>
            </Dialog>
            <Grid item xs={12} >
                <Button onClick={() => handleBack()} > <span style={{ color: "#707070" }} className="icon-Atras-mvil-allfresco"></span> </Button>
            </Grid>
            <Grid item xs={12}>
                <input type="file" required name="imagen" onChange={changeImagen} id='input-image' style={{display:'none'}} />
                <Typography>{`${pImage?.length>0?"Editar":"Añadir"} imagen`}</Typography>
                {renderImage()}
                <label htmlFor='input-image' >
                    {
                        pImage?.length>0
                        ? <IconButton component='span' style={{position:"relative", top:"-60px", marginLeft:"220px"}}  >
                            <EditIcon/>
                        </IconButton>
                        : <Button component='span' sx={{
                            backgroundImage:`url(${Imagen?URL.createObjectURL(Imagen):""})`,
                            backgroundSize:'cover',
                            backgroundPosition:"center",
                            backgroundRepeat:'no-repeat',
                            backgroundColor:"#f3f3f3", 
                            width:"250px", 
                            height:"250px", 
                            borderRadius:"10px"}} >
                            <AddIcon />
                        </Button>
                    }
                    
                </label>
            </Grid>
            <Grid item xs={6}>
                <TextField id="name" inputRef={name} required
                    value={pname}
                    onChange={(e) => setName(e.target.value)}
                    label="Nombre del producto" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="category">Categoría</InputLabel>
                    <Select
                        labelId="category"
                        id="scategory"
                        inputRef={scategory}
                        label="Categoría"
                        value={categoryValue}
                        onChange={handleChange}
                    >
                        {category.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="u1">Unidad</InputLabel>
                    <Select
                        labelId="u1"
                        id="su1"
                        inputRef={su1}
                        label="Unidad"
                        value={unidad}
                        onChange={handleUnidad}
                        required
                    >
                        <MenuItem value={"grs"}>Kg</MenuItem>
                        <MenuItem value={"Pza"}>Pza</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <TextField id="tags" inputRef={tag} required onChange={(e) => setTagItem(e.target.value)} value={tagItem} onKeyPress={(e) => {
                    if (e.key == "Enter") {
                        setTags([...tags, tagItem])
                        setTagItem("")
                    }
                }} label="Etiquetas" variant="outlined" fullWidth />
                <Box>{tags.map((tag) => 
                    <Chip label={tag.toString()}  onDelete={()=>deleteChip(tag)} sx={{marginTop:"3px"}} key={tag} >
                        tag.toString()
                    </Chip>
                )}</Box>
            </Grid>
            <Grid item xs={6}>
                <TextField id="stock" type="number" required InputProps={{ startAdornment: <InputAdornment position="start"> {unidad=="grs"?"Kg":"Pza"} </InputAdornment> }}
                    inputRef={stock} value={pstock} onChange={(e) => setStock(e.target.value)} label="Cantidad en inventario" variant="outlined" fullWidth 
                />
                {/* <Grid item xs={6}> */}
                <TextField id="price" type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    inputRef={price} required value={pprice} onChange={(e) => setPrice(e.target.value)} label={"Precio por " + (unidad === "Pza" ? unidad : "Kg") + ": $$"} variant="outlined" fullWidth sx={{ marginTop: "10px" }}
                />
                {/* </Grid> */}
            </Grid>
            <Grid item xs={6}>
                <TextField id="description" required inputRef={description} value={pdescription} onChange={(e) => setDescription(e.target.value)} label="Descripción" variant="outlined" fullWidth multiline rows={4} />
            </Grid>
            <Grid item xs={6} >
                <TextField sx={{ display: unidad === "Pza" ? "none" : "block" }} id="weight" type="number" required inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} inputRef={aproxWeight} value={weight} onChange={(e) => setWeight(e.target.value)} 
                label="Peso aproximado en grs" variant="outlined" fullWidth
                helperText={weight>0?"aprox. "+(1000/weight).toFixed(0)+" pz por Kg":""}
                />
            </Grid>
            <Grid item xs={6}>

                <span>Producto organico: </span> <AllfrescoSwitch />
            </Grid>
            <Grid item xs={3}>
                {
                    pImage !== undefined ? (<LoadingButton
                        size="small"
                        onClick={
                            handleUpdate
                            // ()=>{
                            //     console.log("original ", pCategory)
                            //     console.log("edit ",categoryValue)
                            // }
                        }
                        // endIcon={<SaveIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        sx={{
                            border: "3px solid #B6D33C",
                            fontWeight: "bold",
                            background: "#B6D33C",
                            '&:hover':{
                                border: "3px solid #B6D33C",
                                background: "#7e9229",
                            }
                        }}
                    >
                        Actualizar
                    </LoadingButton>) : (<LoadingButton
                        size="small"
                        onClick={validate}
                        // endIcon={<SaveIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="outlined"
                        sx={{
                            border: "3px solid #B6D33C",
                            fontWeight: "bold",
                            background: "#B6D33C",
                            color:"white",
                            '&:hover':{
                                border: "3px solid #B6D33C",
                                background: "#7e9229",
                            }
                        }}
                    >
                        <strong>Guardar</strong>
                    </LoadingButton>)
                }
            </Grid>
            <Collapse in={
                open
                // true
                }>
                <Alert
                    sx={{ backgroundColor:"none",marginY:1, padding:1}}
                    severity='error'
                >
                    <Box sx={{ width: "100%", padding:"1rem", }} >
                        <AlertTitle>Error al guardar</AlertTitle>
                        {errorMessage}
                        {/* Revise que los datos sean correctos! */}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="large"
                            onClick={()=>setOpen(false)}
                            sx={{marginLeft:"15px"}}
                        >
                            <CloseIcon fontSize="inherit"  />
                        </IconButton>
                    </Box>
                </Alert>
            </Collapse>
        </Grid>
    )
    
    const refetchProducts = async () => {
        let collectionRef = collection(db, "product");
        onSnapshot(collectionRef, (querySnapshot) => {
          querySnapshot.forEach((document) => {
            onSnapshot(collection(db, 'product', document.id, 'inventario'), (querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const info = doc.data();
                dispatch({
                  type: actionTypes.ADD_PRODUCT,
                  item: {
                    id: info.id,
                    name: info.name,
                    productType: info.productType,
                    image: info.image,
                    price: info.price,
                    description: info.description,
                    alarm: info.alarm,
                    stock: info.stock,
                    stockKg: info.stockKg,
                    link: info.link,
                    piecesPerKg: info.piecesPerKg,
                    primaryUnit: info.primaryUnit,
                    secondaryUnit: info.secondaryUnit,
                    category: document.id,
                    subcategory: info.subcategory,
                    quantity: 0,
                    unit: "Pza",
                    docId: doc.id,
                    weight: info.weight,
                    tags: info.tags,
                    defaultUnit: info.primaryUnit,
                    organico: info.organico
                  }
                });
              });
            });
          });
        });
      }
}
