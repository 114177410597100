// import TextField from '@mui/material/TextField';
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Dialog, DialogActions, IconButton, Typography } from '@mui/material';
import { collection, deleteDoc, doc, getDocs, getFirestore } from "firebase/firestore";
import { Link } from 'react-router-dom';
import AddCategory from './addCategory';


export default function Category() {
  const [categories, setCategories] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState({
    url     : "",
    nombre  : "",
    id      : ""
  })
  const db = getFirestore();
  const getCategories = async () => {
    var datos = []
    const querySnapshot = await getDocs(collection(db, "taxonomies"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //   console.log("Taxonomies");
      //   console.log(doc.data());
      console.log(doc.id, " => ", doc.data());
      let itemCategory = doc.data()
      itemCategory.id = doc.id
      datos = [...datos, itemCategory]


      //   Object.keys( doc.data().taxonomies ).forEach( ( key)=> {
      //         console.log(key)
      //         var item = doc.data().taxonomies[key]
      //         item.id = key
      //         console.log(item)
      //         datos = [...datos, item]
      //     } )

      // console.log(doc.data().orders[llave])
      // console.log(new Date(doc.data().fecha.seconds * 1000))
    });
    setCategories([...datos])
    // console.log(orders)
  }
  React.useEffect(() => {
    getCategories()
    console.log("Taxonomies")
    console.log(categories)
  }, [])

  const deleteCategory = async(idCategory) => {
    try {
      const docRef = doc(db, 'taxonomies', idCategory )
      await deleteDoc(docRef)
      
    } catch (error) {
      console.log(error)
    }
    setDeleteDialog(false)
    getCategories()
  }
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [categoryEdit, setCategoryEdit] = useState({})

  return (
    <Box
    >
      <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen} >
        <AddCategory categoryData={categoryEdit}  onClose={() => {setIsDialogOpen(false);setCategoryEdit({});getCategories()} } />
        <DialogActions>
          <Button onClick={() => {setIsDialogOpen(false);setCategoryEdit({})} } m={2} variant="outlined" >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialog} onClose={()=>setDeleteDialog(false)} >
        <Box p={2}>
          <img src={categoryToDelete.url}  height="250px"  />
          <Typography variant='h6' marginY="20px" > Esta seguro que desea eliminar {categoryToDelete.nombre} permanentemente? </Typography>
          <Button onClick={()=>deleteCategory(categoryToDelete.id)} >Eliminar</Button>
          <Button onClick={()=>setDeleteDialog(false)}  >Cancelar</Button>

        </Box>
      </Dialog>

      <Box display="flex" justifyContent="space-between" >
        <Typography variant='h2' >Categorias</Typography>
        <Button onClick={() => setIsDialogOpen(true)} variant="contained"  >Agregar Categoria</Button>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" marginTop={1}>
        {
          categories.map((item) => (
            <Box key={item.id} width={{ xs: "100%", sm: "45%", md: "30%" }} m={1} p={2} sx={{ boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px" }}>
              {/* <img  src={item.images} /> */}
              <Box sx={{
                backgroundImage: `url(${item.images})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: "100%",
                height: "20vh",
                marginBottom: "10px",
              }} >
              </Box>
                <Typography variant='h4' color="primary" >
                  <Link to={"/" + item.name} > {item.name} </Link>
                </Typography>
                <Typography variant='body1' >
                  {item.description} 
                </Typography>
                <IconButton onClick={()=>{
                  setDeleteDialog(true)
                  setCategoryToDelete({
                    id: item.id,
                    nombre: item.name,
                    url: item.images
                  })
                }} >
                  <span className='icon-eliminar-allfresco'  ></span>
                </IconButton>
                <IconButton onClick={()=>{
                  setCategoryEdit(item)
                  setIsDialogOpen(true)
                  // console.log(item)
                }}>
                  <span className='icon-editar-allfresco'  ></span>
                </IconButton>
            </Box>
          ))
        }
      </Box>
    </Box>
  );
}
