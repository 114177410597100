import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
      with:30
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Ecelente',
    },
  },
};



export function HorizontalBarChart({arrayData}) {

  let excelent=0;
  let good =0;
  let bad =0;
  arrayData.map( d => {
    if(d==5 || d==4)
      excelent++;
    if(d==3)
      good++;
      if(d==2 || d==1)
        bad++;
  } )

  const labels = ['Excelente', 'Regular', 'Mala',];

 const data = {
  labels,
  datasets: [
    {
      label: 'Excelente',
      data: [excelent,0,0],
      borderColor: 'rgb(255, 255, 255)',
      backgroundColor: '#B6D33C',
    },
    {
      label: 'Bueno',
      data: [0, good ,0,],
      borderColor: 'rgb(255, 255, 255)',
      backgroundColor: '#43ABAE',
    },
    {
      label: 'Malo',
      data: [0,0,bad],
      borderColor:'rgb(255,255,255)',
      backgroundColor: '#FF6700'
    }
  ],
};

  return <Bar options={options} data={data} />;
}
