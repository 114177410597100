import { Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {
  return (
    <main className='contenido' style={{padding:"1rem"}} >
      <Typography variant='h1' fontSize={{xs:"2rem",md:"3rem"}} marginBottom={6} marginTop={3} >Términos y Condiciones Allfresco Delivery</Typography>
      {/* <Typography marginY={2} >Términos y condiciones de uso de sitio web</Typography> */}
      <Typography marginTop={2}>1. Introducción: </Typography>
      <Typography marginY={2}>Estimado Cliente, Bienvenido al sitio web de compras en línea de <Link to="https://allfresco.mx" color='primary' ><strong>https://allfresco.mx</strong></Link> , por favor revise los siguientes términos y condiciones que rigen este sitio para que su experiencia sea como lo espera, al favorecernos con tu compra significa que ha leído y aceptado expresamente en su totalidad los Términos y Condiciones del mismo. </Typography>
    <Typography marginY={2} >Los Términos de Uso se actualizaron por última vez el 30 de abril del 2023. Por favor revise periódicamente nuestros Términos de Uso para cualquier cambio.</Typography>
    <Typography marginY={2}>Nuestros productos y servicios de este sitio web, se rigen bajo lo establecido por la NMX-COE-001-SCFI-2018, Comercio Electrónico – disposiciones a las que se sujetarán aquellas personas que ofrezcan, comercialicen o vendan bienes, productos o servicios mediante el uso de medios electrónicos.</Typography>
    <Typography marginY={2} >Allfresco delivery ha desarrollado la tienda en línea para que cualquier Usuario que se encuentren en el territorio de los Estados Unidos Mexicanos (México), pueda adquirir productos contemplados en nuestro catálogo de productos en línea y solicitar que dichos productos sean entregados en su domicilio localizado en el estado de Puebla, en el sitio Web se indica la cobertura de entrega en códigos postales. El uso de este sitio Web se rige por la legislación de los Estados Unidos Mexicanos. Si Usted usa este sitio desde otro país, Usted estará utilizando nuestro sitio Web bajo su responsabilidad, debe cumplir con las leyes mexicanas y aquellas que se encuentran en vigor en la jurisdicción en la que Usted se ubique.</Typography>
    <Typography marginY={2} >Los presentes Términos y Condiciones aplica a todas las personas que utilizan "Tienda en Línea” o "Sitio Web " en la dirección electrónica <Link to='https://alfresco.mx' ><strong>https://alfresco.mx</strong></Link>, en la aplicación móvil para teléfonos android e ios y cualquier portal que utilice Allfresco delivery para prestar sus servicios. </Typography>
    <Typography marginY={2} > Estos Términos y Condiciones constituyen las condiciones generales de contratación y conjuntamente con las confirmaciones de compra correspondientes y los demás Documentos Contractuales del Sitio, rigen la relación que existe entre Allfresco delivery delivert operado por <em>Fresh Up Huertos del Campo S. de R.L. de C.V</em> ("Allfresco delivery", o "Nosotros") y las personas que visitan el sitio Web, consultan o utilizan su contenido (los "Usuarios") que adquieren mercancías a través de medios electrónicos.</Typography>
    
    <p>
    <br/>
    <br/>
      <strong>
      Declaraciones:

      </strong>
      <br/>
      <br/>

Allfresco delivery: declara que es una sociedad mercantil legalmente constituida conforme a las leyes de los Estados Unidos Mexicanos; operada por Fresh Up Huertos del Campo S. de R.L. de C.V. Allfresco delivery ha desarrollado su Tienda en Línea con el propósito de que los Usuarios puedan adquirir a través de medios electrónicos los productos que se muestran en el catálogo de productos en línea de nuestro sitio Web , en los términos que las partes acuerden en la confirmación de compra.
<br/>
<br/>
Los Usuarios de Allfresco delivery: declaran, por su propio derecho: ser personas físicas, mayores de 18 años de edad y contar con plena capacidad jurídica para poder obligarse en términos del presente contrato: tener sus domicilios ubicados, para efectos del presente documento, en las direcciones que han proporcionado a Allfresco delivery en los registros de sus cuentas en línea; les permitirá adquirir productos en línea; que se encuentran conscientes de que todas las transacciones que sean realizadas en el sitio Web  por sí mismos se encuentren asociadas al uso de sus contraseñas y/o de sus firmas electrónicas, serán adjudicables a los Usuarios de sus cuentas.
<br/>
<br/>
<strong>
Definiciones:
</strong>
<br/>
<br/>     
Para los propósitos del presente documento aplicarán los términos y definiciones siguientes, los cuales tendrán el significado que se les define; lo anterior, ya sea que se utilicen de forma abreviada, con mayúsculas o minúsculas, en singular o plural.
<br/>
Alimentos perecederos, Son los alimentos que puede experimentar alteraciones en un tiempo determinado, entre los productos perecederos se encuentran los lácteos, carnes, aves, pescado, embutidos, frutas y verduras, etc. Estos exigen condiciones especiales para retardar su deterioro y así preservar su vida útil, ya que tienen una duración corta y se descomponen rápidamente cuando no se controlan los factores como la luz, humedad, temperatura y sequedad. 
<br/>
<br/>
Bolsa Virtual, es una aplicación integrada en los comercios electrónicos, se despliega en nuestro sitio Web que representa al carrito de compra virtual, permitirá al Usuario verificar los Productos que ha seleccionado para realizar su adquisición, desempeñando un papel fundamental en la última etapa del ciclo de compra de productos.
<br/>
<br/>
Catálogo de productos en Línea, se muestra en categorías el cual se clasifica y ordena en grupos con características comunes, conformado por todos los productos disponibles que despliega el catálogo de nuestro sitio Web  de los diferentes departamentos tales como frutas, verduras,  lácteos,  carnes, pescados - mariscos, embutidos, panadería, tortillería , abarrotes, en cualquier  presentación, frescos, en  bebidas , semiprocesados, procesados,  envasados, enlatados, refrigerados, congelados, empacados, alimento para mascotas, artículos para el cuidado personal y del hogar, los antes mencionados son de manera enunciativa más no limitativa. Los productos disponibles serán los que despliegue cada catálogo según corresponda a la categoría.
<br/>
<br/>
Cupón Promocional, es un conjunto de letras o números o la combinación de ambos para fines de compra con el que se consiguen descuentos en el precio de un producto, estos se presentarán en la plataforma de nuestro sitio Web conforme a las bases aplicables correspondientes.
<br/>
Importe de envío, es la tarifa de transporte que refiere al coste de enviar los productos a domicilio. El importe de envío es la que la empresa cobra a los Usuarios además del costo de los productos que solicitan. El costo de las tarifas de envío se agrega al pedido de un cliente al momento del pago.
<br/>
<br/>
Pedido, es la orden de compra generada por el Usuario quien ha tomado la decisión en firme de comprar los productos para adquirirlos en los términos y condiciones conforme a lo que despliega nuestro sitio Web  en cantidad y precio, la cual se realiza mediante una transacción de acuerdo comercial que se lleva a cabo entre dos partes, en un convenio de compra y venta que implica un intercambio de productos a cambio del pago de una cantidad, dichos productos Allfresco delivery preparará para que se entregue en el domicilio del Usuario.
<br/>
Precio, Es la expresión de valor que tienen los productos, manifestado en términos monetarios, estos serán desplegados en la plataforma para cada producto correspondiente a la unidad de medida, los cuales para los que implica impuestos tales como IVA ( Impuesto al Valor Agregado) o IEPS (Impuesto Especial sobre Producción y Servicios) los precios que se desplieguen ya estarán incluidos u otros impuestos aplicables, estos importes estarán desglosados en el ticket y en la factura correspondientes. En la bolsa virtual contendrá precios basados en un peso aproximado, una vez que se haya realizado la preparación de tu pedido se confirmará el peso exacto con el cual se obtendrá el importe final en el ticket que es el monto total a pagar.
<br/>
<br/>
Ticket, es el comprobante virtual que se emite en operaciones de compra realizadas con Usuarios finales, los importes serán emitidos en moneda nacional, (Peso mexicano, “MXN”), el cual contendrá desglosado por producto el precio total, descuentos que pudieran ser aplicables, importe de envío e impuestos a ser pagados. 
<br/>
Usuario, refiere a las personas físicas que utilizan nuestro sitio Web y usan nuestra plataforma en cualquier dispositivo. 
<br/>
Vida útil de un alimento, es el tiempo que transcurre entre la producción o envasado del mismo y el punto en el que se pierden sus cualidades fisicoquímicas y/u organolépticas, y deja de ser seguro para el consumidor; es decir, el tiempo que transcurre desde su elaboración hasta su deterioro.
<br/>
<br/>
<strong>
Uso del sitio:

</strong>
<br/>
<br/>

El contenido de este sitio Web  está dirigido a personas que tengan mayoría de edad, de conformidad con las diversas legislaciones vigentes y aplicables te informamos que únicamente solicitarán la habilitación de una Cuenta de Usuario las personas de 18 años cumplidos o más, por consiguiente queda bajo la responsabilidad de los padres y/o tutores supervisar el ingreso a este sitio de menores de edad o personas que su capacidad contractual está limitada por la legislación aplicable, en caso de que una persona menor de edad se registre en la plataforma, las personas que tengan la tutela, la patria potestad, o la supervisión del menor serán solidariamente responsables al margen de las herramientas y opciones contenidas en el sitio Web  de conformidad con el Código de Ética en materia de comercio electrónico emitido por la Procuraduría Federal del Consumidor.
<br/>
Cualquier persona puede visitar y consultar nuestro sitio Web, si desean adquirir productos deberán registrarse en nuestra plataforma. Solo podrán realizar compras de artículos en línea los Usuarios que estén registrados. 
https://alfresco.mx, realizará la restricción o cancelación de tu cuenta si se detecta algún uso inapropiado del sitio, se reserva el derecho de dar por terminado si Usted incumple con cualquiera de las cláusulas establecidas en el Sitio, si estas acciones resultan una violación a las leyes, Allfresco delivery se reserva su derecho de ejercer en contra de Usted cualquier acción de carácter civil, penal, mercantil, según corresponda al derecho, se procederá para defender los derechos de Allfresco delivery. Usos inapropiados como los que a continuación se mencionan de manera enunciativa, más no limitativa:
<br/>
Replicar, imitar, copiar, para su uso en servidores espejo, descargar, reproducir, distribuir, publicar, mostrar o transmitir cualquier contenido del Sitio, también incluye nuestras marcas registradas, en cualquier medio por cualquier forma; esta restricción incluye, de manera enunciativa mas no limitativa a los medios de; fotocopiado, electrónicos, mecánicos, grabación o cualquier otro medio. 
<br/>
Emplear herramientas, mecanismos, tecnologías o cualquier otra metodología cuyo fin sea hacer una obtención, extracción o recopilación, indirecta o directa de los contenidos de nuestro Sitio, o de cualquier información o datos personales relacionados con el sitio Web de Allfresco delivery.
<br/>
Usar nuestro sitio web para revender productos o utilizarlo para cualquier otro uso comercial de los contenidos del sitio Web. 
<br/>
Emplear o compilar para fines distintos a aquellos destinados por nuestro sitio Web las descripciones y / o listas de nuestros productos.
<br/>
<br/>
<strong>
Acceder a datos no destinados al Usuario.
</strong>
<br/>
<br/>
Interferir con el servicio a cualquier otro Usuario, mediante el envío de spam, virus, sobrecargarlos, correos al Sitio, u otra forma de intención de interferencia, así mismo se restringe también el envío de correos con el contenido de propaganda, publicidad y/o promociones de cualquier servicios o producto.
<br/>
Realizar una adaptación, modificación, des compilación o transformación de cualquier formato o bien el programa de cómputo del sitio Web, Usted únicamente está autorizado para utilizar el código HTML, este código HTML no se autoriza para su uso de un tercero.
<br/>
Intentar o llevar a cabo actividades contrarias a la verdad que perjudiquen a Allfresco delivery tales como la falsificación de formas de pago, identidades u otras acciones fraudulentas.
<br/>
Llevar a cabo cambios a nuestro sitio Web, incluyendo el contenido. 
<br/>
Iniciar sesión en una cuenta en la que el Usuario no esté autorizado para su acceso, o bien ingresar a datos no destinados al Usuario. 
<br/>
La información de registro, así como la información de facturación proporcionada debe ser verídica. 
<br/>
Nuestro sitio Web sólo puede ser utilizado para adquirir productos que vayan a ser entregados en México en el estado de Puebla en los códigos postales habilitados en la plataforma. 
<br/>
Únicamente se podrán realizar pedidos en nuestra aplicación, no se tiene convenio con ningunas otras aplicaciones. 
<br/>
Si usted tiene una duda o requiere una aclaración con respecto a los Términos y Condiciones o bien al uso de este sitio por favor contactarnos al correo:  <a href='ayuda@allfresco.mx'  >ayuda@allfresco.mx</a>, whatsapp. 221 267 2588.
<br/>
<br/>
<strong>
Compraventa de productos:
</strong>
<br/>
<br/>

Usted únicamente podrá realizar transacciones si ha ingresado a nuestro sitio web mediante el uso de su correo electrónico, el cual nos permite identificarlo en el ámbito digital para las compraventas que realiza en la Tienda de Allfresco delivery en Línea.
<br/>
Los Usuarios previo a realizar la transacción, podrán revisar las especificaciones, características, precios, monto de envío, métodos de pago, así como los términos y condiciones. En caso de que Usted tenga alguna duda respecto a los productos que desea adquirir, favor de contactar a Allfresco delivery previo a realizar su pedido, nuestra Tienda en Línea le permite verificar los productos que ha elegido comprar, las cantidades, el domicilio en el cual se le realizará la entrega, el precio total a ser pagado y los impuestos según correspondan a la compraventa.
<br/>
La compraventa es obligatoria para ambas partes en el momento en que Usted realiza la confirmación de la Compra en nuestro sitio Web.
<br/>
<br/>
<strong>
Formas de pago
</strong>
<br/>
<br/>

Estimado Usuario para llevar a cabo con éxito tu pago en línea, antes de poder completar su Orden de Compra, Usted podrá elegir cualquier modalidad de pago electrónico aceptada en nuestra Plataforma tales como: tarjetas de débito y crédito (tarjetas de crédito Visa, MasterCard, American Express, entre otras.
  <br/>
Cuando hayas realizado tu compra, te enviaremos un correo electrónico que mostrará en detalle su pedido, la finalidad del correo será informativo, no significa que el pago ha sido autorizado o procesado o el banco o el medio de pago de tu elección, una vez que el pago es autenticado, recibirás otro correo electrónico con la confirmación de tu compra.
<br/>
Para que Usted pueda realizar los pagos en línea, previo deberá haber proporcionado a través del sitio Web el domicilio asociado a su tarjeta y los datos asociados a su tarjeta de crédito, el nombre del titular, número de tarjeta, fecha de vencimiento y datos adicionales que se soliciten de la tarjeta. Se informa que nosotros hemos contratado para realizar el procesamiento de pagos a una empresa para asegurar que su pago en línea se realice de manera confiable y segura, por lo tanto, se informa que Allfresco delivery no almacena la información de su tarjeta; estos datos son obtenidos directamente del Usuario, a través de un apartado protegido de altos niveles de seguridad, el procesador de pagos solicitará la autorización de su compra al banco emisor. 
<br/>
Una vez autorizado el cargo por tu banco se generará una retención de saldo en tu cuenta, en caso de existir una diferencia a su favor, el saldo retenido por dicha diferencia será liberado por su banco en el tiempo que éste último determine. Allfresco delivery no puede liberar ni pagar saldos a favor del cliente. En ocasiones algunos bancos pueden tardar hasta un mes en liberar los saldos, para estos casos, es recomendable que acuda a su banco para acelerar tu trámite de liberación, las liberaciones de saldos pueden variar conforme a los tiempos de cada Banco.
<br/>
Cuando se realiza una compra de productos que incluyan gramaje, estos tendrán una variación en peso y monto final reflejado en tu carrito de compra, el cargo final será el que aparezca en el ticket de compra generado al final de la compra cuando ya se hayan verificado los pesos finales de los productos será el monto final que se ejecutará en cobro, en caso de existir un saldo a favor derivado a estas correcciones en peso- precio, se realizará el reembolso a su cuenta.
<br/>
<br/>
<strong>
 Disponibilidad de productos contenidos en nuestro catálogo:
</strong>
<br/>
<br/>

Los productos que se muestran en el sitio Web forman parte de nuestro Catálogo de productos en Línea. Los productos que les de la opción de elegir están disponibles, es probable que se agoten de momento a momento, por lo tanto, se habilitará la opción de elegirlo en caso de que no haya disponibilidad.
<br/>
Las fotografías o imágenes que se muestran de los productos de nuestro sitio web son con fines ilustrativos para orientación de su compra, los colores que ves depende de tu monitor, los productos recibidos pueden tener variaciones con respecto a estas imágenes. Las especificaciones y /o características de nuestros productos pueden cambiar sin previa notificación.
<br/>
<br/>
<strong>
Formas de entrega y Tiempos de entrega:
</strong>
<br/>
<br/>

La entrega de los productos se realizará al usuario o bien usted puede designar a personas autorizadas para recoger el pedido con identificación oficial (credencial para votar, licencia, pasaporte). El máximo tiempo de espera para la entrega de su pedido será de cinco minutos, cortados a partir de que el repartidor anuncie su llegada al domicilio. Los Usuarios o personas autorizadas para recibir los pedidos deberán de verificar conforme al ticket que se hayan entregado todos los Productos conforme a lo solicitado en su pedido en caso de existir diferencias a lo solicitado registrar una nota al reverso del ticket y firmarla. Para confirmar la entrega de los productos deberá firmar el ticket, en caso que el Usuario o quien reciba el pedido no firmen se entenderán como entregados completos los productos del Pedido. Si usted tiene una duda o requiere una aclaración con respecto a su pedido por favor contactarnos al correo: ayuda@allfresco.mx , whatsapp. 221 267 2588.
<br/>
<br/>
<strong>
Formas de entrega:
</strong>
<br/>
<br/>
La entrega la realizamos con nuestras unidades de distribución o bien con unidades de distribución -asociada, en ambas modalidades Nosotros estamos obligados a entregarte integró tu pedido con calidad.
El domicilio de entrega deberá ser establecido por el usuario, podrá agregarla al Pedido siempre y cuando se haya verificado en códigos postales la cobertura de entrega. En caso de que no se haya establecido en nota, se tomará como domicilio de entrega el que haya señalado el Usuario al momento de registrarse en la Plataforma o en su correo personal. 
Usted podrá editar la dirección de entrega al iniciar sesión desde el apartado de mi cuenta. 
<br/>
<br/>
<strong>
Tiempos de entrega:
</strong>
<br/>
<br/>
Elección de horario para entrega el mismo día.
El Usuario puede elegir esta opción de entrega el mismo día una hora después una vez confirmada la compra podrá elegir los horarios que prefiera de entrega estos se despliegan en la plataforma estarán comprendidos dentro del rango de 7:00 a.m a 9:00 p.m. de lunes a sábado. El número de artículos desde 1 en adelante con un monto mínimo de $ 300 (Trescientos pesos 00/100 m.n) en la que Allfresco delivery establece el importe de envío con I.V.A. incluido de $46.40(cuarenta y seis pesos 40/100 m.n) este costo de envío contempla únicamente el área de entrega.
<br/>
<br/>
<strong>
Elección de horario para entrega en los siguientes días. 
</strong>
<br/>
<br/>
Usted tiene la opción de elegir la entrega de su pedido en los siguientes días, en los horarios que prefiera de entrega estos se despliegan en la plataforma estarán comprendidos dentro del rango de 7:00 a.m a 9:00 p.m. de lunes a sábado, el número de artículos desde 1 en adelante con un monto mínimo de $ 300 (Trescientos pesos 00/100 m.n) en la que Allfresco delivery establece el importe de envío con I.V.A. incluido de $46.40 (cuarenta y seis pesos 40/100  m.n) este costo de envío contemplan únicamente el área de entrega.
<br/>
Allfresco delivery tendrá un tiempo de tolerancia de 30 minutos para la entrega de su pedido conforme al horario seleccionado por los Usuarios, el repartidor tocará a su puerta o al personal de vigilancia, en caso de no tener éxito en encontrarlo el repartidor se comunicará vía telefónica para localizarlo y así proceder a realizar la entrega. 
<br/>
Para el caso de que el repartidor no haya completado la entrega por situaciones atribuibles al usuario, el usuario deberá comunicarse con nosotros y reagendar nuevamente la entrega así mismo el usuario deberá pagar el gasto de envío, y un pago del del 20% del monto total de su pedido de cuota administrativa. 
<br/>
En caso de que el repartidor no realice la entrega de su pedido por causas imputable a Allfresco delivery. Nosotros realizaremos la devolución al Usuario del 100% del monto total pagado de su compra, o bien si usted lo prefiere reagendaremos la entrega. 
<br/>
El usuario es responsable del daño, deterioro o la pérdida de sus productos a partir del momento en que destinatario firma de recibido cuando se le realiza la entrega de sus productos en su domicilio. Tome en cuenta que ciertos alimentos son perecederos y requiere de condiciones especiales de conservación inmediatamente después de que reciba su pedido, de no ser así sufrirán alteraciones en su calidad. 
<br/>
Allfresco delivery no asume la responsabilidad por atrasos en la entrega o dificultad de realizar la entrega cuando los retrasos o impedimento de cumplimiento sea de algún caso fortuito, o de cualquier causa fuera del control de Allfresco delivery.
<br/>
<br/>
<strong>
Vigencia de promociones. 
</strong>
<br/>
<br/>

Vigencias de promociones- ofertas.

Las ofertas que despliega para cada producto de nuestro sitio Web se establecen por un tiempo determinado, por lo tanto, los precios aplican sólo dentro del periodo de compra que establezca nuestra página www.allfresco.mx.
<br/>
Los cupones Promocionales, tendrán la vigencia indicada en nuestra plataforma del sitio Web de www.allfresco.mx. Si esta no se especifica entonces tendrán como vigencia máxima para su utilización de hasta por una “semana” siete días naturales desde el día en que hayan emitido en nuestra plataforma. 
<br/>
<br/>
<strong>
Devoluciones, cancelaciones, cambios:
</strong>
<br/>
<br/>

Tu satisfacción es importante para nosotros y si por alguna razón usted quiere cancelar o revocar su pedido puede hacerlo dirigiéndose a Atención al Cliente,  ayuda@Allfresco delivery.mx

En todo caso puede revocar su consentimiento dentro de los 5 días hábiles siguientes a aquel en que reciba su pedido, cumpliendo con los requisitos señalados por las Leyes vigentes y aplicables de la materia.
Si deseas cancelar, requerir cambios o sustituciones de productos de tu pedido, podrás realizarlo 90 min antes del horario que elegiste de entrega comunicándose al whatsapp. 221 267 2588.
Si su compra ya se encuentra en la etapa de distribución, deberá cancelar la entrega y se cobrará nuevamente el monto de envío. Allfresco delivery dispondrá de 2 días hábiles para reponer el producto en caso de cambios o sustituciones. 
Su satisfacción es lo más importante para nosotros si decide cambiar de opinión en cuanto a tu compra de productos o no estás satisfecho, para todos los productos debes notificarnos a más tardar 24 horas después de haber recibido tu pedido:
Los productos no perecederos, te ofrecemos 72 horas después de la entrega para realizar la devolución recibiendo un reembolso total del monto pagado de la misma forma en cómo fue pagado el producto. 
Los productos perecederos tales como carnes, frutas, verduras, quesos, embutidos, panadería, pescados y mariscos, lácteos, congelados, etc. que presenten algún problema de calidad solicita la devolución. 
Para los departamentos de abarrotes te pedimos entregar la mercancía completa, cerrada para poder realizar tu devolución.
Para todos los casos antes mencionados deberás conservar el ticket y el empaque original para que puedas entregar tus productos a devolución o cambio, comunícate a Allfresco delivery, ayuda@Allfresco delivery.mx , le notificarán el proceso a seguir para realizar la devolución o cambio del producto (s), para así coordinar que nuestros repartidores pasen por dichos productos, estos serán revisados por el personal de calidad de la empresa para evaluar la devolución o cambio, así mismo te informarán del proceso para el reembolso en caso de que haya saldo a favor del Usuario. 
<br/>
<br/>
<strong>
   Licencia para el uso del sitio Web.
</strong>
<br/>
<br/>

Con referencia al presente documento de Términos y Condiciones Si Usted cumple con lo antes referido, Allfresco delivery le otorga una licencia no exclusiva, revocable, intransferible, no cedible para que Usted pueda hacer uso de nuestra plataforma en el sitio Web  www.Allfresco delivery.mx, de uso exclusivo personal.
<br/>

Conforme a la regulación aplicable usted debe hacer uso correcto y lícito de nuestro sitio Web , debe cumplir con todas las cláusulas contenidas en el presente documento de Términos y Condiciones y en los Documentos Contractuales de nuestro  sitio Web , 
proporcionar a nuestro sitio Web  información correcta, actualizada y completa.
<br/>
El Usuario no debe utilizar nuestro sitio Web  para participar en, fomentar o hacer delitos que resulten una la violación de las leyes,  suplantar una persona, propagar por medio de nuestra plataforma información, programaciones que causen daño a la plataforma sobre la que opera Allfresco delivery en línea. 
<br/>
<br/>
<strong>
Propiedad intelectual e industrial

</strong>

Los contenidos de nuestro sitio Web son propiedad de la empresa Huertos del Campo S. de R.L. de C.V. se encuentran protegidos por leyes en materia de derechos de autor y propiedad industrial.  
Marcas, diseños, logotipos, imágenes, gráficos, textos, íconos, figuras las antes mencionadas son enunciativas más no limitativas son propiedad de Huertos del Campo S. de R.L. de C.V. no deben ser usadas, distribuidas, transmitidas, copiadas o imitadas por cualquier medio o forma, el contenido del Sitio también contendrá marcas registradas, logotipos, nombres de productos, entre otros, que son propiedad de sus correspondientes dueños. Usted no debe utilizar los derechos de propiedad intelectual sin la autorización correspondiente.
<br/>
<br/>
<strong>
Limitación de responsabilidades.
</strong>
<br/>
<br/>

Allfresco delivery no asume responsabilidad por daños que pudieran ser ocasionados al Usuario debido al funcionamiento y disponibilidad del sitio Web ya que esta puede estar interrumpida, discontinua, inaccesible, tener fallas informáticas u otras situaciones que generen daños en el servicio de la plataforma en Línea. 
<br/>
<br/>
<strong>
Cesión de derechos y obligaciones.
</strong>
<br/>
<br/>

Los Usuarios no podrán ceder sus derechos y obligaciones estipulados en el presente documento de Términos y Condiciones los Usuarios conceden a Allfresco delivery la autorización para que pueda ceder a un tercero los derechos y obligaciones que derivan de estos Términos y Condiciones.
<br/>
<br/>
<strong>
Modificaciones
</strong>
<br/>
<br/>

Los presentes Términos y Condiciones podrán ser modificado por Allfresco delivery, se realizará la publicación de la nueva versión del documento en nuestro sitio Web, el cual contendrá la fecha en que fue realizada la última actualización, esta entrará en vigor posterior a los 30 días naturales contados a partir de la fecha en que sea publicada la versión que se actualizó. 
Al utilizar nuestro sitio Web posterior a la entrada en vigor de nuestros Términos y Condiciones, estará manifestando su consentimiento con respecto al contenido del mismo, así mismos si Usted esta en desacuerdo con las nuevas cláusulas del Sitio, favor de no usar el sitio Web.
<br/>
<br/>
<strong>
Facturación:
</strong>
<br/>
<br/>


Cuando se haya confirmado la compra, Usted tiene la opción de solicitar su factura a ayuda@allfresco.mx.
<br/>
<br/>
<strong>
Atención y soporte (dudas, quejas, sugerencias y comentarios).

</strong>
<br/>
<br/>

Queremos que tu tienda en línea con Allfresco delivery sea de tu entera satisfacción, trabajaremos en la mejora continua para entregarte los productos y servicios con calidad, es muy importante nos hagas saber tus dudas, quejas, sugerencias y comentarios, a través del teléfono y dirección: ayuda@allfresco.mx, whatsapp. 221 267 2588.
<br/>
<br/>
<strong>
Legislación aplicable- jurisdicción.
</strong>
<br/>
<br/>
Para todo lo relacionado con la interpretación, cumplimiento y ejecución del presente contrato, las partes expresamente se someten a la jurisdicción de los tribunales de la Ciudad de México, Distrito Federal, por lo que renuncian a cualquier otra que les pudiera corresponder por razón de sus domicilios o cualquier otra causa, en el presente o en el futuro, así mismo las partes están de acuerdo en someterse a las leyes de los Estados Unidos Mexicanos.

    </p>
    </main>
  )
}

export default TermsAndConditions