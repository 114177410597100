import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box } from '@mui/material'
import React, { useState } from 'react'
import { useStateValue } from '../../StateProvider'

const DetailsCoupon = () => {

  const [{ basket, coupon, discount }, dispatch] = useStateValue();
  const [open, setOpen] = useState(false)
  console.log("Cupon ", coupon)
  if (coupon === null)
    return <span>No hay nada</span>

  let arrayproducts = []
  let total =0;
  if (coupon.applyFor === "All") {
    arrayproducts = basket
  }else  if (coupon.applyFor.length > 0) {
    console.log("aqui")
    coupon.applyFor.map(c => {
      basket.map(p => {
        // if(coupon.type==="percentaje")
          // discount += (p.price)
        if (p.category === c){
          total += Number( p.primaryUnit ==='Pza'
                    ? p.price* p.quantity
                    : p.unit==="grs"
                    ? p.price * Number(p.quantityTotal)
                    : p.priceTotal 
          )
          console.log(p.name, total)
          arrayproducts.push(p)
        }
      })
    })
  }
  // console.log("productos de cupon ",arrayproducts)
  return (
    <>
      <Button onClick={() => setOpen(true)} >ver detalles</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle> Detalles del coupón </DialogTitle>
        <DialogContent>
          <h3>{coupon.title}</h3>
          <Typography>{coupon.text}</Typography>
          {
            arrayproducts.map(p => {
              return <Box flexDirection='row' justifyContent='space-between' display='flex' key={p.id} m={1} alignItems='center' paddingX={2} >
                <img src={p.image} style={{ width: 100, height: 100 }} />
                <Typography display='block'>{p.name}</Typography>
                {coupon.type==='percentaje' && <Typography>{(p.price -(p.price/100*coupon.quantity)).toFixed(2) }</Typography>}
                <Typography display='block' >${p.price.toFixed(2)}</Typography>
              </Box>
            })
          }
          <Box display='flex' justifyContent='space-between' >
            <Typography>subtotal:</Typography>
            <Typography>${total}</Typography>
          </Box>
          <Box display='flex' justifyContent='space-between' >
            <Typography>decuento:</Typography>
            <Typography>${discount}</Typography>
          </Box>
          <Box display='flex' justifyContent='space-between' >
            <Typography>igual:</Typography>
            <Typography>${total-discount}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}  >Acceptar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DetailsCoupon