import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableProducts from './products/tableProducts';
import ZoneCP from './zoneCP';
import Category from './category';
import Users from './users';
import OrdersList from './ordersList';
import { Link } from 'react-router-dom';
import Settings from './settings';
import Graphics from './graphics';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { PdfDocument } from './pdf/PdfDocument';
import { Button } from '@mui/material';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { useStateValue } from '../../StateProvider';
import ExportCSV from './csv/ExportCSV';
import EditField from './Edit/EditField';
// import ModalProducto from './products/newProduct';
// import AllUsers from './allUsers';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const db = getFirestore();
  const [value, setValue] = React.useState(0);
  const [orderData, setOrderData] = React.useState([])
  const [ dashboardData, setDashboardData ] = React.useState({})
  const [{ product, category }, dispatch] = useStateValue();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getOrdersData = async () => {
    let datos = []
    let productos = []
    let satisfactionArray = []
    let totalAmount = 0;
    let stockProducts = []
    product.map( pr =>stockProducts.push(pr) )
    console.log(stockProducts)
    stockProducts.sort( (a, b) => a.stock>b.stock )
    const querySnapshot = await getDocs(collection(db, 'orders'))
    querySnapshot.forEach(doc => {
      Object.keys(doc.data().orders).forEach(async (key) => {
        let item = doc.data().orders[key]
        satisfactionArray.push(item.rate)
        totalAmount += parseFloat(item.basketTotal) + parseFloat(item.shippingPrice)
        // console.log(item)
        Object.values(item.products).map( p => {
          let productFinded = productos.find( prod => prod.nombre == p.nombre )
          if(productFinded){
            let indexProduct = productos.findIndex( element => element.nombre == p.nombre )
            productos[indexProduct] = {
              ...p,
              quantity: productos[indexProduct].quantity + p.quantity
            }
          } else {
            productos.push(p)
          }
        })
        // item.products.map(p => {
        //   let exist = productos.find(product => product.name === p.name)
        //   if (exist) {
        //     //sumar producto al nuevo
        //     p.quantity += exist.quantity
        //   }
        // })

        datos.push(item)

      })
    })
    productos.sort((a, b) => a.quantity > b.quantity)
    // console.log(productos)

    setOrderData(datos)
    setDashboardData({
      mostSeller: productos.slice(0,3),
      lessSeller: productos.slice(productos.length-3,productos.length),
      stockProducts: [
        {
          name: "",
          quantity: 0,
          date: new Date(),

        }
      ],
      sells: {
          totalSells: datos.length,
          amount: totalAmount,
          data: datos
        }
      ,
      clientSatisfaction: satisfactionArray,
      topStock: stockProducts.slice(0,3),
      bottomStock: stockProducts.slice(stockProducts.length-3,stockProducts.length),
      OrderIssues: [
        {
          user: "marsbotmkt",
          mail: "marsbotmkt@gamil.com",
          message: "El pollo estaba podrido",

        }
      ],
      timeDelivery: {
        data: [1, 2, 3, 4],
        labels: ["lunes", "marrtes", "Miercoles", "Jueves"],
        promedio: "45 min"
      }
    })
  }



  React.useEffect(() => {
    getOrdersData()
  }, [product.length])

  return (
    <div className='contenido' >
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
      >
        <Tabs
          // orientation="vertical"
          // variant="scrollable"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
        // sx={{ borderRight: 1, borderColor: 'divider', height: '100%' }}
        >
          <Tab label="Productos" {...a11yProps(0)} />
          <Tab label="Zonas de entrega" {...a11yProps(1)} />
          <Tab label="Pedidos" {...a11yProps(2)} />
          <Tab label="Usuarios" {...a11yProps(3)} />
          <Tab label="Categorías" {...a11yProps(4)} />
          <Tab label="Inicio usuarios" {...a11yProps(5)} >  </Tab>
          <Tab label='Ajustes' {...a11yProps(6)}  ></Tab>
          <Tab label="Reportes" {...a11yProps(7)}></Tab>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="TabsProfile">
        <TableProducts />
        {/* <EditField /> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ZoneCP />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrdersList />
      </TabPanel>
      <TabPanel value={value} index={3} className="TabsProfile">
        {/* <AllUsers /> */}
        {/* <h2>Por el momento esta pagina no esta disponible, intente mas tarde</h2> */}
        <Users />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Category />
      </TabPanel>
      <TabPanel value={value} index={6} >
        <Settings />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Link to="/home" >Ver pagina</Link>
        <PDFViewer style={{ width: "100%", height: "60vh" }} >
          <PdfDocument />
        </PDFViewer>
        <PDFDownloadLink
          document={<PdfDocument />}
          fileName="reporte_de_ventas.pdf"
        >
          <Button variant='outlined' >
            Descargar
          </Button>
        </PDFDownloadLink>
        <ExportCSV  dataR={dashboardData} />
        {/* <div id='pdf-view' ></div> */}
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Graphics data={dashboardData} />
      </TabPanel>
      
    </div>
  );
}