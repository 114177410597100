import * as React from 'react';
import { useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import { Button, Collapse, Box, CircularProgress } from '@mui/material';
import { useStateValue } from '../../StateProvider';
import { getBasketTotal, actionTypes } from '../../reducer';
import { getFirestore, doc, setDoc, updateDoc, getDoc, addDoc, collection } from "firebase/firestore";
import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Link } from 'react-router-dom';
// import Stack from '@mui/material/Stack';


export const CheckoutForm = () => {
  const [success, setSuccess] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [{ basket, user, date }, dispatch] = useStateValue();
  const stripe = useStripe();
  const elements = useElements();
  const db = getFirestore();
  const [idPedido, setIdPedido] = React.useState(4)

  const [disabled, setDisabled] = React.useState(true)
  const [processing, setProcessing] = React.useState("")
  const [error, setError] = React.useState(null)

  const handleChange = async (event) => {
    setDisabled(!event.complete)
    console.log(event)
    setError(event.error ? event.error.message : "")
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setProcessing(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    basket.forEach( (element)=>{
      checkStock(element)
    } )

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    });
    const { id } = paymentMethod;
    if (!error) {
      try {
        
        // const { data } = await axios.post("http://localhost:3001/api/checkout", {
        const { data } = await axios.post(process.env.REACT_APP_PAYMENTURL , {
          id,
          amount: parseInt(getBasketTotal(basket)>500 ? (getBasketTotal(basket) * 100) : (getBasketTotal(basket) * 100) + (46.40*100))
        })
        console.log(basket);
        var orders = {};
        var tS = new Date().getTime();
        var products = {};

        var today = new Date();
        const numberOrder = Math.floor(Math.random() * 999999999999)
        setIdPedido(numberOrder);
        if (data.message === "Pago exitoso") {
          let items = ``;
          basket.forEach(function (element, key) {
            products[element.docId] = {
              quantity: element.quantity,
              unit: element.unit,
              price: element.price,
              url: element.image,
              nombre: element.name,
              subtotal: 
              element.defaultUnit==="Pza"?
              (element.price*element.quantity).toFixed(2)
              : element.unit==="Pza"? 
              (element.priceTotal.toFixed(2)) 
              : ((element.price/1000) * element.quantity).toFixed(2) 
            };
            updateStock(element)
            items += `
            <tr>
              <th>
                <table style="width: 50%;margin: auto;" >
                  <tr  >
                    <td  >
                      <img src="${element.image}" alt="item" width="100px" height="100px" style="border-radius: 10px;" >
                    </td>
                    <th>
                      <span style="color: #43ABAE;text-align: start;display: block;" >${element.name}
                          <span style="color: #707070; font-weight: 400; font-size: 12px;margin-left: 10px;" >   $ ${products[element.docId].subtotal}</span>
                      </span>
                      <span style="color: #707070;display: block; font-weight: 400; font-size: 12px;text-align: start;" >${element.quantity} ${element.unit}</span>
                    </th>
                  </tr>
                </table>
              </th>
            </tr>`
          })
          orders[numberOrder] = {
            products,
            date: today,
            time: date,
            username: user.displayName,
            email: user.email,
            address: user.address,
            status: "pay",
            statusOrder: "Orden recibida",
            shippingPrice: parseInt(getBasketTotal(basket)) < 500 ? 50 : 0,
            basketTotal: getBasketTotal(basket)
          };

          await setDoc(doc(db, "orders", user.uid), {
            orders
          }, { merge: true });

          const emailContent = {
            to: user.email,
            message: {
              subject: "Resumen de compra allfresco",
              text: "Gracias por comprar en allfresco, puede ir a su perfil y ver el estado de su pedido.",
              html: `
              <body>
                <table style="width: 600px;height: 368px;font-family: 'Quicksand';background-image: url(https://firebasestorage.googleapis.com/v0/b/allfresco-52e3c.appspot.com/o/images%2Fabove-view-green-fruits-and-vegetables.jpg?alt=media&token=318ad227-2c92-4cdd-8bc7-650484fd633b);background-position: center;background-size: cover;" >
                <tbody>
                  <tr>
                    <th>
                      <img src="https://develop.allfresco.mx/static/media/cereza.136f873677aece390bd6.png" alt="allfresco logo" width="35px" height="35px"  />
                      <h1 style="color: #43ABAE;margin: 0px;" >allfresco</h1>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <h2 style="font-size:16px;color: #B6D33C; margin: 0px;" >¡Hola!</h2>
                <h2 style="font-size:16px;color: #B6D33C; margin: 0px;">@${user.displayName}</h2>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p style="color: #707070;font-family: 'Open sans';margin-bottom: 0px;font-weight: 400;" >Tu compra ha sido todo un exito</p>
                <p style="color: #707070;font-family: 'Open sans'; margin: 0px;font-weight: 400;" >te mostramos el resumen de tu pedido</p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p style="color: #B6D33C;" >
                        No. de pedido: ${numberOrder}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p style="color: #43ABAE;" >Resumen del pedido</p>
                      <p style="color: #43ABAE;" >${ basket.length } productos</p>
                    </th>
                  </tr>
                  ${items}
                  <tr>
                    <th style="color: #707070;" >
                      <span >Metodo de pago: ----- </span> <label style="font-weight: 400;" >Crédito</label>
                    </th>
                  </tr>
                  <tr>
                    <th style="color: #707070;" >
                      <span>Costo de envio: ---------</span> <label style="font-weight: 400;" >$ ${ parseInt(getBasketTotal(basket) < 500 ? 50 : 0) } </label>
                    </th>
                  </tr>
                  <tr>
                    <th style="color: #707070;" >
                      <span>Descuentos: ------------</span> <label style="font-weight: 400;" >$0.00 </label>
                    </th>
                  </tr>
                  <tr>
                    <th style="color: #707070;" >
                      <span>Total: -------------------</span> <label style="font-weight: 400;" >$ ${ parseInt(getBasketTotal(basket)) + parseInt(getBasketTotal(basket) < 500 ? 50 : 0) }</label>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p style="color: #707070; font-weight: 400;margin-bottom: 0px;" >Estamos preparando tu pedido para que lo tengas cuanto </p>
                      <p style="color: #707070; font-weight: 400;margin-top: 0px;" > antes, te avisaremos cuando esté en camino.</p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <h3 style="color: #B6D33C;" >¡Gracias por tu compra!</h3>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div >
                        <span style="text-align: center;font-size: 12px;color: #43ABAE;" >Siguenos</span>
                        <div  >
                          <svg id="Grupo_1805" data-name="Grupo 1805" xmlns="http://www.w3.org/2000/svg" width="13.246" height="13.246" viewBox="0 0 13.246 13.246">
                            <path id="Trazado_1970" data-name="Trazado 1970" d="M6.623,0a6.623,6.623,0,1,0,6.623,6.623A6.623,6.623,0,0,0,6.623,0M9.23,3.344H8.454a.889.889,0,0,0-1,.961V5.459H9.158L8.885,7.237H7.452v4.3H5.529v-4.3H3.967V5.459H5.529V4.1A2.171,2.171,0,0,1,7.853,1.71a9.456,9.456,0,0,1,1.377.12Z" fill="#3eb1b4"/>
                          </svg>
                          <svg id="Grupo_1804" data-name="Grupo 1804" xmlns="http://www.w3.org/2000/svg" width="13.246" height="13.246" viewBox="0 0 13.246 13.246">
                            <path id="Trazado_1967" data-name="Trazado 1967" d="M74.787,13.415a1.266,1.266,0,1,0,1.266,1.266,1.268,1.268,0,0,0-1.266-1.266" transform="translate(-68.132 -8.059)" fill="#3eb1b4"/>
                            <path id="Trazado_1968" data-name="Trazado 1968" d="M73.309,8.425H70.1a1.658,1.658,0,0,0-1.656,1.656v3.206A1.658,1.658,0,0,0,70.1,14.943h3.205a1.658,1.658,0,0,0,1.657-1.656V10.081a1.658,1.658,0,0,0-1.657-1.656m-1.57,5.236a1.977,1.977,0,1,1,1.977-1.977,1.979,1.979,0,0,1-1.977,1.977m2.093-3.617a.471.471,0,1,1,.471-.471.471.471,0,0,1-.471.471" transform="translate(-65.084 -5.061)" fill="#43abae"/>
                            <path id="Trazado_1969" data-name="Trazado 1969" d="M66.646,0a6.623,6.623,0,1,0,6.623,6.623A6.623,6.623,0,0,0,66.646,0M70.66,8.226a2.414,2.414,0,0,1-2.412,2.412H65.043a2.414,2.414,0,0,1-2.412-2.412V5.02a2.415,2.415,0,0,1,2.412-2.412h3.205A2.415,2.415,0,0,1,70.66,5.02Z" transform="translate(-60.023)" fill="#3eb1b4"/>
                          </svg>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <ul style="list-style-type: none; display: flex; color: #43ABAE;font-weight: 500;font-size: 16px;" >
                        <li >Acerca de allfresco</li>
                        <li style="margin-left: 10px;">Ayuda</li>
                        <li style="margin-left: 10px;margin-right: 10px;" >Privacidad</li>
                        <li>Terminos y condiciones</li>
                      </ul>
                    </th>
                  </tr>
                </tbody>
              </table>
              </body>
              `

            }
          }
          await addDoc( collection(db, "mail"), emailContent );
          
          setProcessing(false);
          setSuccess(true);
          setFail(false);
          cleanCart();
          // cleanCart();
        } else {
          console.warn("Ha ocurrido un error", data);
          setSuccess(false);
          setProcessing(true);
          setFail(true);
        }
        return (
          <div>
            Ha ocurrido un error con el pago
          </div>
        );
      } catch (error) {
        console.log(error);
      }
      setProcessing(false)
    }
  };
  const cleanCart = async () => {
    const docRef = doc(db, 'cart', user.uid)
    try {
      await updateDoc(docRef, {
        data: {}
      })
      // console.log(cart.data())
      dispatch({
        type: actionTypes.EMPTY_BASKET,
        basket: []
      })
      localStorage.removeItem("cart")
    } catch (error) {
      alert(error)
    }
  }
  const update = () => {
    cleanCart()
    // var products = {};
    // basket.forEach(function (element, key) {
    //   products[element.docId] = { quantity: element.quantity, unit: element.unit, price: element.price, url: element.image };
    //   console.log({element})
    //   updateStock(element)
    // })
  }
  const updateStock = async (product) => {
    const taskDocRef = doc(db, 'product', product.category, 'inventario', product.id)
    try {
      const stockProduct = await getDoc(taskDocRef)
      console.log(stockProduct.data().stock)
      console.log(product.quantityTotal)
      if (stockProduct.data().stock >= product.quantityTotal) {
        const newStock = (stockProduct.data().stock - product.quantityTotal)
        await updateDoc(taskDocRef, {
          stock: newStock
        })
      } else {
        alert("El stock de "+product.name+" es menor al pedido")
        return ;
      }
      // onClose()
    } catch (err) {
      alert(err)
    }
  }

  const checkStock = async(product) => {
    const taskDocRef = doc(db, 'product', product.category, 'inventario', product.id)
    const stockProduct = await getDoc(taskDocRef)
    if (stockProduct.data().stock >= product.quantityTotal) {
      
    } else {
      alert("El stock de "+product.name+" es menor al pedido")
      setDisabled(true)
      throw new Error('Check stokc first!');
      return ;
    }
  }

  const appearance = {
    theme: 'flat'
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box sx={{ background: "#fff", borderRadius: "5px", border: "1px solid #acaaaaf9", '&:hover': { border: "1px solid #000" }, '&:focused': { border: "2px solid #B6D33C" } }} paddingX={2} paddingY={1.5} marginY={1} >
          <CardElement id='card-element' onChange={handleChange} />
        </Box>
        {/* <Button onClick={() => update()} >cleanCart</Button> */}
        <Button fullWidth sx={{ border: "3px solid #B6D33C" }} variant='contained' disabled={!stripe || disabled || processing} type="submit">
          {
            processing ? <CircularProgress color='inactive' /> : "Pagar ahora"
          }
        </Button>
      </form>
      <Collapse in={success}>
        <Alert severity="success">
          <AlertTitle>Pago exitoso</AlertTitle>
          Su número de pedido es: <strong> #{idPedido} </strong>. Se ha enviado un correo con los detalles,
          y le informaremos el avance de su pedido.
          Vea sus pedidos en la seccion "Mis pedidos" de su perfil
        </Alert>
        <Button > <Link to="/perfil" >Ir a mi perfil</Link> </Button>
      </Collapse>
      <Collapse in={fail}>
        <Alert severity="error">
          <AlertTitle>Ha ocurrido un error</AlertTitle>
          En estos momentos no es posible procesar su pago, por favor intente nuevamente mas tarde.
        </Alert>
      </Collapse>
    </div>
  )
};