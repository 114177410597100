import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Ventas',
    },
  },
};

const labels = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];



export function VerticalBarChart({arrayData}) {
  let arrayAmount = [0,0,0,0,0,0,0,0,0,0,0,0]
  arrayData.sort( (a,b) => a.date>b.date)
  const arraySells = arrayData.map(sell => {
    let de = new Date(sell.date.seconds * 1000)
    // sell.date.getDate
    // console.log( sell.basketTotal+" "+sell.shippingPrice )
    arrayAmount[de.getMonth()] += parseFloat(sell.basketTotal) + parseFloat(sell.shippingPrice)
    // de.setSeconds()
    // console.log(de)
    
    // const [month, day, year] = [
    //   sell.date.getMonth(),
    //   sell.date.getDate(),
    //   sell.date.getFullYear(),
    // ];
  })
  console.log(arrayAmount)

  const data = {
    labels,
    datasets: [
      {
        label: 'Año',
        data: arrayAmount,
        backgroundColor: '#B6D33C',
      },
      {
        label: 'Dataset 2',
        data: [10,3,2,5,3,1,7],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
