import React from 'react'

const Promotions = () => {
  return (
    <section>
      <h1>Promociones chidas</h1>
    </section>
  )
}

export default Promotions