import React from 'react';
import Card from 'react-credit-cards';
import SupportedCards from './Cards';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from './utils';
import 'react-credit-cards/es/styles-compiled.css';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
// import styles from './styles.css';
// import PaymentForm from '../../checkoutForm/PaymentForm';
// import DialogContentText from '@mui/material/DialogContentText';


export default class CreditCard extends React.Component {
  state = {
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    issuer: '',
    focused: '',
    formData: null,
    open: false,
  };

  handleClickOpen = () => {
    this.setState(
      {
        open: true,
      }
    );
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
      }
    );
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    this.form.reset();
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData, open } = this.state;
    return (
      <div key="Payment">
        <div className="App-payment">
          <Button variant="outlined" onClick={this.handleClickOpen}>
            Añadir otra tarjeta
          </Button>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Añadir una tarjeta de crédito o débito"}
            </DialogTitle>
            <DialogContent>
              <Card
                number={number}
                name={name}
                expiry={expiry}
                cvc={cvc}
                focused={focused}
                callback={this.handleCallback}
              />
              <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="cardNumber"
                      type="tel"
                      name="number"
                      label="Número de tarjeta"
                      fullWidth
                      autoComplete="cc-name"
                      variant="standard"
                      className="form-control"
                      placeholder="0000-0000-0000-0000"
                      pattern="[\d| ]{16,22}"
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="cardName"
                      label="Nombre en la tarjeta"
                      name="name"
                      className="form-control"
                      placeholder="Nombre"
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                      fullWidth
                      autoComplete="cc-number"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="expDate"
                      label="Fecha de vencimiento"
                      name="expiry"
                      className="form-control"
                      placeholder="dd/aa"
                      pattern="\d\d/\d\d"
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                      fullWidth
                      autoComplete="cc-exp"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="cvv"
                      label="CVV"
                      name="cvc"
                      className="form-control"
                      placeholder="CVV"
                      pattern="\d{3,4}"
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                      helperText="Last three digits on signature strip"
                      fullWidth
                      autoComplete="cc-csc"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <input type="hidden" name="issuer" value={issuer} />
                  </Grid>
                </Grid>
              </form>
              {formData && (
                <div className="App-highlight">
                  {formatFormData(formData).map((d, i) => <div key={i}>{d}</div>)}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                Agregar
              </Button>
            </DialogActions>
          </Dialog>
          <SupportedCards />
          <IconButton aria-label="add to favorites" onClick={this.handleClickOpen}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}

//render(<App />, document.getElementById('root'));
