import { Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { saveDelibery } from '../adminFunctions/functions';
import { updateDoc,doc, getDoc } from 'firebase/firestore';
import db from '../../../firebase/fbcnf';

const DeliberyCosts = () => {
  const envio = useRef('');
  const cantidadMinima = useRef('');
  const pedidoMinimo = useRef('');

  async function saveDeliberyCost(){
    try {
      if(envio.current.value===''){
        alert("Ingrese una cantidad")
        return;
      }
      await updateDoc(doc(db,'settings','delibery'),{
        deliberyCost:Number(envio.current.value)
      })
      alert("El costo del envio se ha actualizado correctamente")
      await getData()
      console.log(envio.current.value)
      // await saveDelibery(envio.current.value)
    } catch (error) {
      console.log("erro al guardar ",error)
    }
    
  }
  async function saveMinQty(){
    if(cantidadMinima.current.value===''){
      alert("Ingrese una cantidad")
      return;
    }
    await updateDoc(doc(db,'settings','delibery'),{
      minOrder:cantidadMinima.current.value
    })
    // console.log(cantidadMinima.current.value)
  }
  async function saveMinOrder(){
    if(pedidoMinimo.current.value===''){
      alert("Ingrese un monto para pedido gratis")
      return;
    }
    await updateDoc(doc(db,'settings','delibery'),{
      qtyForFreeDelibery: pedidoMinimo.current.value
    })
  }

  async function getData(){
    try {
      // const result=true
      const result = await getDoc(doc(db, 'settings', 'delibery'))
      if(result){
        // console.log( 'delibery ', result.data())
        envio.current.value = result.data().deliberyCost;
        cantidadMinima.current.value = result.data().minOrder;
        pedidoMinimo.current.value = result.data().qtyForFreeDelibery;
      }
    } catch (error) {
      console.log("delibery settings ", error)
    }
  }
  useEffect(() => {
    getData()
  
  }, [])
  

  return (
    <section style={{marginTop:"20px"}} >
      <Typography variant='h4' >Envio</Typography>
    <div>
      <Typography>Cantidad mínima para generar pedido</Typography>
      <TextField placeholder='$600.00' type='number' inputRef={cantidadMinima} />
      <Button variant='contained' onClick={saveMinQty} >Actualizar</Button>
    </div>

    <div>
      <Typography>Costo del envio</Typography>
      <TextField placeholder='$46.40'inputRef={envio} />
      <Button variant='contained' onClick={saveDeliberyCost} >Actualizar</Button>
    </div>
    <div>
      <Typography>Cantidad para envio gratis</Typography>
      <TextField placeholder='$300.00' inputRef={pedidoMinimo} />
      <Button variant='contained' onClick={saveMinOrder} >Guardar</Button>
    </div>
    </section>
  )
}

export default DeliberyCosts
