import { Button } from '@mui/material'
import React from 'react'
import { CSVLink } from 'react-csv'

const DownloadProductsTable = ({datos}) => {
  const headers = [
    { label: "Producto", key: "product" },
    { label: "Precio", key: "price" },
    { label: "Unidad", key: "unit" },
    { label: "Categoria", key: "taxonomy" },
    { label: "Disponibilidad", key: "stock" },
  ];
  const data = datos.map( p =>{
    const d = {
      product: p.name ,
      price: p.price ,
      unit: p.primaryUnit,
      taxonomy: p.category ,
      stock: p.stock.toFixed(2)
    };
    return d;
  })
  const csvReport = {
    data,
    headers,
    filename: 'Allfresco_products-report.csv'
  }
  
  return (
    <Button 
    // onClick={()=>console.log(csvReport)} 
    variant='contained'
    > 
    {/* print */}
      <CSVLink {...csvReport} 
      // style={{backgroundColor:"##B6D33C"}} 
      >Exportar tabla</CSVLink>
    </Button>
  )
}

export default DownloadProductsTable