import { Button } from '@mui/material';
import React from 'react';
import { CSVLink } from 'react-csv';
 

 
// const data = [
//   { firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36" },
//   { firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76" },
//   { firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57" },
//   { firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42" },
//   { firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56" },
//   { firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23" },
//   { firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87" },
//   { firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78" },
//   { firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22" },
//   { firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35" },
//   { firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63" }
// ];
 

 
function ExportCSV({dataR}) {
  // console.log(dataR)
  const headers = [
    { label: "Usuario", key: "user" },
    { label: "Email", key: "email" },
    { label: "Direccion", key: "address" },
    { label:"CP", key: "zipCode" },
    { label: "Subtotal", key: "subtotal" },
    { label: "Envio", key: "delibery" },
    { label: "Total", key: "total" },
    { label: "Emision", key: "dateCreated" },
    { label: "Recibido", key: "dateRecibed" },
    { label: "Valoracion", key: "rate" },
    { label: "Productos", key: "products" },
  ];

  const data =[] 
  dataR.sells.data.map( item => {
    let row ={
      user: item.username,
      email: item.email,
      address: `${item.address.street} ${item.address.exteriorNumber}, ${item.address.suburb}`,
      zipCode: item.address.zipCode,
      subtotal: item.basketTotal,
      delibery: item.shippingPrice,
      total: parseFloat(item.basketTotal)+parseFloat(item.shippingPrice),
      dateCreated: new Date(item.date.seconds * 1000),
      dateRecibed: item.time.dateDelivery,
      rate: item.rate,
      products: Object.values(item.products).map(p =>p.nombre +", "),
    }
    data.push(row)
  } )

  const csvReport = {
    data: data,
    headers: headers,
    filename: 'Allfresco_Dashboard_Report.csv'
  };

  
  return (
    // <div classname="App">
    //   <h3>Export datos a CSV  - <a href="http://allfresco.mx" target="_blank" rel="noopener noreferrer">Allfresco</a></h3>
      <Button variant='contained' >
        <CSVLink {...csvReport}>Exportar Reporte</CSVLink>
      </Button>
      
    // </div>
  );
}
 
export default ExportCSV;