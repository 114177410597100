import React from 'react'

const NotFound = () => {
  return (
    <main
      style={{
        background: "#B6D33C",
        fontFamily: "Quicksand",
        fontWeight: 'bold',
        color: "white",
        flexGrow: 1,
        paddingTop:"2em"
      }}
    >
      <div style={{maxWidth:"1080px", margin:'auto',display: 'flex', flexDirection: 'column',
        justifyContent: 'center', alignItems:'center', marginTop:"30px"}} >
      <div style={{ display: 'flex', flexDirection: 'column' }} >
        {/* <img src="" alt="logo allfresco delivery" /> */}
        <div>
          <svg id="Grupo_3094" data-name="Grupo 3094" width="179.242" height="107.748" viewBox="0 0 179.242 107.748">
            <defs>
              <clipPath id="clipPath">
                <rect id="Rectángulo_1319" data-name="Rectángulo 1319" width="179.242" height="107.748" fill="#fff" />
              </clipPath>
            </defs>
            <g id="Grupo_1799" data-name="Grupo 1799" clipPath="url(#clipPath)">
              <path id="Trazado_1949" data-name="Trazado 1949" d="M122.173,27.324c-.351-1.61-.123-.383-.413-1.962-.071-.391-.138-.784-.2-1.176-.032-.2-.062-.407-.09-.611-.006-.046-.013-.091-.02-.137,0-.027-.006-.053-.011-.079,0,.011.02.154.006.046s-.028-.211-.041-.317q-.152-1.2-.246-2.409c-.125-1.578-.181-3.159-.183-4.742,0-1.49.044-2.981.131-4.468q.116-1.968.326-3.932.091-.854.2-1.7.025-.2.052-.4c.013-.1-.024.178-.008.057,0-.029.008-.058.012-.088.008-.066.018-.131.027-.2q.049-.363.1-.725.162-1.111.357-2.216c.061-.343.129-.683.194-1.025a.442.442,0,0,1,.009-.044c.329-1.141-.822-1.551-1.183-.613l-.19.987q-.182.984-.332,1.974-.242,1.57-.425,3.15-.222,1.9-.358,3.809-.154,2.189-.188,4.386c-.022,1.579.008,3.158.1,4.734.1,1.665.273,3.328.532,4.976.251,1.6.282,1.375.583,2.959Z" transform="translate(-35.831 -0.019)" fill="#fff" />
              <path id="Trazado_1950" data-name="Trazado 1950" d="M122.231.983c.368.611.77,1.2,1.181,1.785A26.955,26.955,0,0,0,126.3,6.284,11.147,11.147,0,0,0,128.278,7.9a.643.643,0,0,0,.866-.262.654.654,0,0,0-.262-.866c-.067-.042-.132-.084-.2-.128-.036-.023-.071-.048-.106-.071l-.039-.028c-.1-.071.128.094.027.02-.138-.1-.275-.207-.408-.316-.284-.233-.556-.479-.819-.736s-.528-.533-.78-.811c-.131-.144-.259-.291-.387-.439L126,4.054c-.1-.116.071.085,0,0l-.1-.125A33.725,33.725,0,0,1,123.705.921c-.134-.206-.267-.413-.394-.624a.646.646,0,0,0-.884-.2.653.653,0,0,0-.2.884" transform="translate(-36.561 0)" fill="#fff" />
              <path id="Trazado_1951" data-name="Trazado 1951" d="M129.48,9.128s-3.231,6.038-.852,10.939c2.81,5.789,8.163,7.777,9.9,9.874,0,0,5.16-18.348-9.05-20.814" transform="translate(-38.243 -2.732)" fill="#fff" />
              <path id="Trazado_1952" data-name="Trazado 1952" d="M122.625,38.949q-.527.084-1.043.193Z" transform="translate(-36.395 -11.659)" fill="#fff" />
              <path id="Trazado_1953" data-name="Trazado 1953" d="M118.8,38.566a21.43,21.43,0,0,0-3.264.249c.466,1.54.259,1.18.764,2.7q.186.559.394,1.111.1.269.209.536l.06.146c0,.007.006.014.009.021.036.088.074.176.112.263a22.683,22.683,0,0,0,1.017,2.067,19.617,19.617,0,0,0,1.225,1.93l.175.238.013.016.067.088q.174.226.357.446a9.966,9.966,0,0,0,.722.706c2.061-.6,7.731-2.033,11.156,4.8,4.633,9.24-4.251,19.567-15.154,17.934C103.532,69.854,102.211,57.484,107.238,51c4.027-5.2,8.936-3.025,11.414-2.077a21.492,21.492,0,0,1-2.162-3.569,52.839,52.839,0,0,1-2.206-6.3,21.259,21.259,0,1,0,4.515-.485" transform="translate(-29.198 -11.545)" fill="#fff" />
              <path id="Trazado_1954" data-name="Trazado 1954" d="M0,132.277c0-6.476,4.42-11.6,10.852-11.6a8.731,8.731,0,0,1,7.526,3.807v-1.444a1.693,1.693,0,0,1,1.838-1.881h1.925a1.726,1.726,0,0,1,1.882,1.881v18.51a1.694,1.694,0,0,1-1.882,1.838H20.217a1.661,1.661,0,0,1-1.838-1.838v-1.488a8.731,8.731,0,0,1-7.526,3.807C4.42,143.873,0,138.754,0,132.277m18.466,0c-.043-3.983-2.626-6.914-6.52-6.914-3.982,0-6.3,3.151-6.3,6.914,0,3.807,2.32,6.913,6.3,6.913,3.894,0,6.477-2.932,6.52-6.913" transform="translate(0 -36.126)" fill="#fff" />
              <path id="Trazado_1955" data-name="Trazado 1955" d="M42.971,137.74V109.778a1.661,1.661,0,0,1,1.838-1.838h1.97a1.661,1.661,0,0,1,1.838,1.838V137.74a1.661,1.661,0,0,1-1.838,1.838h-1.97a1.661,1.661,0,0,1-1.838-1.838" transform="translate(-12.863 -32.312)" fill="#fff" />
              <path id="Trazado_1956" data-name="Trazado 1956" d="M59.9,137.74V109.778a1.661,1.661,0,0,1,1.838-1.838H63.7a1.661,1.661,0,0,1,1.838,1.838V137.74a1.661,1.661,0,0,1-1.838,1.838h-1.97A1.661,1.661,0,0,1,59.9,137.74" transform="translate(-17.93 -32.312)" fill="#fff" />
              <path id="Trazado_1957" data-name="Trazado 1957" d="M77.375,137.553v-16.1H75.143a.9.9,0,0,1-1.006-1.007v-2.1a.9.9,0,0,1,1.006-1.006h2.232v-1.532c0-6.958,4.245-8.489,7.833-8.489,4.113,0,7.088,2.056,7.088,5.776a3.864,3.864,0,0,1-.088.7.835.835,0,0,1-.919.656H88.664a.821.821,0,0,1-.919-.788v-.305c0-.963-.963-1.62-2.188-1.62-1.707,0-2.582,1.27-2.582,3.238v2.363h4.376a.9.9,0,0,1,1.006,1.006v2.1a.9.9,0,0,1-1.006,1.007H83.063v16.1a1.661,1.661,0,0,1-1.838,1.838H79.257a1.694,1.694,0,0,1-1.882-1.838" transform="translate(-22.193 -32.125)" fill="#fff" />
              <path id="Trazado_1958" data-name="Trazado 1958" d="M100.868,141.554v-18.51a1.694,1.694,0,0,1,1.838-1.882h1.838a1.694,1.694,0,0,1,1.838,1.882v3.063c.744-3.807,3.588-5.426,5.864-5.426a6.52,6.52,0,0,1,.875.088c.963.088,1.137.875,1.137,1.75v2.188c0,1.138-.525,1.619-1.356,1.487a5.344,5.344,0,0,0-1.138-.131c-2.1,0-5.251,1.269-5.251,8.533v6.958a1.694,1.694,0,0,1-1.882,1.838h-1.925a1.661,1.661,0,0,1-1.838-1.838" transform="translate(-30.195 -36.126)" fill="#fff" />
              <path id="Trazado_1959" data-name="Trazado 1959" d="M122.29,132.365c0-6.346,4.857-11.684,12.077-11.684,5.951,0,11.115,3.588,11.552,10.678.088,1.137-.656,1.75-1.838,1.75H127.76c-.088,3.369,2.887,6.126,7.483,6.126a10.021,10.021,0,0,0,5.469-1.619,1.863,1.863,0,0,1,2.582.35l.394.481a1.767,1.767,0,0,1-.35,2.8,13.43,13.43,0,0,1-8.358,2.625c-7.877,0-12.69-5.163-12.69-11.508m18.2-2.8c-.875-3.5-3.676-4.376-6.039-4.376-2.45,0-5.382,1.006-6.433,4.376Z" transform="translate(-36.607 -36.126)" fill="#fff" />
              <path id="Trazado_1960" data-name="Trazado 1960" d="M160.3,139.5c-.569-1.182.043-2.013,1.225-2.32l1.138-.262c1.05-.263,1.575.219,2.319,1.05a4.733,4.733,0,0,0,3.676,1.575c1.925,0,3.282-1.05,3.282-2.451,0-1.181-.919-1.794-2.363-2.318l-2.844-1.007c-2.845-.875-5.907-2.625-5.907-6.257,0-3.938,3.238-6.826,7.833-6.826,2.8,0,5.382.919,6.914,3.544.743,1.138.131,2.057-1.094,2.363l-1.006.262a2.121,2.121,0,0,1-2.319-.875,2.947,2.947,0,0,0-2.407-1.006,2.423,2.423,0,0,0-2.713,2.319c0,1.182,1.05,1.794,2.276,2.188l2.975,1.05c4.42,1.4,5.82,3.982,5.864,6.52,0,4.463-3.938,6.826-8.489,6.826-3.72,0-6.87-1.487-8.358-4.376" transform="translate(-47.92 -36.126)" fill="#fff" />
              <path id="Trazado_1961" data-name="Trazado 1961" d="M188.682,132.452c0-6.476,4.726-11.771,12.69-11.771a13.461,13.461,0,0,1,6.826,1.882,1.592,1.592,0,0,1,.438,2.581l-.525.657c-.7.963-1.488,1.006-2.582.481a8.867,8.867,0,0,0-3.851-.875c-4.245,0-7.351,2.845-7.351,6.871,0,4.069,3.107,6.913,7.351,6.913a9.585,9.585,0,0,0,3.851-.875c1.094-.525,1.925-.481,2.582.438l.525.7c.7.963.482,2.1-.963,2.845a13.1,13.1,0,0,1-6.3,1.575c-7.789,0-12.69-5.076-12.69-11.421" transform="translate(-56.482 -36.126)" fill="#fff" />
              <path id="Trazado_1962" data-name="Trazado 1962" d="M221.535,132.279a12.019,12.019,0,1,1,11.99,11.6,11.706,11.706,0,0,1-11.99-11.6m18.554.043a6.569,6.569,0,1,0-6.564,6.826,6.693,6.693,0,0,0,6.564-6.826" transform="translate(-66.316 -36.126)" fill="#fff" />
            </g>
          </svg>

        </div>
        {/* <span >allfresco</span> */}
      </div>
      <span style={{ fontSize: "4em" }}  >404</span>
      <h1 style={{color:"white"}} >Pagina no encontrada</h1>
      <section  id="notFound" >
        {/* ENCHUFE SVG */}
        <div id='enchufin' >
          <img src="./enchufe.png" alt="imagen de enchufe"  id='enchufe' />
        </div>
        {/* AGUACATE SVG */}
        <div  id='aguacatin' >
          <img src="./aguacate.png" alt="mascota aguacate allfresco delivery"  
              id='aguacate'
          />
        </div>
      </section>
      </div>
    </main>
  )
}

export default NotFound