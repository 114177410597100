// import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, Grid, Snackbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from "react-router-dom";
import { actionTypes } from '../reducer';
import { useStateValue } from '../StateProvider';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import Category from './category';

export default function DetailsProduct() {
  const [{ basket, returnProduct, user }, dispatch] = useStateValue();
  const [showGroupButton, setShowGroupButton] = React.useState(async()=> await returnProduct.quantity > 0 ? true : false);
  const [selected, setSelected] = React.useState(async()=> await returnProduct.unit === "Pza" ? true : false)
  const [unidad, setUnit] = React.useState(selected?"Pza":"grs");
  const [open, setOpen] = React.useState(false);
  let params = useParams();

  const searchProduct = async () => {
    dispatch({
      type: actionTypes.SEARCH_PRODUCT,
      item: {
        id: params.product,
      }
    });
  }

  if (returnProduct.id !== params.product) {
    searchProduct();
    return (<div><h2>Cargando</h2></div>)
  }
  
  // React.useEffect(() => {
  //   if(returnProduct.quantity===0) removeItem()
  // }, [returnProduct.quantity])

  console.log("nombre: ",returnProduct.name)
  // if(returnProduct.name===undefined){
  //   console.log("hay producto")
  //   return (<div><h2>Cargando</h2></div>)
  // }
  // const [sinStock, setSinStock] = React.useState(false)
  // console.log(returnProduct.unit)
  const db = getFirestore();
  
  console.log("producto")
  console.log(returnProduct.unit)
  console.log(unidad)

  const uPza = async () => {
    removeItem();
    setUnit("Pza");
    setSelected(true)
  }
  const uKg = async () => {
    removeItem();
    setUnit("grs");
    setSelected(false)
  }
  const removeItem = async () => {
    dispatch({
      type: actionTypes.REMOVE_ITEM,
      id: returnProduct.id,
    });
    try {
      var data = {};
      basket.forEach(function (element) {
        if (element.docId !== returnProduct.docId) {
          data[element.docId] = { quantity: element.quantity, unit: element.unit }
        }
      })
      await setDoc(doc(db, "cart", user.uid), {
        data
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  

  const addToBasket = async () => {
    var cantidad = 1;
    if (unidad === "grs") {
      cantidad = parseInt(returnProduct.weight);
    }
    // console.log("unidad "+unidad)
    // if(returnProduct.stock<=returnProduct.quantity) {
    //   setOpen(true)
    //   return;
    // }
    if(insuficientStock()){
      setOpen(true)
      return;
    }
    dispatch({
      type: actionTypes.ADD_TO_BASKET,
      item: {
        id: returnProduct.id,
        name: returnProduct.name,
        productType: returnProduct.productType,
        image: returnProduct.image,
        price: returnProduct.price,
        description: returnProduct.description,
        piecesPerKg: returnProduct.piecesPerKg,
        quantity: cantidad,
        category: returnProduct.category,
        docId: returnProduct.docId,
        unit: unidad,
        weight: returnProduct.weight,
        defaultUnit: returnProduct.defaultUnit,
      }
    });
    try {
      var data = {};
      var existe = false;

      if (basket.length === 0) {
        data[returnProduct.docId] = { quantity: returnProduct.quantity + cantidad, unit: unidad }
      }
      Object.keys(data).forEach(key => {
        if (key === returnProduct.docId) {
          existe = true;
        }
      })
      if (!existe) {
        data[returnProduct.docId] = { quantity: returnProduct.quantity + cantidad, unit: unidad }
      }
      basket.forEach(function (element) {
        if (element.docId !== returnProduct.docId) {
          data[element.docId] = { quantity: element.quantity, unit: element.unit }
        }
      })
      await setDoc(doc(db, "cart", user.uid), {
        data
      });
    } catch (e) {
      console.log(returnProduct)
      console.error("Error adding document: ", e);
    }
    setOpen(true)
  }
  const removeToBasket = async () => {
    var cantidad = 1;
    if (unidad === "grs") {
      cantidad = parseInt(returnProduct.weight);
    }
    dispatch({
      type: actionTypes.REMOVE_TO_BASKET,
      item: {
        id: returnProduct.id,
        name: returnProduct.name,
        productType: returnProduct.productType,
        image: returnProduct.image,
        price: returnProduct.price,
        description: returnProduct.description,
        unit: unidad,
        quantity: cantidad,
        docId: returnProduct.docId
      }
    });
    if ((returnProduct.quantity - cantidad) > 0) {
      try {
        var data = {};
        var existe = false;

        if (basket.length === 0) {
          data[returnProduct.docId] = { quantity: returnProduct.quantity + cantidad, unit: unidad }
        }
        Object.keys(data).forEach(key => {
          if (key === returnProduct.docId) {
            existe = true;
          }
        })
        if (!existe) {
          data[returnProduct.docId] = { quantity: returnProduct.quantity - cantidad, unit: unidad }
        }
        basket.forEach(function (element) {
          if (element.docId !== returnProduct.docId) {
            data[element.docId] = { quantity: element.quantity, unit: element.unit }
          }
        })
        if(user!==null){
          await setDoc(doc(db, "cart", user.uid), {
            data
          });
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      removeItem()
    }
  }
  function insuficientStock() {
    if(returnProduct.defaultUnit==='Pza'){
      return returnProduct.stock <= returnProduct.quantity
    } else if(unidad==='grs'){
      return returnProduct.stock <= returnProduct.quantity/1000
    } else if (unidad==='Pza'){
      return returnProduct.stock <= (returnProduct.quantity*returnProduct.weight)/1000
    }
  // if(unidad=="Pza"){
  //   if(returnProduct.stock<=returnProduct.quantity) return true
  // } else {
  //   const units = returnProduct.quantity/returnProduct.weight
  //   console.log("unidades convertidas: "+units)
  //   if(returnProduct.stock<= units)return true;
  //   return false;
  // }
  }
  
  
  const handleClose = (event, reason) => {
   if(reason === "clickaway"){
    return
   }
   setOpen(false)
  }

  // https://schema.org/Article
  const d = new Date(returnProduct.createdAt.seconds *1000).toISOString();
  const productStructuredData={
    "@context":"https://schema.org",
    "@type":"Product",
    productionDate:d,
    name: returnProduct.name,
    description: returnProduct.description,
    image: returnProduct.image,
    category: params.category,
    hasMeasurement:returnProduct.defaultUnit,
    keywords: returnProduct.tags,
    weight: returnProduct.weight,
  }

  return (
    <Box className="all-content store-content" sx={{ flexGrow: 1, padding: "50px 24px", marginTop:{xs:"24px"} }}>
      <script>
        {JSON.stringify(productStructuredData)}
      </script>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "start" }}>
          <Card sx={{ maxWidth: 360 }}>
            <CardContent sx={{ display: "flex", justifyContent: "flex-start" }}>
              <CardMedia
                component="img"
                image={returnProduct.image}
                alt={`Imagen de ${returnProduct.name}`}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid className='product-details' item xs={12} md={7} >
          
          <Typography>$
            {
              returnProduct.defaultUnit==='Pza'
              ? `${returnProduct.price} por Pza`
              : unidad==='grs'
              ? `${returnProduct.price} por K`
              : `${returnProduct.price/1000 * (returnProduct.weight)} por Pza`
            }
            {/* ${returnProduct.price} por {unidad == "grs" ? (returnProduct.weight + "g") : "Pieza"} */}
          </Typography>
          <Typography variant="h2" component="h2">
            {returnProduct.name}
          </Typography>
          <Typography variant="h3" component="h3">
            {params.category}
          </Typography>

          <Box  >
            <Button color='primary' variant='contained' size='large' onClick={() => setShowGroupButton(true)} sx={{ display: showGroupButton ? "none" : "block", marginBottom: "47px" }} >Agregar al carrito</Button>
            <Box display={showGroupButton ? "block" : "none"} >
              <CardActions sx={{ justifyContent: "center", padding: "0px" }}>
                <ButtonGroup color="secondary" variant="contained" aria-label="outlined primary button group" fullWidth sx={{ boxShadow: 0, borderRadius: '10px' }}>
                  {/* <Button onClick={uPza} sx={{ borderRadius: "10px 0 0 10px", border: "none" }} color={selected ? "secondary" : "inactive"} >Pieza</Button> */}
                  {
                    returnProduct.defaultUnit=="Pza"?
                    <Button onClick={uPza} sx={{ borderRadius: "10px", border: "none" }} color={selected ? "secondary" : "inactive"} >Pieza</Button>
                    :<>
                    <Button onClick={uPza} sx={{ borderRadius: "10px 0 0 10px", border: "none" }} color={selected ? "secondary" : "inactive"} >Pieza</Button>
                    <Button onClick={uKg} sx={{ borderRadius: "0 10px  10px 0", border: "none" }} color={selected ? "inactive" : "secondary"} >Kilo</Button>
                    </>
                  }
                </ButtonGroup>
              </CardActions>
              <CardActions sx={{ justifyContent: "center", padding: "0", marginTop: "10px" }} >
                <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ boxShadow: 0, borderRadius: '10px' }}>
                  <Button onClick={removeToBasket} sx={{ border: "none", width: "34px", height: "37px", borderRadius: "11px 0 0 11px", marginLeft: "0" }} color="secondary" className='min-btn'>-</Button>
                  <Button sx={{ border: "none", width: "246px", textTransform:"none" }} className="card-btn-unit" color='inactive'>{returnProduct.quantity} {unidad==="grs"?"g":"Pza"}</Button>
                  <Button onClick={addToBasket} sx={{ border: "none", width: "34px", height: "37px", borderRadius: "0 10px 10px 0", marginRight: "0" }} color="secondary" className='plus-btn'
                    // disabled={checkStock()}
                  >+</Button>
                </ButtonGroup>
              </CardActions>
            </Box>
            {/* <Typography variant='subtitle1' color="tomato" > Stock: {returnProduct.stock}</Typography> */}
          </Box>
          <Typography variant="h3" >
            Descripción
          </Typography>
          <Typography>
            {returnProduct.description}
          </Typography>
        </Grid>
      </Grid>
      <Box height="100px" ></Box>
      <Typography variant='h2' >Productos relacionados</Typography>
      <Category categoryName={returnProduct.category} limit={4} ></Category>

      <Snackbar 
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{vertical:"top",horizontal:"right"}}
      >
        <Box sx={{width:"100%", background:"#fff", borderRadius:"10px"}} p={1} paddingX={2} display="flex" boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" >
          <img src={returnProduct.image} width="50px" height="50px" alt={`image of a ${returnProduct.name}`} />
          <Box marginLeft={2} >
            <Typography color="primary" >{returnProduct.stock<=returnProduct.quantity?"Lo sentimos pero,":"Haz añadido"} </Typography>
            {
              insuficientStock()?
              <Typography color="tomato" ><strong>No hay stock suficiente</strong></Typography>
              :
              <Typography color="primary" ><strong>{returnProduct.quantity} {unidad} de {returnProduct.name} </strong></Typography>
            }  
          </Box>
        </Box>
      </Snackbar>

    </Box>
  );
}