// import CardActions from '@mui/material/CardActions';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Toolbar } from '@mui/material';

export default function Wallet() {
  return (
    <Card sx={{ maxWidth: 345, margin: 'auto' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: "center", background: '#B6D33C', color: 'white' }}>
        <Typography gutterBottom variant="h5" component="div" align="center">
          Wallet
        </Typography>
      </Toolbar>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" align="center">
          $00.00
        </Typography>
      </CardContent>
    </Card>
  );
}
