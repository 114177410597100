import * as React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useStateValue } from '../../StateProvider';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export default function AddressCard() {
    const [{ user }, dispatch] = useStateValue();
    const db = getFirestore()
    const [domicilios, setDomicilios] = React.useState([])
    const [calle, setCalle] = React.useState(user.address.street)


    const getAddress = async () => {
        const result = await getDoc( doc(db, 'address', user.uid) )
        const addresList = result.data()
        let datos = []
        Object.keys(addresList).map( adrs => {
            addresList[adrs].id=adrs
            datos= [...datos, addresList[adrs]]
        } )
        setDomicilios(datos)
    }
    React.useEffect(() => {
      getAddress()
    }, [])

    const changeDeliberyAddress = ( newAddress ) => {
        // alert("address changed")
        user.address = newAddress
        setCalle(newAddress.street)
    }
    
    const changeDelibery = (calle ) => {
        if(user.address.street === calle) 
            return true
            else
                return false 
    }
    React.useEffect(() => {
        setCalle(user.address.street)
    }, [user.address])
    

    const CustomRadio = ({filled}) => (
        <div style={{
            width:"15px",
            height:"15px",
            borderRadius:"50%",
            border: "2px solid #43ABAE"
        }} >
            {
                filled?
                    <div style={{
                        width:"100%",
                        height:"100%",
                        background: "#43ABAE"
                    }} ></div>
                : null
            }
        </div>
    )

    if (user != null && user.address != null) {
        return (
            <React.Fragment>
                {
                    domicilios.map( d => (
                        <Grid item xs={3} marginTop="10px"   >
                            <Card sx={{width:300, background:"#fff"}} onClick={()=>changeDeliberyAddress(d)} >
                                <CardContent>
                                    <CustomRadio filled={ calle===d.street?true:false } />
                                    <Typography variant='h5' > {d.id=="main"?"PRINCIPAL":""} </Typography>
                                <Typography variant='body2' color="text.secondary" >
                                    <p> {d.street}, {d.exteriorNumber} {d.interiorNumber}, {d.suburb} </p>
                                    <p> CP: { d.zipCode} </p>
                                    <p> {d.city}, {d.state}, {d.country} </p>
                                </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ) )
                }
            </React.Fragment>
        );
    }
}