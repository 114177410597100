import * as React from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';
import { Grid } from '@mui/material';
import { getFirestore, doc, setDoc } from "firebase/firestore";
// import CardHeader from '@mui/material/CardHeader';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import Button from '@mui/material/Button';

const Botones = styled('div')(({ theme }) => ({
  root: {
    background: 'blue',
  },
  button: {
    width: '100px',
  },
}));

export default function Product({ product: { id, name, productType, price, image, description, quantity, docId } }) {
  const [{ basket, user }, dispatch] = useStateValue();
  const db = getFirestore();
  var url = "/categoria/" + id;
  const addToBasket = async () => {
    dispatch({
      type: actionTypes.ADD_TO_BASKET,
      item: {
        id,
        name,
        productType,
        image,
        price,
        description,
        quantity: 1,
        docId
      }
    });

    try {
      var data = {};
      var existe = false;

      if (basket.length == 0) {
        data[docId] = { quantity: quantity + 1 }
      }
      Object.keys(data).forEach(key => {
        if (key == docId) {
          existe = true;
        }
      })
      if (!existe) {
        data[docId] = { quantity: quantity + 1 }
      }
      basket.forEach(function (element) {
        if (element.docId != docId) {
          data[element.docId] = { quantity: element.quantity }
        }
      })
      await setDoc(doc(db, "cart", user.uid), {
        data
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }

  }
  const removeToBasket = async () => {
    dispatch({
      type: actionTypes.REMOVE_TO_BASKET,
      item: {
        id,
        name,
        productType,
        image,
        price,
        description,
        quantity: 1,
        docId
      }
    });
    if ((quantity - 1) > 0) {
      try {
        var data = {};
        var existe = false;

        if (basket.length == 0) {
          data[docId] = { quantity: quantity + 1 }
        }
        Object.keys(data).forEach(key => {
          if (key == docId) {
            existe = true;
          }
        })
        if (!existe) {
          data[docId] = { quantity: quantity - 1 }
        }
        basket.forEach(function (element) {
          if (element.docId != docId) {
            data[element.docId] = { quantity: element.quantity }
          }
        })
        await setDoc(doc(db, "cart", user.uid), {
          data
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }
  return (
    <Grid container>
      <Grid item xs={2}>
        <Link to={url}>
          <CardMedia
            component="img"
            height="100"
            width="50"
            sx={{ width: '100px', objectFit: 'contain' }}
            image={image}
            alt="Imagen no disponible"
          />
        </Link>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Categoría
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          ${price} Kg
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Disponibilidad:
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <DeleteIcon />
        </IconButton>
        <IconButton>
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
