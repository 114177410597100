// import Box from '@mui/material/Box';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useStateValue } from '../../StateProvider';
import { getBasketTotal, getTaxes } from '../../reducer';
import { Box } from '@mui/material';
import IconoBolsa from '../../assets/ICONO_BOLSA.webp';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Resume() {
    const [{ basket }, dispatch] = useStateValue();
    var send = parseFloat(getBasketTotal(basket) < 500 ? 46.40 : 0)
    var subtotal = parseFloat(getBasketTotal(basket))
    return (
        <Item   >
            <Typography variant="h2" color="primary" gutterBottom>
                {/* <span  className='icon-Bolsa-de-compra' ></span> */}
                <img style={{marginRight:"10px"}} src={IconoBolsa} />
                  Resumen de compra
            </Typography>
            <Grid container fullWidth spacing={2} p={2} >
                <Grid item xs={12} height="300px" sx={{overflow:"scroll"}} >
                    {
                        basket.map( (producto) => <Box display="flex" key={producto.id} m={1}>
                            <img src={producto.image} height={100} width={100} 
                                // style={{boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"}} 
                            />
                            <Box display="flex" m={1} flexDirection={{xs:"column",sm:"row"}} justifyContent="space-between" alignItems="center" sx={{width:"100%"}} >
                                <Typography width={{xs:"100%",sm:"30%"}} variant='h6' >{producto.name}</Typography>
                                <Box>
                                    <Typography variant='caption' >{producto.quantityTotal} {producto.unit==="grs"?"Kg":"Pza"}</Typography>
                                    <Typography variant='caption' display="block" >{producto.weight} g. por pza</Typography>
                                </Box>
                                <Typography variant='caption' >$ 
                                {
                                    producto.defaultUnit=="Pza"?
                                    (producto.price*producto.quantity).toFixed(2)
                                    : producto.unit==="Pza"? 
                                    (producto.priceTotal.toFixed(2)) 
                                    : ((producto.price/1000) *producto.quantity).toFixed(2) 
                                }</Typography>
                            </Box>
                        </Box> )
                    }
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'left', fontWeight: "bold" }}>
                    Subtotal ({basket.length} productos)
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    ${subtotal} MXN
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'left', fontWeight: "bold" }}>
                    Envío
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    $ {send} MXN
                </Grid>
                <Grid item xs={12} >
                    <hr></hr>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'left', fontWeight: "bold" }}>
                    <Typography variant='h6' color="#B6D33C" >Total</Typography>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                <Typography variant='h6' color="#B6D33C" >${(subtotal + send).toFixed(2)} MXN</Typography>
                    
                </Grid>
            </Grid>
        </Item>
    )
}