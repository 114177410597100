import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { getFirestore, doc, deleteDoc } from "firebase/firestore";
import { styled, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useStateValue } from '../../../StateProvider';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputBase from '@mui/material/InputBase';
import { Button, Dialog, Grid, TableHead, Typography } from '@mui/material';
import ModalProducto from './newProduct';
import AddStockModal from './addStockModal';
import DownloadProductsTable from '../csv/DownloadProductsTable';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(image, name, price, stock, docId, category, unit, weight) {
  return { image, name, price, stock, docId, category, unit, weight };
}


export default function TableProducts() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(-1);
  const [{ product, category }, dispatch] = useStateValue();
  const [allCategories, setAllCategories] = React.useState([])
  const [idProduct, setIdProduct] = React.useState(111);
  const [pName, setName] = React.useState('');
  const [pDescription, setDescription] = React.useState('');
  const [pStock, setStock] = React.useState('');
  const [pStockKg, setStockKg] = React.useState('');
  const [pPiecesPerKg, setPiecesPerKg] = React.useState('');
  const [weight, setWeight] = React.useState(0);
  const [tags, setTags] = React.useState([]);
  const [pUnit, setUnit] = React.useState('');
  const [pSecondaryUnit, setSecondaryUnit] = React.useState('');
  const [pCategory, setCategory] = React.useState('');
  const [pSubcategory, setSubcategory] = React.useState('');
  const [pPrice, setPrice] = React.useState('');
  const [isAsc, setIsAsc] = React.useState(false)
  const [tabsArrows, setTabsArrows] = React.useState({
    name:false,
    category:false,
    price:false,
    stock:false
  })

  // console.log(category);
  // React.useEffect(() => {
  //   setAllCategories(category)
  //   console.log("all categories")
  //   console.log(category)
  //   console.log(allCategories)

  // }, [category])

  var rows = [].sort((a, b) => (a.stock < b.stock ? -1 : 1));

  product.map(dataProduct => (
    rows.push(createData(dataProduct.image, dataProduct.name, dataProduct.price, dataProduct.stock, dataProduct.docId, dataProduct.category, dataProduct.unit, dataProduct.weight))
  ))

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const addProduct = async (e) => {
    document.getElementById("TableProducts").style.display = "none";
    //document.getElementById("newProduct").style.display = "flex";
    const container = document.getElementById('editProduct');
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(<ModalProducto update={handleUpdate} dispatch={dispatch} Categories={{ category }} ProductId={{ id: "", pName: "", pDescription: "", pStock: "", pStockKg: "", pPiecesPerKg: "", pUnit: "", pSecondaryUnit: "", pCategory: "", pSubcategory: "", pPrice: "" }} />);
  }

  const removeProduct = async (e) => {
    const db = getFirestore();
    var productId = "";
    var category = "";
    if (e.target.parentNode.getAttribute("product-id") == null) {
      productId = e.target.parentNode.parentNode.getAttribute("product-id");
      category = e.target.parentNode.parentNode.getAttribute("category");
    } else {
      productId = e.target.parentNode.getAttribute("product-id");
      category = e.target.parentNode.getAttribute("category");
    }
    await deleteDoc(doc(db, "product", productToRemove.category, "inventario", productToRemove.id));
    window.location.reload();
  }

  const editProduct = async (e) => {
    var productId = "";
    let categoryParent = "";
    if (e.target.parentNode.getAttribute("product-id") == null) {
      productId = e.target.parentNode.parentNode.getAttribute("product-id");
      categoryParent = e.target.parentNode.parentNode.getAttribute("category");
    } else {
      productId = e.target.parentNode.getAttribute("product-id");
      categoryParent = e.target.parentNode.getAttribute("category");
    }
    setIdProduct(productId);
    const productIndex = product.findIndex((basketItem => basketItem.docId === productId));
    const productDetails = product[productIndex];
    setName(productDetails.name);
    setDescription(productDetails.description);
    setStock(productDetails.stock);
    setStockKg(productDetails.stockKg);
    setPiecesPerKg(productDetails.piecesPerKg);
    setUnit(productDetails.unit);
    setSecondaryUnit(productDetails.secondaryUnit);
    setCategory(productDetails.category);
    setSubcategory(productDetails.subcategory);
    setPrice(productDetails.price);
    setWeight(productDetails.weight);
    setTags([...productDetails.tags]);
    console.log({ productDetails })

    const container = document.getElementById('editProduct');
    const root = createRoot(container);
    root.render(<ModalProducto update={handleUpdate} dispatch={dispatch} Categories={{ category }} ProductId={{
      id: productDetails.id, pName: productDetails.name, pDescription: productDetails.description, pStock: productDetails.stock,
      pStockKg: productDetails.stockKg, pPiecesPerKg: productDetails.piecesPerKg, pUnit: productDetails.defaultUnit, pSecondaryUnit, pSubcategory, pPrice:
        productDetails.price, pCategory: categoryParent, pWeight: productDetails.weight, pTags: productDetails.tags, pImage: productDetails.image, pOrganic:productDetails.organico
    }} />);
    document.getElementById("TableProducts").style.display = "none";
  }

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // border: '1px solid ',
    backgroundColor: "#F9f9f9",
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  }));
  const [searched, setSearched] = React.useState("");
  const [items, setItems] = React.useState([])
  const requestSearch = (searchedVal) => {
    const filteredProducts = rows.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase())
    })
    setItems(filteredProducts)
  }
  const handleSearch = (search) => {
    setSearched(search)
    requestSearch(search)
    // if(search==="") setItems([...rows])
  }
  const [updateDom, setUpdateDom] = React.useState(false)
  React.useEffect(() => {
    const arr1 = JSON.stringify(items)
    const arr2 = JSON.stringify(product)
    if(arr1!==arr2){
      console.log("are the diferent objects")
      setItems(product)
    }
    console.log("update comp - ",updateDom)

  }, [product.length, updateDom])
  const handleUpdate = ()=> {
    setUpdateDom(!updateDom)
  }

  console.log(items)
  const [open, setOpen] = React.useState(false)
  const [productToRemove, setProductToRemove] = React.useState({
    id: '',
    url: '',
    productName: '',
    category: ''
  })
  const ArrowIcon=()=>(
    <svg  width="12.096" height="14.106" viewBox="0 0 12.096 14.106">
  <path id="Trazado_1979" data-name="Trazado 1979" d="M6.685,32.32a1.009,1.009,0,0,0-1.426,0L.22,37.358a1.009,1.009,0,0,0,1.426,1.426l3.319-3.322v9.661a1.008,1.008,0,0,0,2.015,0V35.463L10.3,38.782a1.009,1.009,0,0,0,1.426-1.426L6.688,32.317Z" transform="translate(0.075 -32.025)" fill="#9f9f9f"/>
</svg>
  )
    const handleSort= (property,condition)=>{
      // const sorted = product.sort((a,b) =>(a.name<b.name)?1:(a.name>b.name)? -1:0)
      setItems(()=>{
        let copyArray= [...items]
        let result = []
        if(condition){
          result = copyArray.sort((a,b) =>(a[property]<b[property])?1:(a[property]>b[property])? -1:0)
        }
        else {
          result = copyArray.sort((a,b) =>(a[property]>b[property])?1:(a[property]<b[property])? -1:0)
        }
        return result
      })
      console.log("sorted by :) ",property, " state ", condition)
      setIsAsc(!isAsc)
      setTabsArrows({
        ...tabsArrows,
        [property]: !tabsArrows[property]
      })
    }
  const TabButton=({text, word})=>{
  const [isUp, setIsUp] = React.useState(false)
  return <Button 
    sx={{color:"#707070", fontFamily:"Quicksand", textTransform:'none', fontWeight:'bold'}}
    onClick={()=>{setIsUp(!isUp);handleSort(word, isAsc);}}
    > <div style={{marginRight:"5px", transform:`rotate(${tabsArrows[word]?180:0}deg)`,}} ><ArrowIcon/></div>
        {text}</Button>}
  
  return (
    <div>
      <Grid container spacing={1} marginY={2} >
        <Grid item xs={12} md={6} >
          <Search sx={{ width: '100%' }}>
              <SearchIconWrapper>
                <SearchIcon color='primary' />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Buscar…"
                inputProps={{ 'aria-label': 'search' }}
                sx={{ width: '100%' }}
                value={searched}
                onChange={(e) => handleSearch(e.target.value)}
                autoFocus
              />
            </Search>
        </Grid>
        <Grid item xs={12} md={3} >
          <Typography sx={{ color: 'black' }} >
              <IconButton size='large' sx={{ color: '#B6D33C' }} onClick={addProduct}>
                <AddCircleIcon fontSize='25px' />
              </IconButton>
              Agregar nuevo producto
            </Typography>
        </Grid>
        <Grid item xs={12} md={3} >
        <DownloadProductsTable datos={items}/>
        </Grid>
        <Grid item xs={12} color={"#cbcbcb"} >
          {`Se muestran ${items.length} resultados`}
        </Grid>
      </Grid>

      <TableContainer 
      // component={Paper} 
      id="TableProducts">
        <Table  aria-label="custom pagination table"  >
          <TableHead>
            <TableRow>
              <TableCell ><Grid xs={2} ><Button sx={{textTransform:'none', color:"#707070"}}>Imagen</Button></Grid></TableCell>
              <TableCell >
                <Grid container >
                  <Grid xs={3} textAlign={'center'} ><TabButton word='name' text='Nombre' /></Grid>
                  <Grid xs={2} textAlign={'center'}><TabButton word='category' text='Categoría' /></Grid>
                  <Grid xs={2} textAlign={'center'}><TabButton word='price' text='Precio'/></Grid>
                  <Grid xs={2} textAlign={'center'}><TabButton word='stock' text='Disponibilidad'/></Grid>
                  <Grid xs={1.5} textAlign={'center'} ><Button sx={{textTransform:'none', color:"#707070"}}>Borrar</Button></Grid>
                  <Grid xs={1.5} textAlign={'center'} ><Button sx={{textTransform:'none', color:"#707070"}}>Editar</Button></Grid>
                </Grid>
              </TableCell>
              {/* <TableCell>Precio</TableCell>
              <TableCell>Stock</TableCell>
              <TableCell>Acciones</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : items
            ).map((row) => (
              <TableRow key={row.name}  >
                <TableCell component="th" scope="row"  >
                  <IconButton onClick={editProduct} product-id={row.docId} category={row.category} >
                    <img src={row.image} className="imgProduct" />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" >
                  <Grid container spacing={1} alignItems='center'  >
                  <Grid item xs={12} sm={3} textAlign={'center'} >  
                  <IconButton onClick={editProduct} product-id={row.docId} category={row.category} sx={{marginLeft:"-10px"}} >
                    <Typography variant='subtitle1' color="primary" >
                      {row.name}
                    </Typography>
                  </IconButton>
                  {/* </TableCell>
                <TableCell component="th" scope="row"> */}
                  
                </Grid>
                <Grid item xs={12} sm={2} textAlign={'center'} >
                  {/* <Typography variant='subtitle2' > */}
                  <span style={{fontFamily:"Quicksand", fontWeight:'bold', color:"#43ABAE"}} >
                    {row.category}
                  </span>
                  {/* </Typography> */}
                </Grid>
                {/* </TableCell>
                <TableCell component="th" scope="row"> */}
                <Grid item xs={12} sm={2} textAlign={'center'} >
                  {/* <Typography variant='' > */}
                  <span style={{fontFamily:"Quicksand", fontWeight:'bold', color:"#707070"}} >
                      ${
                        row.defaultUnit == "Pza" ? (row.price + " por pieza") : (row.price + " por " + " kg")
                      }
                    </span>
                  {/* </Typography> */}
                  </Grid>
                {/* </TableCell>
                <TableCell component="th" scope="row" > */}
                <Grid item xs={12} sm={2} textAlign={'center'}  >
                  <div style={{display:'flex',flex:1,justifyContent:'space-between', alignItems:'center', marginRight:"20px"}} >
                    {/* <Typography variant='' > */}
                    <span style={{fontFamily:"Quicksand", fontWeight:'bold', color:"#707070"}}>
                      {row.defaultUnit === "Pza" ? row.stock + " Pza" : (parseFloat(row.stock)).toFixed(3) + " Kg"}
                    </span>
                    {/* </Typography> */}
                    <AddStockModal 
                      name={row.name}
                      stock={Number(row.stock)}
                      unit={row.primaryUnit}
                      id={row.id}
                      tax={row.category}
                      update={()=>handleUpdate()}
                    />
                  </div>
                  </Grid>
                {/* </TableCell>
                <TableCell component="th" scope="row" align="right"> */}
                <Grid item xs={6} sm={1.5} textAlign={'center'}  >
                  <IconButton onClick={() => {
                    setProductToRemove({
                      id: row.docId,
                      url: row.image,
                      productName: row.name,
                      category: row.category
                    }); setOpen(true)
                  }} product-id={row.docId} category={row.category}>
                    {/* <DeleteIcon/> */}
                    <span className='icon-eliminar-allfresco'  ></span>
                  </IconButton>
                  </Grid>
                  <Grid item xs={6} sm={1.5} textAlign={'center'} >
                  <IconButton onClick={editProduct} product-id={row.docId} category={row.category}>
                    <span className='icon-editar-allfresco'  ></span>
                    {/* <EditIcon/> */}
                  </IconButton>
                  </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}

          </TableBody>
          {/* <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter> */}
          
        </Table>
      </TableContainer>

      <Dialog open={open} >
        <Box m={1} p={1}>
          <img src={productToRemove.url} width="300px" />
          <Typography variant='h6' color="#43ABAE" >¿Esta seguro que desea eliminar {productToRemove.productName} ? </Typography>
          <Box display="flex" justifyContent="space-around">
            <Button variant='outlined' sx={{ border: "3px solid #B6D33C", fontWeight: "bold", borderRadius: "10px" }} onClick={removeProduct} >Aceptar</Button>
            <Button variant="outlined" sx={{ border: "3px solid #B6D33C", fontWeight: "bold", borderRadius: "10px" }} onClick={() => setOpen(false)} >Cancelar</Button>
          </Box>
        </Box>
      </Dialog>
      <section id="editProduct"></section>
    </div>
  );
}
