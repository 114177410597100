
import React from 'react';
// Components
import { Card } from '@mui/material';
import {CardContent} from '@mui/material';
import {CardMedia} from '@mui/material';
import {Chip} from '@mui/material';
import {Typography} from '@mui/material';
// Types
import { Character } from './carousel';

// import { makeStyles } from '@mui/styles';
// import { StylesProvider, createGenerateClassName } from '@mui/styles';

// const useStyles = createGenerateClassName({
//   root: {
//     width: 320
//   },
//   media: {
//     height: 150
//   }
// });

var Props = {
  character: Character
};

const CharacterCard = ({ character }) => {
//   const classes = <StylesProvider generateClassName={useStyles}></StylesProvider>;

  return (
    <Card style={{width: '320px'}} >
      <CardMedia style={{height: '150px'}} image={character.img_url} />
      <CardContent>
        <Typography gutterBottom variant='h5'>
          Name: {character.name}
        </Typography>
        <Typography gutterBottom color='textSecondary'>
          Hair: {character.hair}
          <br />
          Origin: {character.origin}
          <br />
          Species: {character.species}
          <br />
          Status: {character.status}
          <br />
          Alias:
          <br />
        </Typography>
        {character.alias.map((alias) => (
          <Chip key={alias} style={{ margin: 3 }} size='small' label={alias} />
        ))}
        <Typography color='textSecondary'>Abilities:</Typography>
        {character.abilities.map((ability) => (
          <Chip
            key={ability}
            style={{ margin: 3 }}
            size='small'
            label={ability}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default CharacterCard;