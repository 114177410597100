// import { FixedSizeList } from 'react-window';
import * as React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { Button, Typography, TextField, IconButton } from '@mui/material';
import { addDoc, getFirestore, doc, getDocs, collection, deleteDoc, orderBy, query } from 'firebase/firestore';


function renderRow(props) {
  const { index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
        <ListItemText primary={`CP ${index + 72000}`} />
      </ListItemButton>
    </ListItem>
  );
}
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: 'none',
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));
export default function ZoneCP() {
  const db = getFirestore()
  const [cp, setCp] = React.useState("")
  const [zipcodes, setZipcodes] = React.useState([])
  const [zipcodesCopy, setZipcodesCopy] = React.useState([])
  const [search, setSearch] = React.useState("")

  const addCP = async () => {
    try {
      const docRef = collection(db, 'cp')
      let arrayCpValid = []
      const result = cp.split(/\r?\n/)
      result.map( async(item) => {
        let cpValid = 0
        let duplicado = false
        if (item !== "") {
          if (item.length !== 5) {
            alert(item + " longitud incorrecta")
          } else {
            zipcodes.map((zipCode) => {
              if (zipCode.cp === item) {
                alert(zipCode.cp + " duplicado " + item)
                duplicado = true
              } else {
                // console.log("valido " + item)
                cpValid = item
              }

            })
            if (!duplicado) {
              arrayCpValid.push(item)
              await addDoc(docRef, { cp: item })
              alert(""+item+" añadido correctamente")
              setCp("")
            } 
          }

        }
        // item!==""?console.log("cp ()=>"+item): console.log("nada")
      }
      )
      console.log(arrayCpValid)
      // await addDoc(docRef, { cp: cp })

    } catch (error) {
      console.log('Error adding document: ', error)
    }

    // setCp('')
    getCP()
  }

  const getCP = async () => {
    let cpArray = []
    const docRef = collection(db, 'cp')
    const q = query(docRef, orderBy("cp", "asc"))
    const data = await getDocs( q )
    data.forEach((doc) => {
      // console.log("Id de cp")
      // console.log(doc.id)
      let zipCode = {
        cp: doc.data().cp,
        id: doc.id
      }
      cpArray.push(zipCode)
    })
    setZipcodes([...cpArray])
  }
  React.useEffect(() => {
    getCP()

  }, [])

  async function deleteCP(idZipCode) {
    try {
      await deleteDoc(doc(db, 'cp', idZipCode))
    } catch (error) {
      console.log("Error al eliminar documento: " + error)
    }
    getCP()
  }
  const handleSearch = (searched) => {
    if (searched.length <= 5) setSearch(searched)
    const resultSearch = zipcodes.filter(code => code.cp.includes(searched))
    setZipcodesCopy([...resultSearch])
    // console.log(resultSearch)
  }

  const DeleteIcon = ()=> (
    <svg  width="28" height="32" viewBox="0 0 28 32">
      <path id="Trazado_1994" data-name="Trazado 1994" d="M8.45,1.106,8,2H2A2,2,0,1,0,2,6H26a2,2,0,1,0,0-4H20l-.45-.894A1.992,1.992,0,0,0,17.763,0H10.238A1.992,1.992,0,0,0,8.45,1.106ZM26,8H2L3.325,29.188A3,3,0,0,0,6.319,32H21.681a3,3,0,0,0,2.994-2.812Z" fill="#cbcbcb"/>
    </svg>
  )

  return (
    <Box
      sx={{ width: '100%', height: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
    >
      <Search sx={{ width: '100%' }}>
        <SearchIconWrapper>
          <SearchIcon color='primary' />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Buscar codigo postal…"
          inputProps={{ 'aria-label': 'search' }}
          type="number"
          sx={{ width: '100%' }}
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Search>
      <Typography m={3} variant="h4">
        Agregar nuevo CP
      </Typography>
      <TextField
        id="filled-basic"
        label="Codigo Postal"
        variant="filled"
        multiline
        maxRows={6}
        value={cp}
        type="number" maxLength={5}
        onChange={(e) => {
          // if (e.target.value.length <= 5) 
          setCp(e.target.value)
        }}
      />
      <Button variant='outlined' sx={{ margin: "10px" }} onClick={addCP}>
        Agregar
      </Button>
      <Typography m={3} variant='h4' >
        Zonas de cobertura
      </Typography>
      {/* <FixedSizeList
        height={200}
        width={360}
        itemSize={46}
        itemCount={5}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList> */}
      {
        (search === "" ? zipcodes : zipcodesCopy).map(code =>
          <ListItem key={code.id} component="div" disablePadding >
            <ListItemButton sx={{display:"flex", justifyContent:'space-between'}} >
              {/* <ListItemText primary={`CP ${code.cp}`} color='#707070' sx={{fontFamily:"Quicksand"}} /> */}
              <span style={{color:"#707070", fontFamily:"Quicksand", fontWeight:"500"}} >{`CP ${code.cp}`}</span>
              <IconButton onClick={() => deleteCP(code.id)}> <DeleteIcon/> </IconButton>
              {/* <Button  variant='contained'>Borrar</Button> */}
            </ListItemButton>
          </ListItem>
        )
      }
    </Box>
  );
}
