import { Typography } from '@mui/material'
import React from 'react'

const ReturnPolicy = () => {
  return (
    <main className='contenido' style={{padding:"1rem"}} >
      <h1>Política de  Devoluciones</h1>
      <Typography marginY={2} >¡En allfresco.mx puedes cambiar de opinión!</Typography>
      <Typography marginY={1} >Tienes 365 días para devolver los productos comprados en nuestra tienda en línea y/o cualquiera de nuestras tiendas físicas (las “Tiendas”), de Ikano Retail Mexico, S.A. de C.V. (“IKEA”) ubicadas en los Estados Unidos Mexicanos (“México”).</Typography>
      <Typography marginY={1}>Si no estás completamente satisfecho o simplemente cambiaste de opinión sobre tu compra, puedes devolver el producto (cuando no sea un tema relacionado a garantía), es necesario brindar el comprobante de compra y asegurarse de que se encuentre en buenas condiciones (mismas condiciones en las que fue recibido) para poder hacer válido el reembolso</Typography>
      <Typography marginY={1} >Hay algunos productos que no se incluyen en la política 365. ¿Cuáles son?</Typography>
      <ul>
        <li><Typography>Telas por metros</Typography></li>
        <li><Typography>Plantas naturales</Typography></li>
        <li><Typography>Blancos usados (toallas, duvets, almohadas, protectores, sábanas, cojines, fundas, etc)</Typography></li>
        <li><Typography>Línea blanca (refrigeradores, microondas, lavavajillas, electrónicos de cocina, etc)</Typography></li>
        <li><Typography>Productos de oferta final adquiridos en la “Zona de Oportunidades” (también conocida como “As Is”) de las Tiendas IKEA México: estos productos estarán identificados con una etiqueta especial con el texto “As Is” o “Zona de oportunidades”.</Typography></li>
      </ul>
      <Typography variant='h3' marginY={1} >Devoluciones en tiendas allfresco México:</Typography>
      <Typography>Puedes devolver los productos directamente en cualquiera de nuestras tiendas físicas dentro de México, directamente en el área de Atención al Cliente, presentando el producto en buenas condiciones (mismas condiciones en las que fue adquirido), identificación oficial, el comprobante de compra y la tarjeta(s) bancaria(s) con la(s) que hiciste el pago (en caso de aplicar). Es importante que los productos sean devueltos desarmados.</Typography>
      
      <Typography variant='h3' marginY={1} >Devoluciones sin presentarte en tienda:</Typography>
      <Typography>En caso de que no puedas llevar el producto a cualquiera de nuestras tiendas físicas, también puedes solicitar que recolectemos el producto directamente en tu domicilio, contáctanos al Centro de Atención a Clientes IKEA para coordinar la recolección. Considera que, en devoluciones por Política 365, deberás cubrir un cargo por recolección, el cual será deducido de la cantidad total a reembolsar por los productos devueltos.</Typography>
    
    </main>
  )
}

export default ReturnPolicy