// import DialogActions from '@mui/material/DialogActions';
// import DialogTitle from '@mui/material/DialogTitle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useRef } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Grid, IconButton, Typography } from '@mui/material';
import logo from '../../assets/AllFresco.png';
import { styled } from '@mui/material/styles';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { useStateValue } from '../../StateProvider';
import EditIcon from '@mui/icons-material/Edit';
// import { uuid } from 'uuidv4';

const Img = styled('div')(({ theme }) => ({
  img: {
      width: '100px',
  },
}));

export default function EditAddress( {editData} ) {
  const db = getFirestore();
  const [{user}, dispatch] = useStateValue();
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState({...editData})
  console.log(userData)

  const street = useRef('');
  const exteriorNumber = useRef('');
  const interiorNumber = useRef('');
  const suburb = useRef('');
  const city = useRef('');
  const state = useRef('');
  const zipCode = useRef('');
  const country = useRef('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateAddress = async() => {    
    try {
      
      // var dirs = await getDoc( doc(db,'address', user.uid) )
      if( editData.id === 'main' ){
        // dirs= dirs.data()
        // dirs.push(data)
        // console.log(dirs)
        await setDoc(doc(db, "address", user.uid), {
          main: userData
        }
        );
      } else{
        console.log()
        await setDoc(doc(db, "address", user.uid), {
          [editData.id]:userData
        }, {
          merge: true
        }
        )
      }
      handleClose();
      window.location.reload()
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
  return (
    <div>
      <IconButton sx={{ color: '#B6D33C' }} onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className="form-direction">
          <Img>
              <img src={logo} sx={{ width: '100px' }}/>
          </Img>
          <Typography variant="h5" color="text.primary">
            Editar direccion
          </Typography>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="street"
                inputRef={street}
                label="Nombre de la calle"
                value={userData.street}
                onChange={(e)=>setUserData({
                    ...userData,
                    street: e.target.value
                })}
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="exteriorNumber"
                inputRef={exteriorNumber}
                label="Número exterior"
                value={userData.exteriorNumber}
                onChange={(e)=>setUserData({
                    ...userData,
                    exteriorNumber: e.target.value
                })}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="interiorNumber"
                inputRef={interiorNumber}
                label="Número interior"
                value={userData.interiorNumber}
                onChange={(e)=>setUserData({
                    ...userData,
                    interiorNumber: e.target.value
                })}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="suburb"
                inputRef={suburb}
                label="Colonia"
                type="text"
                value={userData.suburb}
                onChange={(e)=>setUserData({
                    ...userData,
                    suburb: e.target.value
                })}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="cp"
                label="Código Postal"
                inputRef={zipCode}
                type="number"
                value={userData.zipCode}
                onChange={(e)=>setUserData({
                    ...userData,
                    zipCode: e.target.value
                })}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="municipio"
                label="Alcaldía o Municipio"
                inputRef={city}
                type="text"
                value={userData.city}
                onChange={(e)=>setUserData({
                    ...userData,
                    city: e.target.value
                })}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="state"
                label="Estado"
                inputRef={state}
                type="text"
                value="Puebla"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="country"
                label="País"
                value="Mexico"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="telephone"
                label="Número de teléfono"
                type="number"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
          <DialogContentText>
            <Button variant="contained" sx={{  color: 'white', background: '#B6D33C' }} onClick={updateAddress}>Guardar cambios</Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}