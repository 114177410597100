// import Paper from '@mui/material/Paper';
// import EditIcon from '@mui/icons-material/Edit';
// import { actionTypes } from '../../reducer';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Avatar, Button, Card, CardActions, CardContent, FormControl, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionForm from '../forms/direction';
import { collection, doc, getDoc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import { useStateValue } from '../../StateProvider';
import EditAddress from './editAddress';
import { deleteUser, getAuth } from 'firebase/auth';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  //color: theme.palette.text.secondary,
  color: '#B6D33C',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));


export default function Information() {
  const [{ user }, dispatch] = useStateValue();
  const db = getFirestore()
  const [direcciones, setDirecciones] = React.useState([])
  console.log(user)
  const handleDelete = async (addressId) => {
    let newAddress = {}
    direcciones.map( dire => {
      if(dire.id !== addressId ){
        newAddress ={
          ...newAddress,
          [dire.id]: {...dire}
        }
      }
    } )
    alert("borrar la direccion principal")
    try {
      await setDoc(doc(db, "address", user.uid), newAddress)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
    getAddress();
  }
  const handleEdit = async () => {

  }
  const auth = getAuth();
  const userToDelete = auth.currentUser;
  const deleteAccount = async() => {
    try {
      deleteUser(userToDelete)
      .then( ()=>{
        alert("Sus datos se han borrado con exito")
        window.location.reload();
      })
      await setDoc(doc(db,"deleted", user.email), user)
    } catch (error) {
      console.log(error)
    }
  }
  const getAddress = async() => {
    const result = await getDoc(doc(db, 'address', user.uid))
    const domicilios = result.data()
    console.log(domicilios)
    let datos  = []
    // console.log(domicilios)
    Object.keys(domicilios).map( adrs => {
        console.log(adrs); 
        domicilios[adrs].id=adrs;
        datos=[...datos, domicilios[adrs]]
    } )
    setDirecciones(datos)
  }
  React.useEffect(() => {
    getAddress()
    console.log(direcciones)
  }, [])
  

  function Address() {
    if (user != null && user.address != null) {
      return (
        <React.Fragment>
          {
            direcciones.map( direccion => (
              <Grid item xs={12} sm={6} md={4} marginTop={"10px"} >
                <Card sx={{ width: 300, height:300 }} >
                  <CardContent>
                    {
                      direccion.id=="main"?<Typography variant='h5' >PRINCIPAL</Typography>:<Typography variant='h5' ></Typography>
                    }
                    <Typography variant="body2" color="text.secondary">
                      <p>{direccion.street}, {direccion.exteriorNumber} {direccion.interiorNumber}, {direccion.suburb} </p>
                      <p> CP: {direccion.zipCode} </p>
                      <p> {direccion.city}, {direccion.state}, { direccion.country} </p>
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <EditAddress editData={ direccion } />
                    <IconButton aria-label='share' sx={{marginLeft:"auto"}} onClick={()=> handleDelete(direccion.id) } >
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))
          }

        </React.Fragment>
      );
    }
  }
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <Avatar
        alt="Avatar"
        src={user.photoURL?user.photoURL:"/static/images/avatar/1.jpg"}
        sx={{ width: 80, height: 80 }}
      />
      <FormControl fullWidth sx={{ m: 1 }}>
        <TextField id="nombre" label="Nombre(s)" value={user.displayName} variant="outlined" sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }} />
        <br />
        <TextField id="apellidos" label="Apellidos" variant="outlined" sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }} />
        <br />
      </FormControl>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='h3' gutterBottom={true} >Direcciones</Typography>
        <Item><DirectionForm /></Item>
        <Grid container spacing={2} columns={12} marginTop={"8px"}>
          <Address />
        </Grid>
       
      </Box>
      <Box>
        <Typography marginBottom={2} variant='h4' >Borrar mi cuenta</Typography>
        <Button variant='contained' color='error' onClick={deleteAccount} >Eliminar</Button>
      </Box>
    </Box>
  );
}
