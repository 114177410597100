// import products from '../product-data';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Product from './product';
import { useStateValue } from '../StateProvider';
import { Typography, Snackbar } from '@mui/material';
import { useParams } from "react-router-dom";
import NotFound from './info/NotFound';


export default function Category({ categoryName, limit }) {
    const [{ product }] = useStateValue();
    const [productCategory, setPC] = React.useState([])
    const [limits, setLimit] = React.useState(limit)
    let params = useParams();
    const [categoryNames, setCategoryNames] = React.useState(categoryName ? categoryName : params.category)
    // console.log(categoryName)
    React.useEffect(() => {
        let categoria = ''
        if (categoryName === undefined) {
            if (params.category !== undefined) {
                console.log("La categoria ha cambiado")
                console.log(params.category)
                setCategoryNames(params.category)
                categoria = params.category
            }
        } else {
            categoria = categoryName
        }
        var categoriaProductos = product.filter((elemento) => {
            // console.log(elemento)
            if (elemento.category != undefined && elemento.category.toString().toLowerCase().includes(categoria.toLowerCase())) {
                return elemento;
            }
        })
        if (productCategory.length !== categoriaProductos.length) {
            if (limits !== undefined) {
                // var element = []
                // for (let index = 0; index < 4; index++) {
                //     let n = Math.floor(Math.random() * categoriaProductos.length);
                //     element = [...element, categoriaProductos[n]];
                // }
                // return setPC([...categoriaProductos.slice(0, limit)])
                return setPC(categoriaProductos.slice(0, limit))
            } else {
                return setPC(categoriaProductos)
            }
        }
    }, [product.length, params.category])


    const [open, setOpen] = React.useState(false);
    const [snack, setSnack] = React.useState({
        image: "",
        name: "",
        quantity1: 0,
        unidad: ""
    })

    const handleClick = (image, quantity, unidad, name) => {
        setOpen(true);
        setSnack({
            image,
            quantity,
            unidad,
            name
        })
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    if(productCategory.length===0){
        return <NotFound/>
    }

    return (
        <Box id='categoria' className='contenido' sx={{ flexGrow: 2 }} marginBottom={3} >
            <Typography variant="h2" marginY="1.5rem" marginLeft="15px" >
                {categoryNames}
            </Typography>
            <Grid container spacing={2}>
                {/* <ProductosCategoria /> */}
                {
                    productCategory.length>0?productCategory.map(dataProduct => (
                        <Grid key={dataProduct.id} item xs={6} md={3}>
                            <Product product={dataProduct} handleClick={handleClick} />
                        </Grid>
                    )) : (
                        <>
                        <Typography variant='h2' >No se encontraron resultados</Typography>
                        {/* <NotFound /> */}
                        </>
                    )
                }
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="Note archived"
                // action={action}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Box sx={{ width: "100%", background: "#fff", borderRadius: "10px" }} display="flex" p={2} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" >
                    <img src={snack.image} width="50px" height="50px" />
                    <Box marginLeft={2} >
                        <Typography color="primary" >Haz añadido</Typography>
                        <Typography color="primary" ><strong>{snack.quantity} {snack.unidad} de {snack.name}</strong></Typography>
                    </Box>
                </Box>
            </Snackbar>
        </Box>
    );
}