import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { getFirestore, getDocs, doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import UserOrders from './userOrders';
import Slide from '@mui/material/Slide';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Cupcake', 305, 3.7),
  createData('Donut', 452, 25.0),
  createData('Eclair', 262, 16.0),
  createData('Frozen yoghurt', 159, 6.0),
  createData('Gingerbread', 356, 16.0),
  createData('Honeycomb', 408, 3.2),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Jelly Bean', 375, 0.0),
  createData('KitKat', 518, 26.0),
  createData('Lollipop', 392, 0.2),
  createData('Marshmallow', 318, 0),
  createData('Nougat', 360, 19.0),
  createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));


const Users = () => {
  const db = getFirestore();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const radioGroupRef = React.useRef(null);
  const [openRol, setOpenRol] = React.useState(false);
  const [value, setValue] = React.useState('Cliente');
  const [clientsList, setClientList] = React.useState([])
  const [showClients, setShowClients] = React.useState(false)
  React.useEffect(() => {
    if (!open) {
      setValue(value);
    }
  }, [value, openRol]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [openPermissions, setOpenPermissions] = React.useState(false)
  const [searched, setSearched] = React.useState("");
  const [userList, setUserList] = React.useState([])
  const [products, setProducts] = React.useState(userList);
  const [numberUsers, setNumberUsers] = React.useState({
    clientes: 0,
    admins: 0,
    repartidores: 0,
    empacadores: 0
  })
  const requestSearch = (searchedVal) => {
    const filteredRows = userList.filter((row) => {
      return row.userName.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setProducts(filteredRows);
    // console.log(products)
  };
  const handleSearch = (search) => {
    setSearched(search)
    requestSearch(search)
  }
  const getUsuarios = async () => {
    var elements = []
    let clientsArray = []
    var ncustomers = 0
    let nrepartidores = 0
    let nempacadores = 0
    let nadmins = 0
    const datos = await getDocs(collection(db, 'users'))
    datos.forEach(element => {
      let ele = element.data()
      ele.id = element.id
      if (element.data().role === "cliente") {
        clientsArray.push(ele)
        ncustomers++
      }
      if (element.data().role === "repartidor") nrepartidores++
      if (element.data().role === "empacador") nempacadores++
      if (element.data().role === 'admin') nadmins++
      // console.log(numberUsers)
      elements = [...elements, ele]
    });
    setNumberUsers({
      ...numberUsers,
      clientes: ncustomers,
      repartidores: nrepartidores,
      empacadores: nempacadores,
      admins: nadmins
    })
    setUserList(elements)
    setClientList([...clientsArray])
  }
  // getUsuarios()
  React.useEffect(() => {
    getUsuarios()
    // userList.map(item => console.log(item.email))
    setProducts([...userList])
  }, [userList.length])
  // console.log(userList)
  const handleChange = (e) => {

  }
  const [open, setOpen] = React.useState(false)
  const [idUser, setIdUser] = React.useState("")
  const [orderList, setOrderList] = React.useState({})
  const [usernameOrder, setUsernameOrder] = React.useState("")
  const handleUserOrders = (id) => {
    setOpen(true)
    setIdUser(id)
    getuserOrders(id)
  }
  const handleClose = () => {
    setOpen(false);
  };
  const getuserOrders = async (idU) => {
    let datos = await getDoc(doc(db, 'orders', idU))
    if (JSON.stringify(datos.data() == '{}')) {
      setOrderList({})
    }
    if(datos.exists()){
      datos = datos.data().orders
      Object.keys(datos).forEach((key) => {
        datos[key].id = key
      })
      setOrderList(datos)
    }
    // console.log(datos.data().orders)
  }
  const handleCancel = () => {
    setOpenRol(false);
  };

  const handleOk = () => {
    try {
      console.log(value.toLowerCase())
      setValue(radioGroupRef.current)
      setOpenRol(false);
      changeUserType()

      //UPDATE LIST
      getUsuarios()
      setProducts([...userList])

    } catch (error) {
      console.log(error)
    }

  };

  const handleChangeRol = (event) => {
    setValue(event.target.value);
  };
  const [idUserChange, setIdUserChange] = React.useState('');
  const [nameUserChange, setNameUserChange] = React.useState("")
  const changeUserType = async () => {
    await updateDoc(doc(db, 'users', idUserChange), {
      role: value.toLowerCase()
    })
    
  // getUsuarios()
  }

  const listClients = ()=> {
    if(showClients){
      setClientList()
    }
    const clients = userList.filter( user => user.role=="cliente" )
    if(clients.length == userList.length)
      setUserList(products)
    else 
      setUserList(clients)
    // console.log(clients)
  }
  // const { onClose, value: valueProp, openRol, ...other } = props;

  return (
    <>
      <Box display="flex" justifyContent="space-between" m={2} >
        <Box display="flex" sx={{backgroundColor:showClients?"rgba(0,0,0,0.09)":"none"}} >
          <Box>
            <Typography variant='h6' >Clientes</Typography>
            <Typography variant='h3' >{numberUsers.clientes}</Typography>
          </Box>
          <Box >
            <IconButton onClick={()=> setShowClients(!showClients) } >
              <span className='icon-Perfil-de-usuario-allfresco' sx={{ color: "#B6D33C" }} ></span>
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" >
          <Box>
            <Typography variant='h6' >Repartidores</Typography>
            <Typography variant='h3' >{numberUsers.repartidores}</Typography>
          </Box>
          <Box>
            <span className='icon-Perfil-de-usuario-allfresco' sx={{ color: "#B6D33C" }} ></span>
          </Box>
        </Box>

        <Box display="flex" >
          <Box>
            <Typography variant='h6' >Empacadores</Typography>
            <Typography variant='h3' >{numberUsers.empacadores}</Typography>
          </Box>
          <Box>
            <span className='icon-Perfil-de-usuario-allfresco' sx={{ color: "#B6D33C" }} ></span>
          </Box>
        </Box>

        <Box display="flex" >
          <Box>
            <Typography variant='h6' >Administradores</Typography>
            <Typography variant='h3' >{numberUsers.admins}</Typography>
          </Box>
          <Box>
            <span className='icon-Perfil-de-usuario-allfresco' sx={{ color: "#B6D33C" }} ></span>
          </Box>
        </Box>

      </Box>
      <Box marginBottom="20px" >
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon color='primary' />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1, background: "#F9F9F9", width: "90%" }}
          placeholder="Buscar..."
          inputProps={{ 'aria-label': 'search ' }}
          value={searched}
          onChange={(e) => handleSearch(e.target.value)}
          autoFocus
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
            {(rowsPerPage > 0
              ? (showClients? clientsList:products).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : (searched !== "" ? products : userList)
            ).map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <span style={{color:"#43ABAE", fontFamily:"Quicksand", fontWeight:"600"}} >{row.userName}</span>
                  {/* <Typography variant='body' color="#43ABAE" > {row.userName} </Typography> */}
                </TableCell>
                <TableCell >
                  <span style={{color:"#707070", fontFamily:"Quicksand", fontWeight:"500"}}>{row.email}</span>
                </TableCell>
                <TableCell size='small' sx={{ width: "80px" }} >
                  {/* <Typography variant='subtitle2' >{row.role}</Typography> */}
                  <Button variant='contained' sx={{width:"150px", display:"flex",color:"#CBCBCB",background:"#F9F9F9",'&:hover':{background:"white"} , justifyContent:"space-between"}} onClick={() => { setOpenPermissions(true); setIdUserChange(row.id); setNameUserChange(row.userName) }} > 
                  <span style={{color:"#B6D33C", fontFamily:"Quicksand", fontWeight:"600"}}>{row.role}</span>
                    <svg width="18" height="16" viewBox="0 0 18 16">
                      <path id="Polígono_8" data-name="Polígono 8" d="M7.257,3.1a2,2,0,0,1,3.486,0l5.58,9.921A2,2,0,0,1,14.58,16H3.42a2,2,0,0,1-1.743-2.981Z" transform="translate(18 16) rotate(180)" fill="#707070" />
                    </svg>
                  </Button>
                </TableCell>
                <TableCell >
                  <Button variant='contained' sx={{width:"140px", textTransform:'none', fontFamily:"Quicksand", fontWeight:"bold", borderRadius:"10px"}} onClick={() => {handleUserOrders(row.id); setUsernameOrder(row.userName)}} >Ver Pedidos</Button>
                </TableCell>
              </TableRow>

            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box>
        <Dialog open={open}
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
        >
          <UserOrders id={idUser} orderData={orderList} userName={usernameOrder} />
        </Dialog>
      </Box>

      <Dialog
        open={openPermissions}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenPermissions(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>¿Esta seguro de cambiar los permisos a este usuario?</DialogTitle>
        <DialogContent>
          <DialogContentText>Asegurese de que los permisos son los correctos y el usuario es el que usted desea cambiar.</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }} >
          <Button onClick={() => { setOpenRol(true); setOpenPermissions(false) }} variant="contained" sx={{ textTransform: "none", paddingX: "40px" }} >Aceptar</Button>
          <Button onClick={() => setOpenPermissions(false)} variant="outlined" sx={{ border: "3px solid", textTransform: "none", paddingX: "40px", color:"#FF6700", border:"1px solid #FF6700" }} >Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="sm"
        TransitionProps={{ onEntering: handleEntering }}
        open={openRol}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} >
          {/* <span className='icon-Perfil-de-usuario-allfresco' sx={{ color: "#B6D33C" }} ></span> */}
          <Typography variant='h3' color="#43ABAE">Selecciona un rol para</Typography>
          <Typography variant="h3" color="#43ABAE"> {nameUserChange} </Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ display: "flex", justifyContent: "flex-start" }} >
          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={value}
            onChange={handleChangeRol}
          >
            {options.map((option) => (
              <FormControlLabel
                value={option}
                key={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }} >
          <Button onClick={handleOk} variant="contained" sx={{ paddingX: "40px", textTransform: "none" }} >Ok</Button>
          <Button autoFocus onClick={handleCancel} variant="outlined" sx={{ paddingX: "40px", border: "2px solid", textTransform: "none", color:"#FF6700", border:"1px solid #FF6700" }} > Cancel </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}


export default Users

const options = [
  'Cliente',
  'Admin',
  'Empacador',
  'Repartidor'
];