import ShoppingBag from '@mui/icons-material/ShoppingBag';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CheckoutCard from './checkoutCard';
import { Typography, Snackbar, Box } from '@mui/material';
import { Total } from './total';
import { useStateValue } from '../StateProvider';
import ApplyCupon from './checkoutForm/ApplyCupon';

const CheckoutPage = () => {
    const [{ basket }, dispatch] = useStateValue();
    const [snack, setSnack] = React.useState({
        image: "",
        name: "",
        quantity: "",
        unidad: ""
    })

    const [open, setOpen] = React.useState(false);

    const handleClick = (image, quantity, unidad, name) => {
        setOpen(true);
        setSnack({
            image,
            quantity,
            unidad,
            name
        })
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    function FormRow() {
        if (basket.length === 0)
            return <Box> El carrito esta vacio </Box>;
        return (
            <React.Fragment>
                {
                    basket.map((item) => (<>
                        <Grid key={item.id} item xs={12} sm={12} md={12} lg={12} container spacing={2} >
                            <CheckoutCard product={item} handleClick={handleClick} />
                        </Grid>
                    </>
                    ))
                }
            </React.Fragment>
        );
    }
    return (
        <Box className='checkout-page Item.root contenido' sx={{ flexGrow: 2, padding: 3 }}  >
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Note archived"
                // action={action}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Box sx={{ width: "100%", background: "#fff", borderRadius: "10px" }} display="flex" p={2} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;" >
                    <img src={snack.image} width="50px" height="50px" />
                    <Box marginLeft={2} >
                        <Typography color="primary" >Haz añadido</Typography>
                        <Typography color="primary" ><strong>{snack.quantity} {snack.unidad} de {snack.name}</strong></Typography>
                    </Box>
                </Box>
            </Snackbar>

            <Grid container spacing={2} marginTop={"24px"}>
                <Grid item xs={12}>
                    <label className="icon-canasta-allfresco" ></label>
                    <Typography className='my-cart' align='left' gutterBottom variant='h2' display='inline-block' color="#B6D33C" >
                        Mi carrito
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} container spacing={2}>
                    <FormRow />
                    <ApplyCupon />
                    <Total />
                </Grid>

            </Grid>
        </Box>
    );
}

export default CheckoutPage;