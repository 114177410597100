import { PlusOneOutlined } from '@mui/icons-material'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import { doc, increment, updateDoc } from 'firebase/firestore'
import React from 'react'
import { useState } from 'react'
import db from '../../../firebase/fbcnf'

const AddStockModal = ({name, stock, tax, id, unit, update}) => {
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleUpdateStock=async()=>{
    try {
      setLoading(true)
      if(quantity<=0){
        alert("la cantidad debe ser mayor a cero")
        setLoading(false)
        return;
      }
      const docRef = doc(db,'product', tax,'inventario',id)
      // console.log("cantidad ", quantity)
      // console.log(tax, id)
      await updateDoc(docRef,{
        stock: increment(quantity)
      })
      alert("Se ha actualizado el stock")
      setOpen(false)
      setQuantity(0)
      update()
    } catch (error) {
      console.log("Error ", error)
    }
    setLoading(false)
  }
  const AddIcon=()=>(
    <svg  width="38.677" height="36.098" viewBox="0 0 38.677 36.098">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectángulo_1038" data-name="Rectángulo 1038" width="38.677" height="36.098" transform="translate(0 0.5)" fill="none"/>
    </clipPath>
  </defs>
  <g id="Grupo_1257" data-name="Grupo 1257" transform="translate(0 -0.5)" clipPath="url(#clip-path)">
    <path id="Trazado_1760" data-name="Trazado 1760" d="M32.361,16.181A16.181,16.181,0,1,1,16.181,0,16.181,16.181,0,0,1,32.361,16.181" transform="translate(3.182 2.384)" fill="#b6d33c"/>
    <path id="Trazado_1761" data-name="Trazado 1761" d="M49.55,43.193H42.656v6.78H39.474v-6.78H32.58V40.2h6.894V33.382h3.182V40.2H49.55Z" transform="translate(-21.703 -23.113)" fill="#fff"/>
  </g>
</svg>

  )
  
  return (
    <>
      <IconButton onClick={() => setOpen(true)} >
        {/* <PlusOneOutlined fontSize='large' color='primary' /> */}
        <AddIcon/>
      </IconButton>

      <Dialog open={open} onClose={handleClose} sx={{ padding: 2, paddingX:4, borderRadius:10 }} >
        <DialogTitle>Añadir inventarío a {name} </DialogTitle>
        <DialogContent>
          <Typography><strong>Inventarío actual</strong></Typography>
          <Typography>{stock.toFixed(3)} {unit==="Pza"?"Piezas":"Kg"}</Typography>
          <TextField 
            placeholder='Cantidad' 
            label='Añadir cantidad' 
            type='number' value={quantity} 
            onChange={(e)=>setQuantity(Number(e.target.value))}
            sx={{marginY:2}}
            autoFocus
          />
          <Typography><strong>Inventarío Final</strong></Typography>
          <Typography>{(stock+quantity).toFixed(3)} {unit==="Pza"?"Piezas":"Kg"}</Typography>
          {/* <Button onClick={()=>update()} >Update</Button> */}
          {loading && <CircularProgress color='primary' /> }
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' sx={{color:"#FF6700", border:'1px solid #FF6700', borderRadius:"10px", marginRight:"10px", fontFamily:"Quicksand", textTransform:'none', paddingX:3}} onClick={handleClose}>Salir</Button>
          <Button variant='contained' sx={{borderRadius:"10px",fontFamily:"Quicksand", textTransform:'none'}} onClick={handleUpdateStock} disabled={loading} >Guardar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddStockModal