import styled from 'styled-components';
import { StyledSliderItem } from './sliderItemStyles';

// let SliderWrapperProps = {
//   zoomFactor: 0,
//   visibleSlides: 0,
// };

// let SliderProps = {
//   visibleSlides: 0,
//   transformValue: "",
//   zoomFactor: 0,
//   slideMargin: 0,
//   pageTransition: 0,
//   ref: {},
// };

export const StyledSliderWrapper = styled.div`
  overflow: hidden;
  position: relative;
  margin-left: -63px;
  margin-right: -63px;
  background: #fff;
  padding: ${(props) => (props.zoomFactor / props.visibleSlides) * 0.7 + '%'} 0;
  .button-wrapper {
    position: absolute;
    width: 55px;
    height: 65%;
    top: 0;
    padding: ${(props) => props.zoomFactor / 7 + '%'} 0;
    box-sizing: border-box;
  }
  .button {
    display: block;
    background: rgb(255, 255, 255, 0.7);
    border: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 3rem;
    font-weight: 800;
    cursor: pointer;
    outline: none;
    transition: all 0.7s;
    user-select: none;
    :hover {
      background:rgba(255,255,255,0.5);
      opacity: 0.3;
    }
  }
  .back {
    left: 63px;
    border-radius: 0 1.5vw 1.5vw 0;
    color: #707070;
    background: rgba(0, 0, 0, 0.01);
    :hover{
      color:#b6d33c;
    }
  }
  .forward {
    right: 63px;
    border-radius: 1.5vw 0 0 1.5vw;
    color: #707070;
    background: rgba(0, 0, 0, 0.01);
    :hover{
      color:#b6d33c;
    }
  }
`;

export const StyledSlider = styled.div`
  display: flex;
  padding: 0 55px;
  transition: transform ${(props) => props.pageTransition}ms ease;
  :hover ${StyledSliderItem} {
    transform: translateX(${(props) => props.transformValue});
  }
`;